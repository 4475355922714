<template>
  <div class="body">
      <!-- IP查询次数 -->
      <div class="global ipSearch">
        <div>
          <shuzi :shuzi="allTotal"></shuzi>
          <span>IP查询总次数</span>
        </div>
        <span class="line"></span>
        <div>
          <shuzi :shuzi="tenminteTotal"></shuzi>
          <span>10分钟内查询次数</span>
        </div>
      </div>
      <!-- 查询平均数 -->
      <div class="global svm">
       <div>
        <shuzi :shuzi="svm" />
        <span>每位警员平均查询次数</span>
       </div>
      </div>
      <!-- 分析报告 -->
      <div class="global report">
        <div>
          <shuzi :shuzi="report"></shuzi>
          <span>深度分析报告查询次数</span>
        </div>
        <span class="line"></span>
        <div>
          <shuzi :shuzi="tenminteReport"></shuzi>
          <span>当天查询次数</span>
        </div>
      </div>
      <!-- 对接数量 -->
      <div class="global docking">
        <div>
          <shuzi :shuzi="DockingTotal"></shuzi>
          <span>总对接量</span>
        </div>
        <span class="line"></span>
        <div>
          <shuzi :shuzi="Dockingcomplete"></shuzi>
          <span>对接完成总数</span>
        </div>
        <div class="docking_surface">
          <v-chart :option="option"></v-chart>
        </div>
      </div>
      <!-- 活跃度 -->
      <div class="global Activity">
        <v-chart :option="options"></v-chart>
      </div>


    <!-- 控制台 -->
    <div class="top_bar">
      <!-- <span @click="toHome">控制台</span> -->
      <span @click="toLogin"><img src="../../public/images/shut.png" alt=""></span>
    </div>
  </div>
</template>

<script>
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart, PieChart } from "echarts/charts";

import VChart, { THEME_KEY } from "vue-echarts";

//引入数字样式
import shuzi from "../components/shuzi.vue";
export default {
  provide: {
    [THEME_KEY]: "higt"
  },
  components: {
    shuzi,
    VChart
  },
  data() {
    return {
      //总查询次数
      allTotal: 0,
      //10分钟内查询次数
      tenminteTotal: 0,
      //警方查询平均数
      svm: 0,
      //对接的总数
      DockingTotal: 0,
      //对接完成总数
      Dockingcomplete: 0,
      //对接中的数量
      Docking: 0,
      //分析报告查询次数
      report: 0,
      //十分钟内分析报告查询次数
      tenminteReport: 0,
      //7天对接的总数
      SevendayDocking: 0,
      //当天对接的总数
      daydocking: 0,
      //定时器
      timer: null,
      //对接情况表
      option: {
        tooltip: {
          show: true,
          trigger: "item",
          axiosPointer: {
            type: "shadow",
            axis: "auto"
          },
          padding: 5,
          textStyle: {
            color: "#3f95d8"
          }
        },
        xAxis: {
          show: false,
          max: "dataMax",
          axisLine: {
            //这是x轴文字颜色
            lineStyle: {
              color: "#fff"
            }
          },
          splitLine: {
            show: false, //去掉x轴分割线
            lineStyle: {
              color: "#57617B"
            }
          }
        },
        yAxis: {
          type: "category",
          data: ["总对接量", "对接中", "近7天对接", "近24小时对接"],
          inverse: true,
          animationDuration: 3000,
          animationDurationUpdate: 3000,
          axisLine: {
            //这是y轴文字颜色
            lineStyle: {
              color: "#fff"
            },
            undefined,
            show: false
          },
          axisLabel: {
            margin: 60,
            textStyle: {
              align: "center"
            }
          },
          axisTick: {
            undefined,
            show: false
          },
          max: 3 // only the largest 3 bars will be displayed
        },
        series: [
          {
            realtimeSort: true,
            type: "bar",
            barWidth: "18",
            data: [0, 0, 0, 0],
            label: {
              show: true,
              position: "right",
              valueAnimation: true,
              textStyle: {
                color: "#fff"
              }
            },
            itemStyle: {
              barBorderRadius: [10, 10, 10, 10],
              //每个柱子的颜色
              color: function(params) {
                var colorList = ["#1d7dff", "#02d2d8", "#02d876", "#f9466e"];
                return colorList[params.dataIndex];
              }
            }
          }
        ],
        legend: {
          show: true,
          textStyle: {
            color: "#fff"
          }
        },
        grid: {
          //与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算
          top: "10%",
          left: "0%",
          right: "20%",
          bottom: "10%",
          containLabel: true
        },
        animationDuration: 0,
        animationDurationUpdate: 1500,
        animationEasing: "linear",
        animationEasingUpdate: "linear"
      },
      //活跃度表
      options: {
        title: {
          text: "警方活跃度人数",
          textStyle: {
            color: "#fff",
            fontSize: 12
          },
          padding: [20, 0, 0, 20]
        },
        // 提示框
        tooltip: {
          show: true,
          trigger: "item",
          axiosPointer: {
            type: "shadow",
            axis: "auto"
          },
          padding: 5,
          textStyle: {
            color: "#3f95d8"
          }
        },
        xAxis: [
          {
            type: "category",
            offset: 8,
            fontSize: 12,
            nameLocation: "end",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#3f95d8"
            },
            axisLine: {
              //这是x轴文字颜色
              lineStyle: {
                color: "#3f95d8"
              }
            },
            axisTick: {
              show: false //---是否显示
            },
            nameGap: 30, //---坐标轴名称与轴线之间的距离

            data: []
          }
        ],
        yAxis: [
          {
            show: true, //---是否显示
            position: "left", //---y轴位置
            offset: 0, //---y轴相对于默认位置的偏移
            type: "value", //---轴类型，默认'category'
            nameLocation: "end", //---轴名称相对位置value
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#3f95d8",
              padding: [5, 0, 0, 5] //---坐标轴名称相对位置
            },
            nameGap: 15, //---坐标轴名称与轴线之间的距离
            axisLine: {
              //---坐标轴 轴线
              show: true, //---是否显示
              //------------------- 线 -------------------------
              lineStyle: {
                color: "#3f95d8",
                width: 1,
                type: "solid"
              }
            },
            splitLine: {
              // 表格里面Y轴线条
              show: false
            }
          }
        ],
        series: [
          {
            name: "活跃人数",
            type: "bar",
            barWidth: "25",
            legendHoverLink: true, //---是否启用图例 hover 时的联动高亮
            // 是否显示文本
            label: {
              //---图形上的文本标签
              show: true,
              position: "top", //---相对位置
              // rotate:90,               //---旋转角度
              color: "#fff"
            },

            itemStyle: {
              barBorderRadius: [2, 2, 0, 0],
              //---图形形状
              color: function(params) {
                var colorList = [
                  "#1d7dff",
                  "#02d2d8",
                  "#d8a302",
                  "#02d876",
                  "#d85d02",
                  "#f9466e",
                  "#435ef8"
                ];
                return colorList[params.dataIndex];
              }
            },
            data: []
          }
        ]
      }
    };
  },
  mounted() {
    this.getChart();
    this.getSvmTotal();
    this.getTenMinte();
    this.getReport();
    this.getActive();
    // this.options.series[0].data = [2314,5146,6142,5142,1541,1402,4156]
    // this.option.series[0].data = [1141,999,499,212]
    this.settimer();
  },
  methods: {
    getChart() {
      this.$axios("/chart").then(res => {
        // console.log(res)
        this.allTotal = res.data.sum;
        this.Docking = res.data.conduct;
        this.Dockingcomplete = res.data.complete;
        this.DockingTotal = res.data.djsum;
        this.setDockingData();
      });
    },
    getSvmTotal() {
      this.$axios("/ip_average").then(res => {
        // console.log(res)
        this.svm = res.data.svm;
      });
    },
    getTenMinte() {
      this.$axios("/time_chart").then(res => {
        // console.log(res)
        this.tenminteTotal = res.data.sum;
        this.SevendayDocking = res.data.Sevendaydocking;
        this.daydocking = res.data.daydocking;
        this.setDockingData();
      });
    },
    getReport() {
      this.$axios("/depth_analysis").then(res => {
        // console.log(res)
        this.report = res.data.sum;
        this.tenminteReport = res.data.totalsum;
      });
    },
    setDockingData() {
      this.option.series[0].data = [
        this.DockingTotal,
        this.Docking,
        this.SevendayDocking,
        this.daydocking
      ];
    },
    getActive() {
      this.$axios("/active").then(res => {
        // console.log(res)
        this.options.xAxis[0].data = res.data.days;
        this.options.series[0].data = res.data.nums;
      });
    },
    //定时器，1分钟更新一次数据
    settimer() {
      this.timer = setInterval(() => {
        this.getChart();
        this.getSvmTotal();
        this.getTenMinte();
        this.getReport();
        this.getActive();
        //假数据
        // this.svm += this.random(2)
        // this.docking += this.random(2)
        // this.DockingTotal += this.random(3)
        // this.allTotal += this.random(3)
        // this.tenminteTotal += this.random(2)
        // this.SevendayDocking += this.random(2)
        // this.daydocking += this.random(2)
        // this.report += this.random(3)
        // this.tenminteReport += this.random(2)
        // this.Dockingcomplete += this.random(2)
      }, 1000 * 10);
    },
    random(num) {
      return parseInt(
        Math.random()
          .toString()
          .slice(-num)
      );
    },

    toHome() {
      this.$router.push("/home");
    },

    toLogin() {
      window.localStorage.clear();
      window.sessionStorage.clear();
      this.$router.push("/login");
    }
  },
  beforeDestroy() {
     // 清除定时器
    clearInterval(this.timer);
    this.timer = null;
  }
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100%;
  zoom: 1;
  background-image: url('../../public/images/databg.png');
  background-size: 100% 100%;
 
}
.bg{
  width: 100%;
  height:100%; 
  position: absolute;
  left: 0;
  top: 0;
}

/* 公共样式 */

.global {
  width: 520px;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 15%;
  background-size: 100% 100%;
}

.global span {
  color: #fff;
  font-size: 16px;
}

.top_bar {
  position: absolute;
  right: 2%;
  top: 2%;
  color: #00eaff;
}

.top_bar:hover {
  box-shadow: 0px 0px 5px 1px #00eaff;
}

/* 中间的渐变线 */

.line {
  height: 90px;
  background-image: linear-gradient(
    rgba(7, 88, 148, 0),
    rgba(0, 255, 252),
    rgba(7, 88, 148, 0)
  );
  width: 2px;
}

.report {
  left: 4%;
  background-image: url("../../public/images/bgimg1-2.png");
}

.ipSearch {
  left: 36.5%;
  background-image: url("../../public/images/bgimg1-1.png");
}

.ipSearch div,
.docking div:nth-of-type(1),
.docking div:nth-of-type(2),
.svm div,
.report div {
  width: 48%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.svm {
  left: 68%;
  flex-direction: column;
  background-image: url("../../public/images/bgimg1-3.png");
}

.docking {
  top: 46%;
  left: 4%;
  height: 400px;
  align-items: flex-start;
  padding-top: 60px;
  background-image: url("../../public/images/bgimg2.png");
}

.docking_surface {
  width: 91%;
  position: absolute;
  top: 45%;
  left: 5%;
  background: #fff;
  height: 210px;
  border-radius: 4px;
  background-image: url("../../public/images/bgimg1.png");
  background-size: 100% 100%;
}

.Activity {
  left: 68%;
  top: 45%;
  height: 420px;
  padding-top: 60px;
  background-image: url("../../public/images/bgimg3.png");
  background-size: 100% 100%;
}

@media screen and (max-width: 1640px) {
  .global{
    width: 460px;
    top: 10%;
  }
  .docking, .Activity{
    height:420px;
    top: 39%;
  }
  .Activity{
    padding-top:50px;
  }
}
@media screen and (max-width: 1460px) {
  .global{
    width: 430px;
    height: 200px;
  }
  .docking, .Activity{
    height:380px;
    top: 39%;
  }
  .Activity{
    padding-top:50px;
  }
}
</style>