<template>
  <div>
    <div class="did" v-for="item in data" :key="item.id" v-show="item.is_check==0">
      <div class="title">
        {{ item.title }}
        <div class="username" v-if="item.company">----{{item.company}}--{{item.policename}}</div>
        <div class="username" v-else>----{{item.contacts}}--{{item.enterpriseName}}</div>
      </div>

      <div class="main">{{ item.content }}</div>
      <!-- 轮播 -->
      <div class="lunbo" v-if="item.zsk_img != '0'">
        <el-image :preview-src-list="imgUrl" v-for="(img, imgindex) of item.zsk_img" :key="imgindex" :src="BaseUrl + img" @click="setImg(item.zsk_img)"></el-image>
      </div>
      <div class="yesorno">
        是否通过审核？
        <div class="btn">
          <el-button type="danger" icon="el-icon-close" @click="audit(2,item.id,item.user_id)"></el-button>
          <el-button type="success" icon="el-icon-check" @click="audit(1,item.id,item.user_id)"></el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      BaseUrl:this.$store.state.BaseUrl,
      imgUrl:[]
    }
  },
  methods: {
    audit(state, id,user_id) {
      this.$emit('audit', state, id,user_id)
    },
    //设置大图预览的数据，必须是数组
     setImg(img) {
      this.imgUrl = []
      img.forEach( item => {
        this.imgUrl.push(this.BaseUrl + item)
      })
    }
  }
}
</script>
<style scoped>
.did {
  background-color: #fcfcfc;
  border-radius: 5px;
  padding: 30px;
  margin: 30px 20px;
  box-shadow: 0px 0px 7px rgba(147, 153, 154, 0.35);
}
.title {
  display: flex;
  align-items: flex-end;
  font-size: 25px;
}
.username {
  font-size: 14px;
  margin-left: 30px;
  color: #666;
}
.main {
  margin: 10px 0;
  font-size: 14px;
  color: #999;
  background-color: #fcfcfc;
  box-shadow: 0px 0px 0px #ffff;
}
.lunbo .el-image {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: 10px;
}
.yesorno {
  margin: 20px;
}
.btn {
  margin: 20px 0;
}
</style>
