<template>
  <div>
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>APP管理</el-breadcrumb-item>
        <el-breadcrumb-item>通知管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main card">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="resource">
          <el-radio-group v-model="ruleForm.resource">
            <el-radio label="通知"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="活动内容" prop="desc">
          <el-input type="textarea" maxlength="99" resize="none" :show-word-limit="true" rows="4" v-model="ruleForm.desc"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            v-loading.fullscreen.lock="fullscreenLoading"
            >立即创建</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
          <el-button type="success" @click="getHistory(1)">查看历史</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="发布历史" :visible.sync="dialogVisible" width="70%" close-on-click-modal>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%"
        >
          <el-table-column prop="title" label="活动名称"></el-table-column>
          <el-table-column prop="url" label="地址"></el-table-column>
          <el-table-column width="580px" prop="content" label="活动内容"></el-table-column>
          <el-table-column prop="createdAt" label="发布时间"></el-table-column>
        </el-table>
          <span slot="footer" class="dialog-footer">
           <Pagenation :size="5" :total="total" v-on:getPage="history_page" />
        </span>
      </el-dialog>
  </div>
</template>

<script>
import Pagenation from '../components/pagenation'
import { Base64 } from 'js-base64';
export default {
  components:{
    Pagenation
  },
  data() {
    return {
      fullscreenLoading: false,
      dialogVisible: false,
      tableData: [],
      total:0,
      ruleForm: {
        name: "",
        delivery: false,
        resource: "通知",
        desc: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        resource: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        desc: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
          {
            min: 10,
            max: 200,
            message: "长度在 10 到 200 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed:{
    widthStyle(){
      return this.$store.state.widthStyle
    }
  },
  methods: {
    submitForm(formName) {
       this.$confirm("确定发布吗?, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then( ()=>{
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          //浅拷贝
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
          this.$axios.post("/add_inform_all",{
              title: Base64.encode(ruleForm.name),
              url:"news/newsWhole",
              content: Base64.encode(ruleForm.desc),
              type: 4,
            },
          ).then(res => {
            this.fullscreenLoading = false;
          })
        } else {
          this.$message.error("输入不正确");
          return false;
        }
      });
      }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getHistory(val){
      this.$axios('/news_history?pagenumber='+ val).then( res=> {
        this.tableData = res.data
        this.total = res.count
        this.dialogVisible = true
      })
    },
    //历史分页
    history_page(val){
      this.getHistory(val)
    }
  },
};
</script>

<style>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.main {
  width: 70%;
  margin-top: 10px;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 1px 2px 0 rgba(134, 131, 131, 0.1),
    0 6px 20px 0 rgba(138, 135, 135, 0.1);
}
.el-pagination{
  margin-top: 10px;
  text-align: center;
}
.dialog-footer{
  position: absolute;
  bottom: 20px;
  left: 0;
  width:100%;
  display:flex;
  justify-content: center;
  padding-left:70px;
}
</style>
