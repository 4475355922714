<template>
  <div class="body">
    <!-- 面包屑区域  fullscreen-->
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>发票</el-breadcrumb-item>
        <el-breadcrumb-item>发票审核</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 审核区域 -->
    <div class="main_invoice ">
      <el-card shadow="hover" v-for="item in data" :key="item.id">
        <div class="username">
          申请人：{{item.invoice_name}}
        </div>
        <div class="money">
          发票金额：{{item.invoice_amount}} ¥
        </div>
        <div class="phone">
          电话：{{item.phone}}
        </div>
        <div class="invoice_title">
          发票抬头：{{item.invoice_title}}
        </div>
        <div class="invoice_number">
          单位税号：{{item.invoice_number}}
        </div>
        <div class="region">
          地址：{{item.region}}-{{item.address}}
        </div>
        <div class="region">
          时间：{{item.createdAt}}
        </div>
        <div class="yesorno" v-if="item.status==1">
          是否通过审核？
          <div class="btn">
            <el-button type="success" icon="el-icon-check" @click="audit(item.id)"></el-button>
          </div>
        </div>
        <div class="yesorno success" v-else>
          审核通过
        </div>
      </el-card>
    </div>
    <div class="paginations" :style="{width:widthStyle[1]}">
      <Pagenation :size="30" :total="total" v-on:getPage="handleCurrentChange" />
    </div>
  </div>
</template>
<script>
import Pagenation from "../components/pagenation";
export default {
  components: {
    Pagenation
  },
  data() {
    return {
      data: [],
      total: 0,
      page:1
    };
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    }
  },
  mounted() {
    //获取所有发票列表
    this.get_invoice_list(1);
  },
  methods: {
    get_invoice_list(val) {
      this.$axios({
        url: "/invoice_list?pagenumber=" + val
      })
        .then(data => {
          this.data = data.data;
          this.total = data.count;
        })
        .catch(err => {
          console.log(err);
        });
    },
    audit(id) {
      this.$axios
        .post("/invoice_audit", { 
          invoice_id: id,
          status: 2 
          }).then(data => {
          this.get_invoice_list(this.page);
        });
    },
    handleCurrentChange(val) {
      this.page = val
      this.get_invoice_list(val);
    }
  }
};
</script>
<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.main_invoice {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.el-card {
  width: 30%;
  margin: 10px;
}

.el-card div {
  padding: 5px 0;
}

.yesorno {
  float: right;
}

.success {
  color: yellowgreen;
}

.btn {
  text-align: end;
}

.paginations {
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 93.6%;
  background: #fff;
  margin-left: -20px;
}
</style>