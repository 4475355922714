<template>
    <div class="body">
        <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>任务管理</el-breadcrumb-item>
                <el-breadcrumb-item>员工管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row class="add">
          <!-- 搜索框 -->
          <el-input class="search" placeholder="请输入您要查询的用户名" v-model.trim="search_text" @keyup.enter.native="searchStaff"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="searchStaff">搜索</el-button>
          <el-button type="success" @click="dialogVisible = true">添加企业员工账号</el-button>
        </el-row>
        <div class="card">
          <el-card>
          <el-table :data="staff_list">
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="username" label="用户名"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="delStaff(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
         <!-- <div class="paginations" :style="{width:widthStyle[1]}">
          <Pagenation :size="30" :total="total" v-on:getPage="handleCurrentChange"/>
        </div> -->
          <!-- 添加账号弹窗 -->
          <el-dialog :visible.sync="dialogVisible" title="添加账号" center width="800px">
            <el-form :model="account_form" label-width="100px" :rules="rules">
              <el-form-item prop="username" label="用户名">
                <el-input v-model="account_form.username"></el-input>
              </el-form-item>
              <el-form-item prop="password" label="密码">
                <el-input type="password" v-model="account_form.password"></el-input>
              </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addAccount">确 定</el-button>
            </span>
          </el-dialog>
        </div>
    </div>
</template>

<script>
import Pagenation from "../components/pagenation";
import { connect } from "net";
import unixTimeToDateTime from "../filter/data";
export default {
  data() {
    return {
      staff_list: [],
      loading: true,
      account_form: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码必须包含字母与数字",
            trigger: "blur"
          },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur"
          }
        ]
      },
      dialogVisible: false,
      // 搜索的字段
      search_text:'',
      // 页码
      pagenumber:1,
      total:0,
      count:0,
    };
  },
  components: {
    Pagenation
  },
   directives: {
    focus: {
      inserted: function(el) {
        el.focus();
      }
    }
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    }
  },
  mounted() {
    this.getStaff(1);
  },
  methods: {
    //获取员工列表
    getStaff(val) {
      this.$axios.post("/staff_list",{ pagenumber:val}).then(res => {
        // console.log(res)
        this.staff_list = res.data;
        this.loading = false
      });
    },
    // 搜索员工
    searchStaff(){
      if (this.search_text == '') {
          this.$message({
            type: 'error',
            message: '输入框不能为空'
          })
        } else {
          this.$axios.post('/staff_search', {username: this.search_text})
            .then(res => {
              if (res.code == 200) {
                // console.log(res)
                this.staff_list = res.data
                this.show = true
                this.searchTotal = res.data.count
                //归零整个数据的条数，这样整个的分页就会隐藏
                this.total = 0
              } else {
                this.search_text = ''
                this.show = false
              }
            })
        }
    },
    //添加员工账号
    addAccount() {
      this.dialogVisible = false;
      this.$axios
        .post("/add_staff", {
          uname: this.account_form.username,
          password: this.account_form.password
        })
        .then(res => {
          if (res.code == 201) {
            this.getStaff();
          }
          this.dialogVisible = false;
        });
    },
    // 删除员工
    delStaff(id) {
      // console.log(id)
      this.$confirm("此操作将删除员工, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then( ()=>{
      this.$axios
        .post("/del_staff", {
          id
        })
        .then(res => {
          if (res.code == 201) {
            this.getStaff();
          }
        });
      }).catch( () => {
          this.$message({
              message:'已取消删除',
          })
      })
    },
    // 列表分页
    handleCurrentChange(val){
      this.pagenumber = val
      this.gettask_details(this.state,val)
    },
  }

};
</script>

<style scoped>
.el-form {
  width: 50%;
  margin: 0 auto;
}
.add {
  margin-top: 45px;
}
.search{
  width: 20%;
  margin-right: 20px;
}
.card {
  margin-top: 20px;
}
.card_select {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 10px;
}
.audit_err {
  color: red;
  margin-top: 5px;
}
.dialog-footer{
  justify-content: space-around;
}
</style>