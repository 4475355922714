<template>
  <div class="body">
    <div class="top" :style="{ width: widthStyle[2] }">
      <div slot="header" class="clearfix admin_audit_home">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>用户管理</el-breadcrumb-item>
          <el-breadcrumb-item>后台审核</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="top_search">
        <div>
          <el-button
            size="mini"
            :autofocus="true"
            v-focus
            plain
            @click="get_user_list_all()"
            >全部</el-button
          >
          <el-button
            type="primary"
            size="mini"
            plain
            @click="get_user_list_main(1)"
            >警方</el-button
          >
          <el-button
            type="primary"
            size="mini"
            plain
            @click="get_user_list_address('0')"
            >企业</el-button
          >
        </div>
        <!-- 搜索框部分 -->
        <div class="search_ip">
          <el-input
            placeholder="请输入您要查询的内容"
            v-model.trim="input_ip"
            clearable
            @clear="del_value()"
            @keyup.enter.native="search()"
          ></el-input>
           <el-select style="margin-right:20px;" v-model="select" @change="getSearchType" popper-class="getSearchType" placeholder="请选择字段">
          <el-option v-for="item in searchOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
          <el-button type="primary" icon="el-icon-search" @click="search"
            >搜索</el-button>
        <el-button type="primary" @click="crearSearch(1)">清空搜索</el-button>
        </div>
      </div>
    </div>

    <el-card class="box-card">
      <!-- 表格区域 -->
      <div>
        <el-table  :default-sort = "{prop: 'id', order: 'ascending'}" 
          :data="user_list"
          stripe
          style="width: 100%;"
          v-loading="loading"
          element-loading-text="拼命加载中"
        >
          <el-table-column prop="id" label="id" sortable></el-table-column>
          <el-table-column prop="policename" label="姓名"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column
            label="身份"
            prop="role_name"
            style="width: 100px;"
          ></el-table-column>
          <el-table-column prop="createdAt" label="注册时间"></el-table-column>
          <el-table-column prop="address" label="审核状态">
            <template slot-scope="scope">
              <div v-if="scope.row.is_pass == 1">审核中</div>
              <div v-else-if="scope.row.is_pass == 0">
                审核不通过:
                <span class="cause">{{ scope.row.cause }}</span>
              </div>
              <div v-else-if="scope.row.is_pass == 3">审核信息不完整</div>
              <div v-else>审核通过</div>
            </template>
          </el-table-column>
          <el-table-column prop label="单位名称">
            <template slot-scope="scope">
              <div v-if="scope.row.type == 1 && scope.row.company != ''">
                {{ scope.row.company }}
              </div>
              <div
                v-else-if="
                  scope.row.type == 2 && scope.row.enterpriseName != ''
                "
              >
                {{ scope.row.enterpriseName }}
              </div>
              <div
                v-else-if="
                  scope.row.type == 0 && scope.row.enterpriseName != ''
                "
              >
                {{ scope.row.enterpriseName }}
              </div>
              <div v-else style="color: red;">信息填写不完整</div>
            </template>
          </el-table-column>
          <el-table-column prop label="操作">
            <template slot-scope="scope">
              <div v-if="scope.row.is_pass == 1">
                <el-button
                  type="primary"
                  size="mini"
                  @click="ablout_is_pass(scope.row.id, scope.row.type)"
                  >去审核</el-button
                >
              </div>
              <div v-else-if="scope.row.is_pass == 2">
                <el-button
                  type="success"
                  size="mini"
                  @click="ablout_is_pass(scope.row.id, scope.row.type)"
                  >审核通过</el-button
                >
              </div>
              <div v-else-if="scope.row.is_pass == 3">
                <el-button type="danger" size="mini" disabled
                  >信息不完整</el-button
                >
              </div>
              <div v-else>
                <el-button
                  type="danger"
                  size="mini"
                  @click="ablout_is_pass(scope.row.id, scope.row.type)"
                  >审核失败</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="paginations" :style="{ width: widthStyle[1] }">
          <!-- 分页部分 -->
          <Pagenation
            :size="30"
            :total="total"
            v-on:getPage="handleCurrentChange"
          />
          <!-- 搜索的分页 -->
          <Pagenation
            :size="30"
            :total="searchTotal"
            v-on:getPage="getSearchPage"
          />
        </div>
      </div>

      <!-- 弹窗 -->
      <el-dialog
        title="信息"
        :visible.sync="drawer"
        width="70%"
        modal
        :close-on-click-modal="false"
        :fullscreen="fullscreen"
      >
        <div v-if="check == 1">
          <!--  -->
          <el-form label-width="80px">
            <el-form-item label="姓名">
              <el-input :value="info.policename" disabled></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
              <el-input :value="info.contacts" disabled></el-input>
            </el-form-item>
            <el-form-item label="单位名称">
              <el-input :value="info.company" disabled></el-input>
            </el-form-item>
            <div class="ablout_image">
              <el-image
                :preview-src-list="imgUrl"
                :src="BaseUrl + info.positiveImage"
                :z-index="20000"
                @click="setImg(info.positiveImage, info.nopositiveImage)"
              ></el-image>
              <el-image
                :preview-src-list="imgUrl"
                :src="BaseUrl + info.nopositiveImage"
                :z-index="20000"
                @click="setImg(info.positiveImage, info.nopositiveImage)"
              ></el-image>
            </div>
            <el-form-item label="审核结果" v-show="info.is_pass == 2">
              <el-radio-group value="2">
                <el-radio label="2" border disabled>通过</el-radio>
                <el-radio label="0" border disabled>不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="审核结果" v-show="info.is_pass == 0">
              <el-radio-group value="0">
                <el-radio label="2" border disabled>通过</el-radio>
                <el-radio label="0" border disabled>不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="审核结果" v-show="info.is_pass == 1">
              <el-radio-group v-model="label_value">
                <el-radio label="2" border>通过</el-radio>
                <el-radio label="0" border>不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="原因" v-if="label_value == 0">
              <el-input
                placeholder="请输入不通过原因"
                v-model="from_value"
              ></el-input>
            </el-form-item>
            <el-form-item label="原因" v-if="info.is_pass == 0">
              <el-input
                disabled
                placeholder="请输入不通过原因"
                :value="info.cause"
              ></el-input>
            </el-form-item>
          </el-form>
          <!--  -->
        </div>
        <div v-else>
          <!--  -->
          <el-form label-width="80px">
            <el-form-item label="企业电话">
              <el-input :value="info.enterprisePhone" disabled></el-input>
            </el-form-item>
            <el-form-item label="企负责人">
              <el-input :value="info.contacts" disabled></el-input>
            </el-form-item>
            <el-form-item label="企业名称">
              <el-input :value="info.enterpriseName" disabled></el-input>
            </el-form-item>
            <el-form-item label="企业地址">
              <el-input :value="info.address" disabled></el-input>
            </el-form-item>
            <div class="ablout_image">
              <el-image
                :preview-src-list="imgUrl"
                :src="BaseUrl + info.q_image"
                @click="setImg(info.q_image)"
                :z-index="20000"
              ></el-image>
            </div>
            <el-form-item label="审核结果" v-show="info.is_pass == 2">
              <el-radio-group value="2">
                <el-radio label="2" border disabled>通过</el-radio>
                <el-radio label="0" border disabled>不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="审核结果" v-show="info.is_pass == 0">
              <el-radio-group value="0">
                <el-radio label="2" border disabled>通过</el-radio>
                <el-radio label="0" border disabled>不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="审核结果" v-show="info.is_pass == 1">
              <el-radio-group v-model="label_value">
                <el-radio label="2" border>通过</el-radio>
                <el-radio label="0" border>不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="原因" v-if="label_value == 0">
              <el-input
                placeholder="请输入不通过原因"
                v-model="from_value"
              ></el-input>
            </el-form-item>
            <el-form-item label="原因" v-if="info.is_pass == 0">
              <el-input
                disabled
                placeholder="请输入不通过原因"
                :value="info.cause"
              ></el-input>
            </el-form-item>
          </el-form>
          <!--  -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="drawer = false">取 消</el-button>
          <el-button
            v-show="info.is_pass == 1"
            type="primary"
            @click="dialogVisible(info)"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import Pagenation from "../components/pagenation";
import { Base64 } from "js-base64";
export default {
  components: {
    Pagenation,
  },
  data() {
    return {
      // 表格数据
      user_list: [],
      total: 0,
      // 搜索查询内容
      input_ip: "",
      //搜索的字段
        search_type: 'username',
        options: [],
        value: '',
        searchOptions: [{
          value: 'username',
          label: '用户名'
        },{
          value: 'phone',
          label: '手机号'
        }],
        select: '用户名',
      // 选择身份
      type_id: undefined,
      // 弹窗开关
      drawer: false,
      // 身份类型
      type: undefined,
      // 用户数据
      info: "",
      // 单选框的值
      label_value: "2",
      // 不通过原因
      from_value: "",
      list_user: [],
      show: false,
      // 弹窗是否全屏
      fullscreen: false,
      // 审核状态
      Pagenumber: 1,
      //   选择框的选择
      loading: true,
      searchTotal: 0,
      BaseUrl: this.$store.state.BaseUrl,
      check: undefined,
      imgUrl: [],
    };
  },
  // 页面加载使
  mounted() {
    if (document.body.clientWidth < 550) {
      this.fullscreen = true;
    }
    this.get_user_list();
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      },
    },
  },
  //动态调整页码和top的宽度
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    },
  },
  methods: {
    get_user_list_all() {
      this.type_id = undefined;
      this.type = undefined;
      this.get_user_list();
    },
      //选择搜索哪个字段
      getSearchType(val) {
        this.search_type = val
      },
    // 搜索用户
    search(val) {
      if (val.isTrusted) {
        val = 1;
      }
      if (this.input_ip == "") {
        this.$message({
          type: "error",
          message: "输入框不能为空",
        });
      } else if (this.search_type == '') {
          this.$message({
            type: 'error',
            message: '请选择要搜索的字段'
          })
        }else {
        this.$axios
          .post("/audit_search", {
            username: this.input_ip,
            search_type: this.search_type,
            pagenumber: val,
          })
          .then((res) => {
             if (res.code == 200) {
                this.user_list = res.data
                this.show = true
                this.searchTotal = res.count
                //归零整个数据的条数，这样整个的分页就会隐藏
                this.total = 0
              } else {
                this.search_text = ''
                this.show = false
              }

            // this.show = true;
            // this.user_list = res.data;
            // this.searchTotal = res.count;
            // this.total = 0;
          });
      }
    },
    //清空搜索
      crearSearch(val) {
        this.get_user_list(val)
        //归零搜索数据的总数，这样搜索的分页就会隐藏
        this.searchTotal = 0
        this.search_text = ''

      },
    //搜索分页
    getSearchPage(val) {
      this.search(val);
    },
    // 警方
    get_user_list_main(type) {
      this.type = type;
      this.Pagenumber = 1;
      this.get_user_list(type);
    },
    // 企业
    get_user_list_address(type) {
      this.type = type;
      this.Pagenumber = 1;
      this.get_user_list(type);
    },
    // 获取数据
    get_user_list(type) {
      //将搜索的页码归零输入框清空
      this.searchTotal = 0;
      this.input_ip = "";
      if (type != undefined) {
        this.type_id = type;
        this.$axios
          .post("/admin_home_audit", {
            type,
            pagenumber: this.Pagenumber,
          })
          .then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.total = res.count;
              this.user_list = res.data;
              this.loading = false;
            }
          });
      } else {
        this.$axios
          .post("/admin_home_audit", {
            type: "",
            pagenumber: this.Pagenumber,
          })
          .then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.loading = false;
              this.total = res.count;
              this.user_list = res.data;
            }
          });
      }
    },
    // 分页部分
    handleCurrentChange(val) {
      this.Pagenumber = val;
      this.get_user_list(this.type);
    },
    // 审核部分
    dialogVisible(info) {
      // 判断用户点击的是通过还是不通过
      if (this.label_value == 2) {
        if (info.jq_user_qid != undefined) {
          this.$axios
            .post("/submit", {
              is_pass: 2,
              id: info.jq_user_qid,
              cause: "",
            })
            .then((res) => {
              this.drawer = false;
              this.show = false;
              this.$message({
                message: res.msg,
                type: "success",
              });
              window.location.reload();
            });
        } else {
          this.$axios
            .post("/submit", {
              is_pass: 2,
              id: info.jq_user_jid,
              cause: "",
            })
            .then((res) => {
              this.drawer = false;
              this.show = false;
              this.$message({
                message: res.msg,
                type: "success",
              });
              window.location.reload();
            });
        }
      } else {
        if (this.from_value == "") {
          // 如果审核不通过，必须填写审核不通过的原因
          this.$message({
            message: "审核原因不能为空",
            type: "error",
          });
        } else {
          if (info.jq_user_jid != undefined) {
            this.$axios
              .post("/submit", {
                is_pass: "0",
                id: info.jq_user_jid,
                cause: Base64.encode(this.from_value),
              })
              .then((res) => {
                this.drawer = false;
                this.show = false;
                this.from_value = "";
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.get_user_list();
              });
          } else {
            this.$axios
              .post("/submit", {
                is_pass: "0",
                id: info.jq_user_qid,
                cause: Base64.encode(this.from_value),
              })
              .then((res) => {
                this.drawer = false;
                this.show = false;
                this.from_value = "";
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.get_user_list();
              });
          }
        }
      }
    },
    // 去审核
    ablout_is_pass(id, type) {
      this.check = type;
      this.drawer = true;
      this.label_value = "2";
      this.$axios
        .post("/audit", {
          id: id,
          type: type,
        })
        .then((res) => {
          this.info = res.data;
        });
    },
    del_value() {
      this.show = false;
    },
    //查看大图
    setImg(img, img2) {
      this.imgUrl = [];
      if (img2 != undefined) {
        this.imgUrl.push(this.BaseUrl + img, this.BaseUrl + img2);
      } else {
        this.imgUrl.push(this.BaseUrl + img);
      }
    },
  },
};
</script>
<style scoped>
.top {
  height: auto;
  width: 92.5%;
  background: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 60px;
  padding: 10px;
}
.top_search {
  display: flex;
  flex-direction: row;
}
.box-card {
  margin: 120px 0 60px 0;
}
.search_ip {
  display: flex;
  width: 80%;
  margin: -5px 0 0 20px;
}
.search_ip > .el-input {
  width: 40%;
  margin-right: 10px;
}
.el-button--success {
  background: #29d49e;
  border: #29d49e;
}
.el-button--success:hover {
  background: #1d916c;
  border: #1d916c;
}
.search_ip > .el-input,
.el-button {
  display: inline-block;
}
.el-pagination {
  margin-top: 10px;
  text-align: center;
}

.paginations {
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 93.6%;
  background: #fff;
  margin-left: -20px;
}
.admin_audit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #999;
  background-color: #fff;
  margin-bottom: 20px;
}
.ablout_image {
  height: 220px;
  margin: 10px;
  text-align: center;
}
.cause {
  font-size: 12px;
  color: red;
}
.ablout_image > .el-image {
  width: 30%;
  height: 200px;
  margin: 0 80px;
}
@media screen and (max-width: 550px) {
  .ablout_image > img {
    width: 100%;
    height: 200px;
    margin: 0;
  }
  .ablout_image {
    height: 400px;
    margin: 10px;
    text-align: center;
  }
}
.search_table {
  margin-bottom: 20px;
  border-bottom: 1px solid #bfbfbf;
}
</style>
