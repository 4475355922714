<template>
  <div>
    <!-- <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>统计图</el-breadcrumb-item>
      </el-breadcrumb>
    </div>-->
    <Echars/>
  </div>
</template>
<style scoped>
</style>
<script>
import Echars from "../components/earchs";

export default {
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    }
  },
  components: {
    Echars
  },
  mounted() {
    // console.log(window.localStorage.getItem("token"));
  }
};
</script>
<style scoped>
</style>
