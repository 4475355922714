// 时间戳转换为日期时间 --年月日 时分秒
function unixTimeToDateTime(unixtime) {
    var date = new Date(unixtime);
    const Y = date.getFullYear() + '-';
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return (Y + M + D + h + m + s);

}
export default unixTimeToDateTime


























{/* <template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  AxisPointerComponent,
  PolarComponent,
  GeoComponent,
  SingleAxisComponent,
  ParallelComponent,
  CalendarComponent,
  GraphicComponent,
  ToolboxComponent,
  BrushComponent,
  TimelineComponent,
  MarkPointComponent,
  MarkLineComponent,
  MarkAreaComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
  AriaComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  BarChart,
  LineChart,
  GridComponent,
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  AxisPointerComponent,
  GridComponent,
  PolarComponent,
  GeoComponent,
  SingleAxisComponent,
  ParallelComponent,
  CalendarComponent,
  GraphicComponent,
  ToolboxComponent,
  TooltipComponent,
  AxisPointerComponent,
  BrushComponent,
  TitleComponent,
  TimelineComponent,
  MarkPointComponent,
  MarkLineComponent,
  MarkAreaComponent,
  LegendComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
  AriaComponent,
  DatasetComponent,
  TransformComponent,
]);

export default {
  name: "HelloWorld",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      option: {
        title: {
          text: "某地区蒸发量和降水量",
          textStyle: {
            color: "#f0f",
          },
          subtext: "副标题",
          //   padding:[0,0,100,100]
        },
        // 提示框
        tooltip: {
          show: true,
          trigger: "item",
          axiosPointer: {
            type: "shadow",
            axis: "auto",
          },
          padding: 5,
          textStyle: {
            color: "green",
          },
        },
        //  特殊功能
        // toolbox: {
        //   show: true,
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ["line", "bar"] },
        //     restore: { show: true },
        //     saveAsImage: { show: true },
        //   },
        // },
        // calculable: true,

        xAxis: [
          {
            type: "category",
            name: "月份",
            offset: 8,
            nameLocation: "end",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#f0f",
            },
            nameGap: 30, //---坐标轴名称与轴线之间的距离
            //nameRotate:270,           //---坐标轴名字旋转
            // 下面箭头的样式
            //  axisLine:{                  //---坐标轴 轴线
            //     show:true,                  //---是否显示

            //     //------------------- 箭头 -------------------------
            //     symbol:['none', 'arrow'],   //---是否显示轴线箭头
            //     symbolSize:[8, 8] ,         //---箭头大小
            //     symbolOffset:[0,7],         //---箭头位置

            //     //------------------- 线 -------------------------
            //     lineStyle:{
            //         color:'#f0f',
            //         width:1,
            //         type:'solid',
            //     },
            // },
            // axisTick: {
            //   //---坐标轴 刻度
            //   show: true, //---是否显示
            //   inside: true, //---是否朝内
            //   lengt: 3, //---长度
            //   lineStyle: {
            //     //color:'red',          //---默认取轴线的颜色
            //     width: 1,
            //     type: "solid",
            //   },
            // },
            // axisLabel: {
            //   //---坐标轴 标签
            //   show: true, //---是否显示
            //   inside: false, //---是否朝内
            //   rotate: 0, //---旋转角度
            //   margin: 5, //---刻度标签与轴线之间的距离
            //   //color:'red',              //---默认取轴线的颜色
            // },

            // 背景图
            // splitLine: {
            //   //---grid 区域中的分隔线
            //   show: true, //---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
            //   lineStyle: {
            //     //color:'red',
            //     //width:1,
            //     //type:'solid',
            //   },
            // },
            // splitArea: {
            //   //--网格区域
            //   show: true, //---是否显示，默认false
            // },



            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
             
            ],
          },
        ],
        yAxis: [
          {
            show:true,                  //---是否显示
                position:'left',            //---y轴位置
                offset:0,                   //---y轴相对于默认位置的偏移
                type:'value',           //---轴类型，默认'category'
                name:'销量',              //---轴名称
                nameLocation:'end',         //---轴名称相对位置value
                 nameTextStyle:{             //---坐标轴名称样式
                    color:"#fff",
                    padding:[5,0,0,5],  //---坐标轴名称相对位置
                 },
                 nameGap:15,                 //---坐标轴名称与轴线之间的距离
                                 axisLine:{                  //---坐标轴 轴线
                    show:true,                  //---是否显示
 
                    //------------------- 箭头 -------------------------
                    symbol:['none', 'arrow'],   //---是否显示轴线箭头
                    symbolSize:[8, 8] ,         //---箭头大小
                    symbolOffset:[0,7],         //---箭头位置
 
                    //------------------- 线 -------------------------
                    lineStyle:{
                        color:'#f0f',
                        width:1,
                        type:'solid',
                    },
                },
                  axisTick:{                  //---坐标轴 刻度
                    show:true,                  //---是否显示
                    inside:true,                //---是否朝内
                    lengt:3,                    //---长度
                    lineStyle:{
                        //color:'red',          //---默认取轴线的颜色
                        width:1,
                        type:'solid',
                    },
                },
                axisLabel:{                 //---坐标轴 标签
                    show:true,                  //---是否显示
                    inside:false,               //---是否朝内
                    rotate:0,                   //---旋转角度   
                    margin: 8,                  //---刻度标签与轴线之间的距离
                    color:'red',              //---默认取轴线的颜色
                },
          },
          
        ],
        series: [
          {
            name: "蒸发量",
            type: "bar",
            legendHoverLink:true,       //---是否启用图例 hover 时的联动高亮
            // 是否显示文本
            //  label:{                     //---图形上的文本标签
            //             show:true,
            //             position:'insideTop',   //---相对位置
            //             rotate:90,               //---旋转角度
            //             color:'#f0f',
            //         },


              itemStyle:{                 //---图形形状
                        color:'blue',
                        barBorderRadius:[18,18,0,0],
                    },
            data: [
              2.0,
              4.9,
              7.0,
              23.2,
              25.6,
              76.7,
              135.6,
              162.2,
              32.6,
              
            ],
            // markPoint: {
            //   data: [
            //     { type: "max", name: "最大值" },
            //     { type: "min", name: "最小值" },
            //   ],
            // },
            // markLine: {
            //   data: [{ type: "average", name: "平均值" }],
            // },
          },
        //   {
        //     name: "降水量",
        //     type: "bar",
        //     data: [
        //       2.6,
        //       5.9,
        //       9.0,
        //       26.4,
        //       28.7,
        //       70.7,
        //       175.6,
        //       182.2,
        //       48.7,
        //       18.8,
        //       6.0,
        //       2.3,
        //     ],
        //     // markPoint: {
        //     //   data: [
        //     //     { name: "年最高", value: 182.2, xAxis: 7, yAxis: 183 },
        //     //     { name: "年最低", value: 2.3, xAxis: 11, yAxis: 3 },
        //     //   ],
        //     // },
        //     // markLine: {
        //     //   data: [{ type: "average", name: "平均值" }],
        //     // },
        //   },
        ],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style> */}