<template>
  <div>
    <div class="ipSubmit_home" :style="{width:widthStyle[0]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>客服</el-breadcrumb-item>
        <el-breadcrumb-item>在线客服</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="mainChat">
      <div class="chat">
        <!-- 用户列表 -->
        <div class="user">
          <div>
            <h4>会话列表</h4>
          </div>
          <div class="ulist">
            <ul class="liFather">
              <li
                @click="getUserHis(item)"
                v-for="(item,index) in leftList"
                :key="index+'-user'"
                :class="item.id==userId ? 'item itCreate' : 'item'"
              >
                <div class="item">
                  <el-avatar :src="policeAva"></el-avatar>
                  <div>
                    <p>{{item.username}}</p>
                    <p style="color:navy;font-size:13px;">
                      <span style="color:teal;">Tel:</span>
                      {{item.phone}}
                    </p>
                  </div>
                  <span v-if="pointVisi && item.id==seviceId" class="point"></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 对话框 -->
        <div class="content">
          <!-- 当前聊天用户 -->
          <div class="item head">
            <el-avatar :src="policeAva"></el-avatar>
            <strong>{{userName}}</strong>
          </div>
          <!-- 框内内容 -->
          <!-- 1是自己 2是用户 3是自己图片 4是用户对方图片 -->
          <div class="contain" ref="mesScroll">
            <!-- 新消息 -->
            <div v-for="(items,index) in sendList" :key="index+'-label'">
              <div
                v-if="items.message_info!='order_1' || items.friend_uid==userId || items.user_id==userId ||items.user_id==0"
              >
                <!-- 接收与发送消息 -->
                <div
                  v-show="items.message_info!=''"
                  :class="items.user_id==userId ? 'mesItem' : 'messItem mesFloat'"
                >
                  <div class="item">
                    <!-- 用户消息 -->
                    <el-avatar
                      :src="policeAva"
                      v-if="items.message_info != '' && items.user_id==userId && items.message_info != 'order_1'"
                    ></el-avatar>
                    <div>
                      <p
                        v-show="items.message_info != 'order_1' && items.message_info != ''"
                        style="color:darkgray;font-size:12px;"
                      >{{items.time}}</p>
                      <div
                        :class="items.user_id==userId || items.friend_id== userId ? 'mess umess' : 'mess'"
                        v-if="items.message_type =='text' && items.message_info!='order_1' || items.friend_id== userId "
                      >{{items.message_info}}</div>
                      <!-- 订单样式 -->
                      <div
                        style="padding:10px 15px 45px 10px;"
                        :class="items.user_id==userId ? 'mess umess' : 'mess'"
                        v-if="items.message_type == 'order_0' || items.message_type == 'order_1' && items.message_info.type != 'order_1'"
                      >
                        <p>亲，请您核对订单信息</p>
                        <div>
                          <div
                            class="item"
                            style="background-color:#fbfbfb;padding:0 20px 0 0;margin:5px 0 0 0;"
                          >
                            <img
                              src="../assets/artificial.png"
                              width="80px"
                              style="margin-right:10px"
                            >
                            <div>
                              <p style="font-size:14px;">人工深度分析</p>
                              <p style="color:#999;font-size:12px;">{{items.message_info.typeValue}}</p>
                            </div>
                          </div>
                          <div class="list">
                            <ul>
                              <li>
                                分析数量：
                                <span>{{items.message_info.number}}个</span>
                              </li>
                              <li>
                                花费积分：
                                <span>{{items.message_info.sumPrice}}</span>
                              </li>
                              <li style="display:flex;">
                                订单详情：
                                <span>
                                  <template>
                                    <div
                                      v-for="(ite,inr) in items.message_info.address"
                                      :key="inr+'-ir'"
                                    >{{ite}}</div>
                                  </template>
                                </span>
                              </li>
                              <li>
                                订单备注：
                                <span>{{items.message_info.mark}}</span>
                              </li>
                              <li>
                                完成时间：
                                <span>{{sendTime}}</span>
                              </li>
                            </ul>
                          </div>
                          <el-button
                            type="primary"
                            round
                            size="mini"
                            class="yes"
                            :disabled="items.message_type=='order_1' || payOrder==true ? true : false"
                          >{{items.message_type=='order_1' || payOrder==true ? '已生成' : '未支付'}}</el-button>
                        </div>
                      </div>
                      <!-- 用户发送图片 -->
                      <div
                        style="margin-right:10px;"
                        v-if="items.message_type=='img' && items.user_id==userId"
                      >
                        <el-image
                          style="width: 100px; height: 200px"
                          :src="items.message_info"
                          fit="fill"
                          :preview-src-list="srcList"
                        ></el-image>
                      </div>
                      <!-- 客服自己图片 -->
                      <div
                        style="margin-right:10px;"
                        v-if="items.message_type=='img' && items.user_id==0 || items.user_id==curser_id && items.message_type=='img'"
                      >
                        <el-image
                          style="width: 100px; height: 100px"
                          :src="items.message_info"
                          fit="fill"
                          :preview-src-list="srcList"
                        ></el-image>
                      </div>
                    </div>
                    <el-avatar :src="avaUrl" v-if="items.user_id ==curser_id ||items.user_id==0 "></el-avatar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 发送消息框 -->
          <div class="send">
            <!-- 协助下单 -->
            <div style="padding-left:10px; display:flex; justify-content:space-between;">
              <!-- 上传图片 -->
              <div>
                <el-upload
                  class="upload-demo"
                  action="#"
                  multiple
                  :auto-upload="false"
                  :show-file-list="false"
                  :file-list="fileList"
                  list-type="picture"
                  :on-change="imgChange"
                >
                  <el-button
                    size="small"
                    style="margin-top: 10px !important;"
                    class="el-icon-picture-outline iconPic"
                  ></el-button>
                </el-upload>
              </div>
              <!-- 协助下单 -->
              <div style="display:flex;">
                <el-button
                  type="primary"
                  size="mini"
                  style="margin:10px 30px;"
                  @click="dialogVisible=true"
                >协助下单</el-button>
                <!-- 客服挂起 -->
                <el-button
                  :type="hang_type==true ? 'warning' : 'primary'"
                  size="mini"
                  style="margin:10px 30px 10px 0;"
                  @click="hangUp"
                >{{hang_type==true ? '客服挂起' : '恢复在线'}}</el-button>
              </div>
            </div>
            <!-- 发送内容 -->
            <div class="sendContent">
              <el-input
                type="text"
                autosize
                :rows="1"
                class="sendMes"
                @keydown.enter.native="send"
                v-model="sendText"
                placeholder="请输入内容……"
              ></el-input>
            </div>
            <!-- 发送 -->
            <div>
              <el-button type="success" size="mini" class="song" @click="send">发送</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 协助下单对话框 -->
    <div class="orderLog">
      <el-dialog title="协助下单" :visible.sync="dialogVisible" width="25%" :before-close="handleClose">
        <!-- 选择报告类型 -->
        <div>
          <label for="#">请选择要使用的分析类型：</label>
          <el-select @change="type" v-model="typeValue" placeholder="请选择" style="width:100%;">
            <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.type"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <!-- 请选择案件类型 -->
        <div>
          <label for="#">请选择分析的案件类型：</label>
          <el-select @change="ccsd" v-model="caseValue" placeholder="请选择" style="width:100%;">
            <el-option
              v-for="item in caseOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <!-- 单个地址分析所需积分 -->
        <div style="display:flex;justify-content:space-between;margin-top:20px;">
          <div>单个地址分析所需积分:</div>
          <span style="color:red">{{price}}</span>
        </div>
        <!-- 请输入镜像/APP/域名/IP(下载)数量 -->
        <div>
          <label for>请输入镜像/APP/域名/IP(下载)数量:</label>
          <el-input type="text" v-model="number"></el-input>
        </div>
        <!-- 请输入镜像/APP/域名/IP(下载)地址 -->
        <div>
          <label for>请输入镜像/APP/域名/IP(下载)地址:</label>
          <el-input
            type="textarea"
            v-model="address"
            placeholder="如需分析多个地址，一行一个地址(如有网盘地址请在地址后面附带网盘口令)"
          ></el-input>
        </div>
        <!-- 备注 -->
        <div>
          <label for>备注:</label>
          <el-input type="textarea" v-model="mark" placeholder="文字描述其他关键信息"></el-input>
        </div>
        <!-- 完成时间 -->
        <div class="dialogFlex">
          <div class="block">
            <label for>任务完成时间：</label>
            <el-date-picker v-model="timeValue" type="date" placeholder="选择日期"></el-date-picker>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="line-height:40px;">
            总积分：
            <span style="color:red">{{rePrice}}</span>
          </div>
          <div>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="orderEnter()">下单</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import artificial from "../assets/artificial.png";
// 客服头像
import avaUrl from "../assets/customer.png";
// 警方头像
import policeAva from "../assets/police.png";
import moment from "moment";
export default {
  data() {
    return {
      lockReconnect: false,
      // 测试
      // path: "ws://221.194.44.170:9502/",     
      // 正式
      path: "wss://tos.127.me/websocket/",
      // 客服挂起0还是恢复在线1
      hang_type: true,
      // 客服的user_id
      curser_id: JSON.parse(window.sessionStorage.getItem("info")).id,
      // 发送的消息
      sendList: [],
      // 发送消息的完成时间
      sendTime: "",
      // 发送的图片
      sendImg: "",
      diviShow: false,
      // 接收的订单支付状态
      payOrder: false,
      // 接受的消息的用户id
      seviceId: null,
      //已支付的订单号
      payID: "",
      // // 发送图片路径
      // iUrl: "",
      // 大图预览
      srcList: [],
      // 聊天对话框
      sendText: "",
      // 客服头像
      avaUrl: avaUrl,
      // 警方头像
      policeAva: policeAva,
      artificial: artificial,
      // 图片缩略图
      fileList: [],
      // 左侧警方列表
      leftList: [],
      newList: [],
      pointVisi: false,
      // 聊天对方用户id
      userId: null,
      userName: "",
      is_docking: null,
      // 下单弹出框
      dialogVisible: false,
      // 下单信息
      listText: {},
      // 选择要使用的分析类型
      typeOptions: [],
      typeValue: "",
      typeId: "",
      // 选择案件类型
      caseOptions: [],
      caseValue: "",
      caseId: "",
      // 所需积分
      price: 1280,
      // 下载数量
      number: null,
      // 下载地址
      address: "",
      newAddress: [],
      // 备注
      mark: "",
      // 任务完成时间
      timeValue: ""
    };
  },
  mounted() {
    this.isOnline();
    // 获取用户列表
    this.getUser();
    // 初次连接
    this.initWebSocket();
    // 获取类型
    this.getType();
    this.getHeight();
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    },
    rePrice() {
      if (this.number == null) {
        return this.price;
      } else {
        return Math.ceil(this.number) * parseInt(this.price);
      }
    }
  },
  watch: {
    sendList() {
      this.sendList.map(item => {
        if (item.message_type == "img") {
          this.srcList.push(item.message_info);
        } else if (
          item.message_type == "order_0" ||
          item.message_type == "order_1"
        ) {
          if (typeof item.message_info == "string") {
            item.message_info = JSON.parse(item.message_info);
          }
          if (item.message_info.completion_time != undefined) {
            let sstr = item.message_info.completion_time.substr(0, 1);
            if (sstr != "2") {
              let tt = item.message_info.completion_time + "000";
              this.sendTime = moment(Number(tt)).format("YYYY-MM-DD");
            }
          }
        } else if (item.time) {
          this.diviShow = true;
        }
      });
    }
  },
  methods: {
    // socket是否在线
    isOnline() {
      this.$axios.post("/get_now_online_type").then(res => {
        // console.log(res)
        if (res.data.is_online == 0) {
          this.hang_type = false;
          this.$message({
            message: "与服务器已断开连接",
            type: "warning"
          });
        }
      });
    },
    // 初始化weosocket
    initWebSocket() {
      try {
        if ("WebSocket" in window) {
          this.webSocket = new WebSocket(this.path);
        }
        // 接收消息
        this.webSocket.onmessage = this.webSocketMsg;
        this.webSocket.onopen = this.openWebsocket;
        this.webSocket.onerror = this.webSocketError;
        this.webSocket.onclose = this.closeWebSocket;
      } catch (e) {
        // reconnect(url);
        // console.log(e);
      }
      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = this.closeWebSocket();
    },
    // 二次测试连接
    openWebsocket(data) {
      let user_id = this.curser_id;
      let userID = this.userId;
      let sendd = [
        "initial_connections",
        {
          receiving_method: "initial_connections", // 接收返回数据的方法
          message_type: "text",
          user_id: user_id, // 当前用户ID
          friend_uid: userID, // 聊天对象uid
          user_type: 2, // 发送消息用户类型: 1:用户; 2:管理员;
          message_info: "建立连接" // 消息内容
        }
      ];
      this.webSocket.send(JSON.stringify(sendd));
    },
    // 连接失败
    webSocketError(error) {
      // console.log(error);
      this.$message({
        message: "连接已断开，请重新连接！",
        type: "error"
      });
    },
    // 数据接收
    webSocketMsg(e) {
      // console.log(JSON.parse(e.data));
      //数据接收
      let data = JSON.parse(e.data);
      if (data[0] == "initial_connections" && data[1].chat_code == 200) {
        this.$message({
          message: "已连接成功，可进行聊天！",
          type: "success"
        });
      }
      if (data[0] == "chat_callback_message") {
        if (data[1].friend_uid == this.curser_id) {
          if (!this.newList.includes(data[1].user_id)) {
            this.$message({
              message: "您有新用户加入！",
              type: "success"
            });
            this.getUser();
          }
        }
        if (
          data[1].user_id != this.userId &&
          data[1].user_id != this.curser_id
        ) {
          this.pointVisi = true;
          this.seviceId = data[1].user_id;
        } else if (data[1].message_info != "") {
          this.sendList.push(data[1]);
          this.getHeight();
        }
        if (data[1].user_id != this.curser_id) {
          this.$store.commit("setIcon", true);
        }
      }
    },
    // 数据发送
    sendWebSocket(data) {
      let user_id = Number(this.curser_id);
      let userID = this.userId;
      // console.log(userID);
      if (typeof data == "string") {
        var sendType = "text";
        if (data.substr(0, 4) == "data") {
          var sendType = "img";
        }
      } else if (typeof data == "object") {
        var sendType = "order_0";
        data = JSON.stringify(data);
      }
      //数据发送
      let mess = [
        "chat_callback_message",
        {
          receiving_method: "chat_callback_message", // 接收返回数据的方法
          message_type: sendType,
          user_id: user_id, // 当前用户ID
          friend_uid: userID, // 聊天对象uid
          user_type: 2, // 发送消息用户类型: 1:用户; 2:管理员;
          message_info: data // 消息内容
        }
      ];
      this.webSocket.send(JSON.stringify(mess));
    },
    // 连接关闭
    closeWebSocket(e) {
      if (e) {
      if (e.currentTarget.readyState == 3) {
        this.hang_type = false;
        // this.isOnline()
        this.initWebSocket();
      }
      }
    },
    reconnect(url) {
      if (lockReconnect) return;
      lockReconnect = true;
      setTimeout(function() {
        //没连接上会一直重连，设置延迟避免请求过多
        this.initWebSocket()
        lockReconnect = false;
      }, 2000);
    },
    // 客服挂起
    hangUp() {
      // console.log(this.hang_type);
      if (this.hang_type == true) {
        var type = 0;
      } else {
        var type = 1;
      }
      this.$axios
        .post("/customer_service_hang", { hang_type: type })
        .then(res => {
          if (res.code == 200 && this.hang_type) {
            this.$message({
              message: "已断开服务器连接",
              type: "error"
            });
            // this.$socket.ws.close()
          } else if (!this.hang_type) {
            this.openWebsocket();
            this.initWebSocket();
            this.$message({
              message: "已重新连接",
              type: "success"
            });
          }
          this.hang_type = !this.hang_type;
        });
    },
    // 获取聊天列表
    getUser() {
      this.$axios.post("/get_chat_list").then(res => {
        if (res.code == 200) {
          this.$message.close();
        }
        this.leftList = res.data;
        this.leftList.map(i => {
          this.newList.push(i.id);
        });
        let item = res.data[0];
        this.getUserHis(item);
      });
    },
    getUserHis(item) {
      this.userId = item.id;
      // console.log(this.userId);
      this.is_docking = item.is_docking;
      this.userName = item.username;
      this.pointVisi = false;
      this.getHistory();
    },
    // 获取历史聊天记录
    getHistory() {
      this.sendList = [];
      this.$axios
        .post("/get_chat_record_list", {
          is_docking: this.is_docking,
          user_id: this.userId,
          page: 0
        })
        .then(res => {
          let data = res.data;
          this.sendList.unshift(...res.data.reverse());
          this.getHeight();
          if (res.code == 204) {
            this.getUser();
          }
        });
    },
    // 输入框点击就发送或者回车触发的事件
    send() {
      if (this.sendText == "") {
        this.$message("不得发送空消息！");
      } else {
        const send = this.sendText.trim();
        const url = this.imgUrl;
        this.getHeight();
        this.sendWebSocket(send);
        this.sendText = "";
      }
    },
    getHeight() {
      this.$nextTick(() => {
        if (this.$refs != "undefined") {
          let mess = this.$refs.mesScroll;
          if (this.$refs.mesScroll != "undefiend") {
            mess.scrollTop = mess.scrollHeight;
            let his = this.$refs.hisScroll;
          }
        }
      });
    },
    // 上传图片
    imgChange(file, fileList) {
      // 转换图片格式为base64
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = e => {
        var $this = this;
        let iUrl = e.target.result;
        this.sendWebSocket(iUrl);
        this.getHeight();
        // this.srcList.push(iUrl);
      };
    },
    // 获取分析类型和案件类型
    getType() {
      this.$axios.post("/analysis_form_data").then(res => {
        this.typeOptions = res.data.analy_type;
        this.caseOptions = res.data.case_type;
      });
    },
    // 协助下单
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    type(v) {
      const typ = this.typeOptions.find(i => i.id == v);
      this.typeId = typ.id;
      this.typeValue = typ.type;
    },
    ccsd(value) {
      const cas = this.caseOptions.find(i => i.id == value);
      this.caseId = cas.id;
      this.caseValue = cas.name;
    },
    // 提交下单
    orderEnter() {
      this.payOrder = false;
      // 转换完成时间格式
      const data = this.timeValue.getTime();
      const completion_time = data.toString().substr(0, 10);
      // 当前时间的时间戳
      const time = Date.parse(new Date());
      const nowTime = time.toString().substr(0, 10);
      let daaa = moment(Number(nowTime)).format("YYYY-MM-DD");
      this.newAddress = this.address.split("\n");
      // 判断如果选择的时间小于当前时间则重新选择
      if (completion_time < nowTime) {
        this.$message({
          message: "不能小于当前时间，请选择正确的时间！",
          type: "error",
          duration: 1500,
          offset: 450
        });
      } else if (this.newAddress.length != this.number) {
        this.$message({
          message: "分析数量与地址数量不一致,请更改。",
          type: "error",
          duration: 1500,
          offset: 450
        });
      } else if (
        this.typeValue == "" ||
        this.caseValue == "" ||
        this.number == "" ||
        this.address == "" ||
        this.mark == ""
      ) {
        this.$message({
          message: "请检查是否填写完整！",
          type: "error",
          duration: 2000,
          offset: 450
        });
      } else {
        this.listText = {
          typeValue: this.typeValue,
          typeId: this.typeId,
          caseValue: this.caseValue,
          caseId: this.caseId,
          number: this.number,
          address: this.newAddress,
          order_type: 0,
          mark: this.mark,
          completion_time: completion_time,
          sumPrice: this.rePrice,
          nowTime: nowTime
        };
        this.$axios
          .post("/assist_in_placing_orders", {
            ...this.listText,
            uid: this.userId
          })
          .then(res => {
            if (res.code == 200) {
              let ab = { ...this.listText, ...res.data };
              this.sendWebSocket(ab);
              this.sendWebSocket(
                "您的订单已生成，订单号为" +
                  res.data.order_number +
                  "，请去“我的订单”页面支付。"
              );
              this.payOrder = true;
            }
          });
        this.dialogVisible = false;
        this.typeValue = "";
        this.caseValue = "";
        this.number = null;
        this.address = "";
        this.mark = "";
        this.getHeight();
      }
    }
  },
  destroyed() {
    this.webSocket.close();
  }
};
</script>
<style >
p {
  margin: 0 !important;
}
/*聊天*/
.mainChat {
  width: 60%;
  margin: 80px 0 0 12%;
  padding: 1px;
}
.chat {
  height: 700px;
  display: flex;
}
/*用户列表*/
.user {
  width: 25%;
  padding: 0 10px;
  background-color: whitesmoke;
}
.user h4 {
  color: #4f5050;
}
.ulist {
  box-shadow: 0px -5px 4px -5px lightgray;
  padding-top: 1px;
}
.liFather {
  padding-left: 0;
  /* overflow-y: scroll; */
  height: 50vh;
  overflow: hidden;
}
.liFather:hover{
  overflow-y: scroll;
}
.liFather > li {
  border-bottom: 1px dotted lightgray;
}
/*滚动条整体样式*/
.liFather::-webkit-scrollbar {
  width: 8px; /*y轴滚动条的宽度*/
  height: 16px; /*x轴滚动条的高度*/
  scrollbar-arrow-color: rgba(41, 96, 148, 0.8); /*滚动箭头的箭头元素的颜色*/
}
/*滚动条里面小方块*/
.liFather::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 5px rgba(106, 108, 109, 0.8);
  /* background: rgba(124, 160, 180, 0.8); */
  scrollbar-arrow-color: rgba(106, 108, 109, 0.8); /*滚动箭头的箭头元素的颜色*/
}
/*滚动条里面轨道*/
.liFather::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(106, 108, 109, 0.2);
  border-radius: 8px;
}
.ulist .item {
  cursor: pointer;
  padding: 0 10px 0 5px;
}
.itCreate {
  position: relative;
  cursor: pointer;
  background-color: gainsboro;
  font-weight: bold;
  box-shadow: 1px 1px 3px 1px silver;
}
.item {
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.point {
  position: absolute;
  right: -4px;
  border-radius: 4px;
  width: 8px;
  height: 8px;
  background-color: red;
  box-shadow: 0px 0px 3px red;
}
.el-avatar {
  margin-right: 10px;
}
.item p {
  font-size: 16px;
  margin: 0 !important;
}
/*聊天对话框*/
.content {
  width: 80%;
  position: relative;
  box-shadow: -10px 2px 8px -10px lightgray;
}
.head {
  width: 99%;
  padding: 10px 0 10px 10px;
  margin: 0;
  color: #fcfcfc;
  background-color: #4f5050;
}
/* 框内内容 */
.contain {
  padding: 10px;
  height: 418px;
  background-color: #fcfcfc;
  overflow-y: scroll;
}

/*滚动条整体样式*/
.contain::-webkit-scrollbar {
  width: 10px; /*y轴滚动条的宽度*/
  height: 16px; /*x轴滚动条的高度*/
  scrollbar-arrow-color: rgba(41, 96, 148, 0.8); /*滚动箭头的箭头元素的颜色*/
}
/*滚动条里面小方块*/
.contain::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 5px rgba(106, 108, 109, 0.8);
  background: rgba(106, 108, 109, 0.8);
  scrollbar-arrow-color: rgba(106, 108, 109, 0.8); /*滚动箭头的箭头元素的颜色*/
}
/*滚动条里面轨道*/
.contain::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(106, 108, 109, 0.2);
  border-radius: 8px;
  background: rgba(106, 108, 109, 0.2);
}
.mesItem {
  margin: 20px 0;
}
.mesFloat {
  display: flex;
  justify-content: right;
}
.mess {
  width: 24vh;
  border-radius: 5px;
  padding: 8px 10px;
  margin: 3px 10px 0;
  position: relative;
  background-color: #fff;
  word-break: break-all;
  box-shadow: 0 0 5px 2px rgba(240, 240, 240, 0.5);
}
.umess {
  color: #fff;
  background-color: #2878ff;
}
.umess::after {
  left: -14px;
  border-left-color: transparent !important;
  border-right-color: #2878ff !important;
}
.mess::after {
  position: absolute;
  right: -14px;
  top: 40%;
  content: "";
  display: block;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  border: 7px solid transparent;
  border-left-color: #fff;
}
.list {
  color: #999;
  font-size: 12px;
}
.list > ul {
  padding-left: 0;
}
.list > ul > li {
  margin: 5px 0;
}
.list > ul > li > span {
  margin-left: 5px;
}
.yes {
  float: right;
}
/* 发送消息框 */
.send {
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  background-color: #fcfcfc;
  box-shadow: 0 -4px 2px 0 rgba(235, 235, 235, 0.5);
}
.sendContent {
  margin: 10px 0;
}
.sendMes > .el-input__inner {
  border: 1px solid transparent !important;
  background-color: #fcfcfc;
  padding: 0 15px;
  /* background-color: #333; */
  /* height: 100px; */
}
.sendMes > .el-input__inner::placeholder {
  margin: 0 !important;
  padding: 0 !important;
}
.song {
  position: absolute;
  right: 20px;
  bottom: 10px;
}
/* 上传图片 */
.iconPic {
  border: 1px solid transparent !important;
  font-size: 20px !important;
}
.iconPic:hover {
  background-color: transparent !important;
}
/*协助下单对话框*/
.el-dialog__body {
  padding: 0 30px 20px !important;
}
.el-textarea__inner {
  height: 100px;
}
.orderLog .el-dialog__wrapper {
  top: -90px;
}
.el-dialog__footer {
  padding: 0;
}
.dialog-footer {
  position: inherit;
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.orderLog label {
  display: block;
  margin: 20px 0;
}
</style>
    