import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 登录的信息
        info: [],
        bar: [],
        widthStyle: ['85.5%', '93.6%', '86.5%'],
        // 正式 
        BaseUrl: 'https://api.hitevil.com/',
        // 测试
        // BaseUrl: 'http://221.194.44.170:6868/',
        recive: false,
        newShow: true,
        socket: false,
        fileList:false
    },
    getters: {

    },
    mutations: {
        // 存入登录信息
        store_add(state, step) {
            state.info = step
        },
        //存入导航栏信息
        store_add_bar(state, bar) {
            state.bar = bar
        },
        //存入动态宽度
        store_add_width(state, widthStyle) {
            state.widthStyle = widthStyle
        },
        // 更改是否收到消息
        setRe(state, newRe) {
            state.recive = newRe
        },
        // 更改客服小标
        setIcon(state, newShow) {
            state.newShow = newShow
        },
        // 监听socket状态
        setSoc(state, newSoc) {
            state.socket = newSoc
        },
        // 更改图片上传组件的列表
        setList(state,newValue){
            state.fileList = newValue
        }
    },
    actions: {},
    modules: {},
})