<template>
  <div class="login">
    <div class="left_img">
      <h1>警方与企业的信息交互平台</h1>
      <p>一个为互联网企业提供更加有效的信息交换系统</p>
      <img src="../../public/images/banner.png" alt>
    </div>
    <div class="login_main">
      <div class="login_text" v-show="user_type">欢迎登录云追查管理系统</div>
      <div class="code-login" :class="{'zhLogin': !user_type}">
        <a href="#" @click="user_type = !user_type">{{ user_type ? "扫码登录" : "账号登录" }}</a>
      </div>

      <!-- 登录表单区域 -->
      <div v-if="user_type">
        <el-form ref="form_ref" :model="j_LoginForm" :rules="loginRules" class="login_form">
          <!-- 用户名 -->
          <el-form-item prop="uname">
            <el-input v-model="j_LoginForm.uname" placeholder="请输入用户名" id="inputId">
              <!-- <i slot="prefix" class="el-input__icon el-icon-user"></i> -->
              <img slot="prefix" src="../../public/images/zhanghao.png" alt>
            </el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="upwd">
            <el-input
              id="bgColor"
              placeholder="请输入密码"
              v-model="j_LoginForm.upwd"
              type="password"
              @keyup.enter.native="j_login()"
            >
              <!-- <i slot="prefix" class="iconfont icon-mimasuoding"></i> -->
              <img slot="prefix" src="../../public/images/mima.png" alt>
            </el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item class="btns">
            <a href="#" class="btns_login" @click="j_login">登录</a>
          </el-form-item>
        </el-form>
      </div>
      <div v-else class="Qcode">
        <div>
          <h2>手机扫码 快速登录</h2>
          <div>请打开云追查APP进行扫码登录</div>
          <img :src="url" alt v-if="Qr_code == 1">
          <div
            class="zhezhao"
            @click="code_login(),startTimer()"
            v-else-if="Qr_code == 2"
          >二维码失效，点击刷新验证码</div>
          <div class="zhezhao" v-else>已扫码，请等待.....</div>
          <div class="toRegister" @click="regist_push">
            <a href="#">免费注册</a>
          </div>
          <div :class="{'tozhLogin': !user_type}" @click="user_type = !user_type">
            <a href="#">账号登录</a>
          </div>
        </div>
      </div>
      <div class="regist" v-show="user_type">
        还没有账号，去
        <span @click="regist_push">注册</span>
      </div>
    </div>
  </div>
</template>

<script>
//引入密码加密
export default {
  data() {
    return {
      // 用户type
      user_type: true,
      // 二维码图路径
      url: "",
      // uuid
      uuid: "",
      j_LoginForm: {
        uname: "",
        upwd: ""
      },
      q_LoginForm: {
        uname: "",
        upwd: ""
      },
      // 验证规则
      loginRules: {
        uname: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur"
          }
        ],
        upwd: [
          { required: true, message: "密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur"
          }
        ]
      },
      // 定时器
      settimer: "",
      Qr_code: 1
    };
  },
  watch: {
    // 扫码登录
    user_type() {
      if (!this.user_type) {
        this.startTimer();
      } else {
        clearInterval(this.settimer);
      }
    }
  },
  mounted() {
    //先清空储存的数据
    window.localStorage.clear();
    window.sessionStorage.clear();
    // 执行扫码登录
    this.code_login();
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
      })();
    };
    // 自动获取焦点
    document.getElementById("inputId").focus();
  },
  methods: {
    // 警方登录
    j_login() {
      window.localStorage.clear();

      this.$refs.form_ref.validate(valid => {
        if (valid) {
          //加密密码
          let newData = {
            uname: this.j_LoginForm.uname,
            upwd: this.j_LoginForm.upwd
          };
          this.$axios({
            url: "/login",
            method: "post",
            data: newData
          }).then(res => {
            if (res.code == 200) {
              // this.localSocket();
              // 存入到缓存区中的token
              window.localStorage.setItem("token", res.token);
              window.localStorage.setItem("roleId", res.data.info.role_id);
              window.sessionStorage.setItem(
                "info",
                JSON.stringify(res.data.info)
              );
              window.sessionStorage.setItem(
                "info1",
                JSON.stringify(res.data.info1)
              );
              //将导航栏数据存到sessionStorage中
              window.sessionStorage.setItem(
                "bar",
                JSON.stringify(res.data.left)
              );
              this.$message({
                message: "登陆成功",
                type: "success"
              });
              if (res.data.info.role_id == 666) {
                //666是监管者身份，跳转到大数据页面
                this.$router.push("/big_data");
              } else if (res.data.info.role_id == 999) {
                // admin账号，则跳转到统计图页面
                this.$router.push("/echars_tj");
              } else {
                //跳转到导航栏的第一个页面
                this.$router.push(
                  res.data.left[0].wenidc_son[0].wenidc_action_url
                );
              }
            } else if (
              res.data.code == 0.4 ||
              res.data.code == 0.2 ||
              res.data.code == 0.3
            ) {
              window.localStorage.setItem("token", res.data.token);
              window.localStorage.setItem("data", JSON.stringify(res.data));
              this.$router.push("/regist_trail");
            }
          });
        } else {
          this.$message({
            message: "登录失败",
            type: "warning"
          });
        }
      });
    },
    
    //定时器
    startTimer() {
      this.settimer = setInterval(() => {
        this.$axios.post("/polling_code", { uuid: this.uuid }).then(res => {
          // console.log(res)
          if (res.code == 410) {
            this.Qr_code = 2;
            clearInterval(this.settimer);
          } else if (res.code == 200) {
            this.Qr_code = 1;
            clearInterval(this.settimer);
            // 存入到缓存区中的token
            window.localStorage.setItem("token", res.token);
            window.sessionStorage.setItem(
              "info",
              JSON.stringify(res.data.info)
            );
            window.sessionStorage.setItem(
              "info1",
              JSON.stringify(res.data.info1)
            );
            //将导航栏数据存到sessionStorage中
            window.sessionStorage.setItem("bar", JSON.stringify(res.data.left));
            this.$message({
              message: res.msg,
              type: "success"
            });
            if (res.data.info.role_id == 666) {
              //666是监管者身份，跳转到大数据页面
              this.$router.push("/big_data");
            } else {
              //跳转到导航栏的第一个页面
              this.$router.push(
                res.data.left[0].wenidc_son[0].wenidc_action_url
              );
            }
          } else if (res.code == 100) {
            this.Qr_code = 3;
          }
        });
      }, 2500);
    },
    code_login() {
      // 发送请求获取二维码
      this.$axios("/code").then(res => {
        this.url = res.data.src;
        this.uuid = res.data.identification;
        this.Qr_code = 1;
        // 请求轮询接口
      });
    },
    // 注册跳转
    regist_push() {
      this.$router.push("/regist_type");
    }
  }
};
</script>
<style>
.login {
  background: url(../../public/images/bssjx.png) no-repeat center;
  height: 100%;
}

.left_img {
  width: 560px;
  height: 100vh;
  position: absolute;
  display: flex;
  background-color: #071f46;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  color: #ccc;
}

.left_img img {
  margin-top: 90px;
}

.left_img h1 {
  font-size: 28px;
  color: #fff;
  margin-top: -100px;
}

.login_main {
  width: 550px;
  height: 400px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-10%);
  background-color: #fff;
}

.login_form {
  position: absolute;
  top: 23%;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.el-form-item:nth-of-type(2) {
  margin-top: 40px;
  margin-bottom: 50px;
}

#bgColor:focus,
#inputId:focus {
  border: 2px solid rgb(40, 56, 82);
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(40, 56, 82, 0.15);
}

.el-input img {
  margin: 10px 10px 0 5px;
}

.btns {
  text-align: center;
  width: 100%;
  background-color: #409eff;
  display: block;
  border-radius: 4px;
  height: 45px;
}

.btns_login {
  width: 100%;
  display: block;
  text-decoration: none;
  color: #fff;
  line-height: 45px;
}

.btns_login:hover {
  background-color: #70b6ff;
  color: #fff;
}

.login_text {
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #666;
}

.regist {
  position: absolute;
  bottom: 35px;
  left: 10px;
  font-size: 12px;
}

.code-login {
  position: absolute;
  bottom: 35px;
  right: 10px;
  font-size: 12px;
}

.zhLogin {
  left: 55%;
  bottom: 45px;
}

a {
  color: black;
  text-decoration: none;
}

.regist > span {
  color: #0aa1ed;
  text-decoration: underline;
  cursor: pointer;
}

.Qcode {
  position: absolute;
  top: 20%;
  right: 50%;
  height: 60%;
  transform: translate(50%);
  font-size: 12px;
  color: #999;
}

.Qcode h2 {
  color: black;
  text-align: center;
  font-size: 20px;
}

.zhezhao {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%);
  color: #fff;
  width: 177px;
  height: 177px;
  background-color: rgba(31, 31, 29, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.toRegister {
  position: absolute;
  bottom: -35px;
  left: 0;
  font-size: 16px;
  color: black;
  display: block;
  font-size: 12px;
}

.tozhLogin {
  position: absolute;
  bottom: -30px;
  right: 0;
  font-size: 12px;
  color: black;
  display: none;
}

.Qcode img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1230px) {
  .left_img {
    display: none;
  }
  .login_main {
    left: 30%;
    top: 20%;
  }
  .zhLogin {
    display: none;
  }
  .tozhLogin {
    display: block;
    bottom: -35px;
  }
}

@media screen and (max-width: 550px) {
  .login_main {
    width: 90%;
    left: 15%;
    top: 20%;
  }
  .login_text {
    font-size: 12px;
    top: 15%;
  }
  .code-login {
    bottom: -30px;
  }
  .tozhLogin {
    bottom: -30px;
  }
  .toRegister {
    bottom: -30px;
  }
  .regist {
    bottom: -30px;
    right: 13px;
    width: 50%;
  }
}
</style>