import axios from 'axios'
import ElementUi from 'element-ui'
import router from '../router/index'

import {
    encryption,
    decryption
} from './phpapi.js'
// 测试
// axios.defaults.baseURL = 'http://221.194.44.170:6868/admin/'
// 正式
axios.defaults.baseURL = 'https://api.hitevil.com/admin/'

axios.defaults.withCredentials = true;
// 请求拦截器
axios.interceptors.request.use(
        configs => {
            configs.headers = {
                'Content-Type': 'application/json',
                'version': 'v3'

            }
            if (configs.url == "/query_state") {
                configs.headers.Authorization = window.localStorage.getItem('token') || undefined
            } else if (window.sessionStorage.getItem('info')) {
                configs.headers.Authorization = window.localStorage.getItem('token') || undefined
            }
            return configs
        }

    )
    // 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.code == 101 || response.data.code == 102) { //101 未登陆 102重复登陆
            window.localStorage.clear()
            window.sessionStorage.clear()
            ElementUi.Message({
                message: response.data.msg,
                type: 'error'
            })
            router.replace({
                path: '/login',
                query: {
                    redirect: router.currentRoute.fullPath
                }
            });
            return response
        }

        //返回的参数是字符串，先转成对象才能用
        response = response.data
            // response = JSON.parse(response.data)
            //204修改失败，303用户名已存在,410密码错误次数过多
        if (response.code == 204 || response.code == 500 || response.code == 303 || response.code == 410 || response.code == 405 || response.code == 310 || response.code == 311) {
            ElementUi.Message({
                message: response.msg,
                type: 'error'
            })
            return response
        } else if (response.code == 201 || response.code == 202 || response.code == 200) { //201修改成功,202查询为空

            // if (response.config.url != '/get_chat_record_list') {
            ElementUi.Message({
                message: response.msg,
                type: 'success',
                duration: 1000
            })

            // }
            return response
        } else if (response.code == 313) { //313未认证
            ElementUi.Message({
                message: '您还未提交认证，为您跳转到认证界面',
                type: 'warning'
            })
            window.localStorage.setItem('user_id', response.data.user_id)
            if (response.data.type == 1) {
                //1是警方，跳转到警方认证
                router.replace({
                    path: '/j_regist'
                });
            } else {
                //跳转到企业认证
                router.replace({
                    path: '/q_regist'
                });
            }
        } else if (response.code == 111) { //扫码轮询，111代表未扫描
            return response
        } else {
            //对返回的data数据进行解密，解密之后是字符串形式，所以再转一次
            //如果有用户信息，就使用token解密，没有就默认解密
            if (response.data) {
                if (window.sessionStorage.getItem('info')) {
                    // response.data = JSON.parse(decryption(response.data, window.localStorage.getItem('token')))
                    return response
                } else {
                    // response.data = JSON.parse(decryption(response.data, 'wenidc'))
                    return response
                }
            } else {
                return response
            }
        }
    }
)

export default axios