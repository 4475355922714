<template>
  <!-- 面包屑区域 -->
  <div class="ipSubmit_home" :style="{ width: widthStyle[0] }">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>任务管理</el-breadcrumb-item>
      <el-breadcrumb-item>授权函审核</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name:'crumbs',
  data() {
    return {
      widthStyle: this.$store.state.widthStyle,
    };
  },
};
</script>
<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
</style>
