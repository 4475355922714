<template>
  <div>
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>IP搜索</el-breadcrumb-item>
        <el-breadcrumb-item>IP搜索</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="input">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="IP搜索：">
          <el-input  v-model="formInline.ip" maxlength="30" placeholder="请输入IP地址" class="oinp"></el-input>
        </el-form-item>
        <el-form-item class="searchBtn">
          <el-button type="primary" @click="onSubmit" v-loading.fullscreen.lock="fullscreenLoading">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table" v-if="tableData.length != 0">
      <el-table :data="tableData" style="width: 100%;">
        <el-table-column prop="ip" label="IP" width="180"></el-table-column>
        <el-table-column prop="usage_id" label="IP" width="180">
          <template slot-scope="scope">
            <div v-if="scope.row.usage_id ==1 ">自用</div>
            <div v-else >代理</div>
          </template>
        </el-table-column>
        <el-table-column prop="submitter" label="公司" width="180"></el-table-column>
        <el-table-column prop="address" label="地址"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
let ipTest = /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/
export default {
  data() {
    return {
      input: '',
      formInline: {
        ip: ''
      },
      tableData: [],
      fullscreenLoading: false
    }
  },
 computed:{
    widthStyle(){
      return this.$store.state.widthStyle
    }
  },
  methods: {
    onSubmit() {
      if (!ipTest.test(this.formInline.ip)) {
        this.$message.error('IP格式错误')
        return
      }
      this.fullscreenLoading = true
      this.$axios({
        url: '/ip_lookup',
        method: 'POST',
        data: {
          ip_look: this.formInline.ip
        },
        timeout: 5000
      }).then(res => {
            this.fullscreenLoading = false
          this.tableData = res.data
          this.fullscreenLoading = false
        },
        () => {
          this.fullscreenLoading = false
          this.$message.error('请求超时！')
        }
      )
    }
  }
}
</script>

<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.searchBtn {
  margin: 0;
}
.input {
  margin-top: 50px;
}
.input /deep/ .el-textarea{
  vertical-align: middle;
}
.oinp {
  width: 500px;
}
</style>
