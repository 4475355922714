<template>
  <div class="body">
    <!-- 面包屑区域 -->
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>APP管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户反馈</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card">
      <el-card class="box-card">
           反馈信息：
        <div class="main">
             <div class="div_Dad" v-for="(p,i) of data" :key="i">
         <div class="text">{{p.opinion}}</div>
         <div class="image" v-if="p.img!='0'">
              <el-image
               v-for="(item,index) in p.img"
              style="width: 150px; height: 150px"
              :src="BaseUrl + item"
              :preview-src-list="imgUrl"
              :key="index"
              :z-index='20000'
              @click="setImg(p.img)"
            ></el-image>
         </div>
         <div class="text_timer">
             <div class="user">用户：{{p.user_name}}</div>
             <div class="time">评价时间：{{p.createdAt | timer}}</div>
         </div>
        </div>
     </div>
      </el-card>
      <div class="paginations" :style="{width:widthStyle[1]}">
        <Pagenation :size="20" :total="total" v-on:getPage="getSearchPage" />
     </div>
    </div>

  </div>
</template>
<script>
import Pagenation from '../components/pagenation'
export default {
  components:{
    Pagenation
  },
    data() {
        return {
            data:[],
            total:0,
            BaseUrl:this.$store.state.BaseUrl,
            imgUrl:[]
        }
    },
     mounted(){
        this.get_opinion(1)
    },
    computed:{
        widthStyle(){
         return this.$store.state.widthStyle
        }
  },
    methods: {
        get_opinion(val){
         this.$axios('/get_opinion?pagenumber='+ val ).then(res=>{    
             this.data=res.data
             this.total = res.count
         })
        },
        // //分页
        getSearchPage(val){
            this.get_opinion(val)
        },
        setImg(img){
            this.imgUrl = []
            img.forEach( item => {
                this.imgUrl.push(this.BaseUrl + item)
            })
        }
    },
};
</script>
<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.main{
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 0;
}
.div_Dad{
    flex: 3;
    padding: 20px;
    margin: 10px;
    border: 1px solid #bfbfbf;
    border-radius: 10px;
}
.image>img{
    margin-top: 20px;
    padding: 10px;
    border:  1px dashed #666 ;
}
.text_timer{
    margin-top: 20px;
    font-size: 14px;
    color: #999;
    text-align: right;
}
.paginations{
  position: fixed;
  bottom: 0;
  z-index:999;
  width: 93.6%;
  background: #fff;
}
</style>
    