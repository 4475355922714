<template>
    <div class="body">
        <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>分析公司</el-breadcrumb-item>
                <el-breadcrumb-item>分析公司列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="card">
            <el-card>
                <el-table :data="q_info" stripe v-loading="loading">
                    <el-table-column prop="q_id" width="150px" label="ID"></el-table-column>
                    <el-table-column width="300px" prop="enterpriseName" label="企业名称"></el-table-column>
                    <el-table-column prop="contacts" label="联系人"></el-table-column>
                    <el-table-column prop="enterprisePhone" label="公司电话"></el-table-column>
                    <el-table-column label="当前任务量">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.underway">{{scope.row.underway}}</span>
                                <span v-else>0</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="meanwhile" label="最大任务量"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" @click="compile(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
            <div class="paginations" :style="{width:widthStyle[1]}">
             <Pagenation :size="30" :total="total" v-on:getPage="handleCurrentChange"/>
            </div>

            <!-- 弹窗 -->
            <el-dialog :visible.sync="dialogVisible" title="编辑" center width="800px">
                <el-row>
                    企业名称 <el-input style="margin-left:25px" :value="enterpriseName" disabled></el-input>
                </el-row>
                <el-row>
                    最大任务量<el-input onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" v-model="meanwhile" placeholder="请输入数量"></el-input>
                </el-row>
                <el-row>
                    <el-button type="primary" @click="onSubmit">确认</el-button>
                </el-row>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Pagenation from "../components/pagenation";
export default {
  data() {
    return {
      dialogVisible: false,
      q_info: [],
      enterpriseName: "",
      meanwhile: '',
      q_id: null,
      loading: true,
      total:0
    };
  },
  components:{
    Pagenation
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    }
  },
  mounted() {
    this.todistribution(1);
  },
  methods: {
    //获取企业列表
    todistribution(val) {
      this.$axios.post("/enterprise_lists",{
        pagenumber:val
      }).then(res => {
        // console.log(res)
        this.q_info = res.data;
        this.total = res.count
        this.loading = false
      });
    },
    //打开弹窗
    compile(row) {
      this.enterpriseName = row.enterpriseName;
      this.q_id = row.q_id;
      this.dialogVisible = true;
    },
    //编辑任务数量
    onSubmit() {
      if (this.meanwhile != '') {
        this.$axios
          .post("/task_volume", {
            qid: this.q_id,
            meanwhile: this.meanwhile
          })
          .then(res => {
            if (res.code == 200) {
              this.dialogVisible = false;
              this.meanwhile = ''
              this.todistribution(1);
            }
          });
      } else {
        this.$message({
          message: '请填写数量',
          type: "error"
        });
      }
    },
    // 分页
    handleCurrentChange(val){
      this.todistribution(val)
    }
  }
};
</script>

<style scoped>
.el-dialog .el-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  line-height: 40px;
  margin: 30px;
}
.el-dialog .el-row .el-input {
  width: 400px;
  margin-left: 10px;
}
</style>