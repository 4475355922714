<template>
  <!-- 角色列表页面 -->
  <div class="body">
    <!-- 面包屑区域 -->
    <div class="ipSubmit_home" :style="{width:widthStyle[2] }">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>角色列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 卡片区域 -->
    <div class="card">
      <el-card class="box-card">
        <div class="add_roles">
          <el-button type="primary" @click="drawer = true">添加角色</el-button>
        </div>
        <!-- 添加角色弹窗问题 -->

        <el-drawer title="添加角色" :visible.sync="drawer" direction="rtl" size="70%" modal>
          <!-- 角色名称部分 -->
          <div class="input">
            <el-input placeholder="请输入角色名称" v-model="input_role"></el-input>
          </div>
          <!-- 树形控件 -->

          <el-tree :data="add_tree_data" accordion :props="tree_prop" show-checkbox ref="tree_add_role" node-key="wenidc_id"></el-tree>
          <!-- 添加角色表格 -->
          <!-- 添加按钮 -->
          <div class="add_button">
            <el-button type="primary" @click="add_roles_role">添加</el-button>
          </div>
        </el-drawer>
        <!-- 角色表格 -->
        <el-table :data="tableData" ref="tablelist" @expand-change="get_role_list_user" :row-key='getRowKeys'
          :expand-row-keys="expands" stripe style="width: 100%" v-loading="loading" element-loading-text="拼命加载中">
          <el-table-column width="100px" type="expand" label="展开"></el-table-column>
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="role_name" label="角色名"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div>
                <el-button type="primary" icon="el-icon-edit" size="mini" @click="role_edit(scope.row.id)"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
       <el-dialog  title="成员列表"
          :visible.sync="dialogVisible"
         width="70%" class="role_flex">
         <div v-if="total == 0">用户列表为空</div>
         <div v-else>
            <div v-for="(item, index) in userInfo" :key="index">
              <el-button type="info" plain>{{item.username}}</el-button>
            </div> 
          </div>
              <span slot="footer" class="dialog-footer">
                  <div class="pagination">
                   <Pagenation :size="30" :total="total" v-on:getPage="handleCurrentChange" />
                </div>
              </span>
              </el-dialog>
      <!-- 操作权限弹框 -->
      <el-dialog title="权限列表" :visible.sync="visible" width="70%" @close="close_dialog" :fullscreen="fullscreen">
        <div class="roles">
          <el-tree :data="data" :props="tree_prop" show-checkbox node-key="wenidc_id" accordion :default-checked-keys="default_active_key" ref="tree_role"></el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="visible = false">取 消</el-button>
          <el-button type="primary" @click="determine">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
//引入分页组件
import Pagenation from '../components/pagenation'
export default {
  components:{
    Pagenation
  },
  data() {
    return {
      // 添加角色按钮权限
      add_tree_data: [],
      // 树形角色的绑定以及属性
      add_tree_prop: {
        label: '',
        children: ''
      },
      tableData: [],
      userInfo:[],
      //   弹窗开关
      drawer: false,
      // 权限弹窗
      visible: false,
      // 角色名称
      input_role: '',
      // 权限数据
      data: [],
      // 树形控件的绑定以及属性
      tree_prop: {
        label: 'wenidc_action_name',
        children: 'children'
      },
      // 选中的id值
      default_active_key: [],
      // 用户id
      id: '',
      // 弹窗是否全屏
      fullscreen: false,
      loading: true,
      //当前页码
      //分页总条数
      total:1,
      expands: [],
      getRowKeys (row) {
        return row.id
      },
      pagenumber:1,
      row:[],
      expandedRows:[],

      //弹出框
      dialogVisible:false
    }
  },
  mounted() {
    if (document.body.clientWidth < 550) {
      this.fullscreen = true
    }
    // 获取数据
    this.get_role_list()
    // 获取添加角色的按钮需要的数据
    this.get_add_role()
  },
  watch:{
    //监控弹窗的关闭，如果关闭了，就把表格的展开层也关闭,并清空用户列表,清空页码
    dialogVisible(){
      if(this.dialogVisible == false){
        this.$refs.tablelist.toggleRowExpansion(this.row,false)
        this.userInfo = []
        this.pagenumber = 1
        //将默认页码重置为1
      }
    }
  },
  computed:{
    widthStyle(){
      return this.$store.state.widthStyle
    }
  },
  methods: {
    // 确定修改权限按钮
    determine() {
      // 首先获取当前所有选中项的Id
      const keys = [...this.$refs.tree_role.getCheckedKeys(), ...this.$refs.tree_role.getHalfCheckedKeys()]
      let arr = keys.join(',')
      this.$axios
        .post('/change_permissions', {
          id: this.id,
          check_arr: arr
        })
        .then(res => {
            this.visible = false
        })
    },
    // 获取新添角色所需要的数据
    get_add_role() {
      this.$axios('/roles_add').then(res => {
          this.add_tree_data = res.data
      })
    },
    // 编辑权限按钮
    role_edit(id) {
      this.id = id
      this.visible = true
      this.$axios
        .post('/roles', {
          id
        })
        .then(res => {
          this.data = res.data
          this.fetLeafKeys(this.data, this.default_active_key)
        })
    },
    // 添加角色按钮 tree_add_role
    add_roles_role() {
      const arr_keys = [...this.$refs.tree_add_role.getCheckedKeys(), ...this.$refs.tree_add_role.getHalfCheckedKeys()]
      console.log(this.$refs.tree_add_role.getCheckedKeys())
      if (this.input_role.trim() == '') {
        this.$message({
          type: 'error',
          message: '角色名称不能为空'
        })
      } else if (arr_keys.length == 0) {
        this.$message({
          type: 'error',
          message: '角色权限不能为空'
        })
       } else {
        // 发送请求
        this.$axios
          .post('/identity', {
            role_name: this.input_role,
            action_id: arr_keys.join(',')
          })
          .then(res => {
              this.drawer = false
              this.get_role_list()
          })
      }
    },
    // 关闭窗口 重置数据
    close_dialog() {
      this.default_active_key = []
    },
    // 通过一个函数来获取已经有的权限节点id
    fetLeafKeys(node, arr) {
      node.forEach(element => {
        element.children.forEach(item => {
          if (item.wenidc_check == 1) {
            return arr.push(item.wenidc_id)
          }
        })
      })
    },
    // 获取角色按钮
    get_role_list() {
      this.$axios('/role_list').then(res => {
        this.tableData = res.data
        this.loading = false
      })
    },
    //根据角色获取用户
   get_role_list_user(row, expandedRows){
      let that = this
      that.row = row
      that.expandedRows = expandedRows
      //只有展开的时候expandedRows是有值的,这时候才请求数据，防止数据重复请求
      if(expandedRows.length>0){
        that.dialogVisible = true;
         this.$axios.post('/rolelistuser',{
        role_id:row.id,
        pagenumber:this.pagenumber
       }).then(res => {
         this.userInfo = res.data
         this.total = res.count
       })
      }
       //设置表格只能同时展开一行
      if (expandedRows.length) {
        that.expands = []
        if (row) {
         that.expands.push(row.id)
             }
        } else {
          that.expands = []
      }
      
    },
    handleCurrentChange(val){
      this.pagenumber = val
      this.get_role_list_user(this.row,this.expandedRows)
    }
  }
}
</script>
<style scoped>
.card /deep/ .el-drawer__body {
      overflow: auto;
      padding-bottom:50px;
}
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.add_roles {
  margin: 10px 0;
}
.input {
  padding: 10px;
  margin-left: 50px;
}
.input > .el-input {
  width: 50%;
}
.role_flex {
  display: flex;
  flex-wrap: wrap;
  padding-bottom:50px;
}
.role_flex div{
  float:left;
}
.role_flex .el-button {
  margin: 10px;
}
.add_button {
  text-align: center;
  margin-top: 20px;
}
.el-tree {
  margin-left: 50px;
}
.dialog-footer{
  position: absolute;
  bottom: 20px;
  left: 0;
  width:100%;
  display:flex;
  justify-content: center;
  padding-left:70px;
}

</style>