import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Login.vue'
// import Home from '../components/home.vue'
// import IpSubmit from '../components/IPSubmit.vue'
// import welcome from '../components/welcome.vue'
// import regist from '../components/regist.vue'
import roleGl from '../view/role_list.vue'
// 引入用户列表
import userList from '../view/order_list.vue'
// 引入后台审核页面
import backstage from '../view/action_list.vue'
// 引入账号审核页面
import user_backstage from '../view/home_audit.vue'
// 后台审核
import admin_home_audit from '../view/admin_home_audit.vue'
// 后台IP统计
import IPAll from '../view/ip_all.vue'
//app分析
// import appManualAnalysis from '../view/app_ManualAnalysis.vue'
// 引入图标文件
import '../../public/font/iconfont.css'
// 引入对接页面
import Docking from '../view/Docking.vue'
// 引入版本控制页面‘
import Version from '../view/version.vue'
//价格管理
import priceControl from '../view/price_control.vue'
// 统计图页面
import echarsTj from '../view/echars_tj.vue'
// 用户反馈
import getopinion from '../view/get_opinion.vue'
// asn相关
import asn from '../view/asn.vue'
// asn ip 相关
import asn_ip from '../view/asn_ip.vue'
// 充值页面
import Recharge from '../view/Recharge.vue'
// 知识库审核
import homeZsk from '../view/home_zsk.vue'
// 发票审核
import invoice from '../view/invoice.vue'

//ip搜索
import IpSearch from '../view/IpSearch.vue'
Vue.use(Router)

//大数据图
import bigData from '../view/big_data.vue'

//添加通知信息
import newsPush from '../view/newsPush.vue'
//分析企业列表
import companyList from '../view/company_list.vue'
//案件类型管理
import caseControl from '../view/case_control.vue'
// 员工管理
import staffManage from '../view/staffManage.vue'
// 金刚区
import kingKong from '../view/kingKong.vue'
// 客服
import Chat from '../components/chat'
// 管理任务
import Manager from '../view/Manage_task.vue'
// 授权函审核
const confer = () =>
    import ('@/view/confer.vue')
const router = new Router({
    // mode:'history',
    routes: [{
            path: '/login',
            component: Login
        },
        {
            path: '/j_regist',
            component: resolve => require(['@/components/j_regist.vue'], resolve)
        },
        {
            path: '/regist_type',
            // component: registType
            component: resolve => require(['@/components/resgist_select.vue'], resolve)
        },
        {
            path: '/regist_trail',
            // component: registTrail
            component: resolve => require(['@/components/regsit_trail.vue'], resolve)
        },
        {
            path: '/q_regist',
            // component: QRegist
            component: resolve => require(['@/components/q_regist.vue'], resolve)
        },
        {
            path: '/',
            redirect: '/login'
        },

        {
            path: '/regist',
            component: resolve => require(['@/components/regist.vue'], resolve)
        },
        {
            path: '/big_data',
            component: bigData
        },
        {
            path: '/home',
            // component: Home,
            component: resolve => require(['../components/home.vue'], resolve),

            children: [
                // 统计图
                {
                    path: '/echars_tj',
                    component: echarsTj
                },                
                {
                    path: '/reportList',
                    component: resolve => require(['../view/reportList.vue'], resolve),
                },
                {
                    path: '/payList',
                    component: resolve => require(['../view/payList.vue'], resolve),
                },
                {
                    path: '/adver_space',
                    component: resolve => require(['../view/adverSpace.vue'], resolve),
                },
                {
                    path: '/get_opinion',
                    component: getopinion
                },
                {
                    path: '/asn',
                    component: asn
                },
                {
                    path: '/asn_ip',
                    component: asn_ip
                },
                {
                    path: '/home_ip_submits',
                    //  import('../components/welcome.vue')
                    component: resolve => require(['@/components/IPSubmit.vue'], resolve)
                },
                // ip提交
                {
                    path: '/home_ip_submits',
                    component: resolve => require(['@/components/IPSubmit.vue'], resolve)

                    // component:IpSubmit
                },
                // {
                //     path: '/app_ManualAnalysis',
                //     component: appManualAnalysis
                // },
                // ip数量提交
                {
                    path: '/ip_all',
                    component: IPAll
                },
                // 角色列表
                {
                    path: '/role_list',
                    component: roleGl
                },
                // 用户列表
                {
                    path: '/order_list',
                    component: userList
                },
                // 权限列表
                {
                    path: '/action_list',
                    component: backstage
                },
                // 账号审核
                {
                    path: '/home_audit',
                    component: user_backstage
                },
                // 后台审核
                {
                    path: '/admin_home_audit',

                    component: admin_home_audit
                },
                // 对接详情
                {
                    path: '/get_duijie_details',

                    component: Docking
                },
                // 版本控制
                {
                    path: '/version',

                    component: Version
                },


                // 充值页面
                {
                    path: '/recharge',
                    component: Recharge
                },
                // 知识库审核
                {
                    path: '/home_zsk',
                    component: homeZsk
                },
                {
                    path: '/price_control',
                    component: priceControl
                },
                // 发票审核  invoice
                {
                    path: '/invoice_audit',
                    component: invoice
                },
                // IpSearch ip搜索
                {
                    path: '/Ip_lookup',
                    component: IpSearch
                },
                // newsPush 通知信息添加
                {
                    path: '/news_push',
                    component: newsPush
                },
                // 员工管理
                {
                    path: '/staff_manage',
                    component: staffManage
                },
                // 分析公司列表
                {
                    path: '/company_list',
                    component: companyList
                },
                // 案件类型
                {
                    path: '/case_control',
                    component: caseControl
                },
                // 授权函
                {
                    path: '/confer',
                    component: confer
                },
                // 金刚区
                {
                    path: '/king_kong',
                    component: kingKong
                },
                // 客服
                {
                    path: '/chat',
                    component: Chat
                },
                // 管理任务
                {
                    path: '/manage_task',
                    component: Manager
                }
            ]
        }
    ]
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    if (to.path == '/login') return next()
    if (to.path == '/regist' || to.path == '/j_regist' || to.path == '/q_regist' || to.path == '/regist_type') return next()
    const token = window.localStorage.getItem('token')
    if (!token || token == undefined) return next('/login')
    next()
})

export default router