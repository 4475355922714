<template>
  <div class="body">
    <!-- 面包屑区域 -->
    <div class="ipSubmit_home" :style="{width:widthStyle[0]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>对接信息</el-breadcrumb-item>
        <el-breadcrumb-item>对接信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 卡片区域 -->
    <div class="card">
      <el-card class="box-card">
        <!-- 角色表格 -->
        <el-table :data=" tableData" style="width: 100%" ref="tables" v-loading="loading" element-loading-text="拼命加载中">

          <el-table-column label="警方名称" prop="j_userinfo.company" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.j_userinfo.company }}---{{ scope.row.j_userinfo.policename }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="对接企业" prop="q_userinfo.address" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.q_userinfo.address }}---{{ scope.row.q_userinfo.contacts }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="对接时间" prop="createAt" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.createAt | timer }}
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center">
            <template slot-scope="scope">
              <div>
                <el-button size="mini" type="primary " @click="row( scope.row,scope.row.id)">对接详情</el-button>
              </div>
            </template>
          </el-table-column>

        </el-table>
        <!-- 弹窗 -->
        <el-dialog title="对接详情" :visible.sync="dialogVisible" width="70%">
          <div>
            <el-steps :active="list.status" finish-status="success">
              <el-step :title="p.title" v-for="(p,i) of steps" :key="i"></el-step>
            </el-steps>
          </div>
          <div class="main">
            <div v-for="(p,i) in rows" :key="i">
              <div class="public_class" v-if="list.j_id==p.comment_id">
                <div class="style">警方--{{list.j_userinfo.policename}} :</div>
                <div class="margin">{{p.content}}</div>
                <div class="image" v-if="p.comment_img.length!=''">
                  <el-image v-for="(item,index) in p.comment_img" style="width: 150px; height: 150px" :src="BaseUrl + item" :preview-src-list="imgUrl"
                    :key="index" :z-index="9999" @click="setImg(p.comment_img)"></el-image>
                </div>
                <div class="image" v-else>
                  <el-image v-for="(item,index) in p.ec_document" style="width: 100px; height: 100px" :src="BaseUrl + item" :preview-src-list="imgUrl"
                    :key="index" :z-index="9999" @click="setImg(p.ec_document)"></el-image>
                </div>
                <div class="time">
                  {{p.createAt | timer}}
                </div>
              </div>
              <div class="public_class public_right" v-else>

                <div class="dad">
                  <div class="style">企业--{{list.q_userinfo.enterpriseName}} :</div>
                  <div class="margin">{{p.content}}</div>
                  <div class="image" v-show="p.comment_img.length!=''">

                    <el-image style="width: 100px; height: 100px" v-for="(item,index) in p.comment_img" :src="BaseUrl + item" :preview-src-list="imgUrl"
                      :key="index" :z-index='20000' @click="setImg(p.comment_img)"></el-image>
                  </div>
                  <div class="time">
                    {{p.createAt | timer}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 分页部分 -->
        <div class="paginations" :style="{width:widthStyle[1]}">
          <Pagenation :size="30" :total="total" v-on:getPage="handleCurrentChange" />
        </div>
      </el-card>
    </div>

  </div>
</template>
<style scoped>
.el-dialog__header{
  text-align: center !important;
}
.dialog-footer{
  justify-content: space-evenly !important;
}
  .style {
    font-size: 12px;
    font-weight: 700;
  }

  .ipSubmit_home {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .paginations {
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 93.6%;
    background: #fff;
    margin-left: -20px;
  }

  .el-table__expand-column .cell {
    display: none;
  }

  .public_class {
    margin: 20px 0;
    border: 1px dashed #bfbfbf;
    padding: 10px;
  }

  .public_right {
    text-align: right;
  }

  .image>img {
    margin: 10px 0;
    width: 50px;
    height: 50px;
  }

  .time {
    color: #999;
    font-size: 12px;
  }

  .margin {
    margin: 10px 0;
  }
</style>
<script>
  import Pagenation from '../components/pagenation'
  export default {
    components: {
      Pagenation
    },
    data() {
      return {
        tableData: [],
        total: 1,
        pagenumber: 1,
        rows: [],
        dialogVisible: false,
        list: "",
        //   选择框的选择
        loading: true,
        BaseUrl:this.$store.state.BaseUrl,
        imgUrl: [],
        steps: [
          {
            id: 1,
            title: "等待企业接受"
          },
          {
            id: 2,
            title: "等待警方提交取证文书"
          },
          {
            id: 3,
            title: "需要企业提交IP归属地"
          },
          {
            id: 4,
            title: "对接中"
          },
          {
            id: 5,
            title: "完结"
          },

        ]
      };
    },
    mounted() {
      this.get_ismnu(1);
    },
    computed: {
      widthStyle() {
        return this.$store.state.widthStyle
      }
    },
    methods: {
      row(list, id) {
        this.$axios.post('/get_djhuifu_details', {
          duijie_id: id
        }).then(res => {
          this.rows = res.data
        })
        this.list = list
        this.dialogVisible = true
      },
      handleCurrentChange(val) {
        this.pagenumber = val;
        this.get_ismnu()
      },
      get_ismnu() {
        this.$axios("/get_duijie_details?pagenumber=" + this.pagenumber)
          .then(result => {
            this.loading = false
            this.total = result.count
            this.tableData = result.data
          })
          .catch((err) => {
            console.log(err);
          });
      },
      //设置预览大图的数据，必须是数组
      setImg(img) {
        //先清空数组
        this.imgUrl = []
        img.forEach(item => {
          this.imgUrl.push(this.BaseUrl + item)
        })
      }
    },
  };
</script>