<template>
  <div id="app">
    <keep-alive include="register">
      <router-view/>
    </keep-alive>
  </div>
</template>
<script>
document.title = "云追查后台管理系统";
export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  }
};
</script>
<style>
</style>
