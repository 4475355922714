<template>
  <div>
    <!-- 面包屑区域  fullscreen-->
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>ASN</el-breadcrumb-item>
        <el-breadcrumb-item>相关IP</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 卡片区域 -->
    <div class="card">
      <el-card shadow="always">
        <!-- 搜索框部分 -->
        <div class="search_ip flex">
          <el-input
            placeholder="请输入内容"
            v-model="asn_ip"
            clearable
            @keyup.enter.native="search()"
          ></el-input>
          <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          <el-button type="danger" plain @click="toggleSelection">批量删除</el-button>
        </div>
        <div class="searchTable" v-show="serachTable.length!=0">
          <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="serachTable"
            stripe
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column prop="id" label="ID" type="selection"></el-table-column>
            <el-table-column prop="range_start" label="IP段起"></el-table-column>
            <el-table-column prop="range_end" label="IP段结尾"></el-table-column>
            <el-table-column prop="AS_number" label="ASN号"></el-table-column>
            <el-table-column prop="country_code" label="国家标识"></el-table-column>
            <el-table-column prop="createdAt" label="日期"></el-table-column>
            <el-table-column prop="id" label="操作">
              <template slot-scope="scope">
                <div class="solt_button">
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                    @click="delete_ip(scope.row.id)"
                  ></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 表格区域 -->
        <div class="search_table">
          <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="tableData"
            stripe
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column prop="id" label="ID" type="selection"></el-table-column>
            <el-table-column prop="range_start" label="IP段起"></el-table-column>
            <el-table-column prop="range_end" label="IP段结尾"></el-table-column>
            <el-table-column prop="AS_number" label="ASN号"></el-table-column>
            <el-table-column prop="country_code" label="国家标识"></el-table-column>
            <el-table-column prop="createdAt" label="日期"></el-table-column>
            <el-table-column prop="id" label="操作">
              <template slot-scope="scope">
                <div class="solt_button">
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                    @click="delete_ip(scope.row.id)"
                  ></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页部分 -->
        <div class="pagination">
          <Pagenation :total="total" :size="30" v-on:getPage="handleCurrentChange"/>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import unixTimeToDateTime from "../filter/data";
import Pagenation from "../components/pagenation";
export default {
  components: {
    Pagenation
  },
  data() {
    return {
      //默认选择框的值
      value: "1",
      //搜索的ip
      asn_ip: "",
      dialogVisible: false,
      // 登录用户的类型
      type: "",
      // 搜索到的ip表格
      serachTable: [],
      // 抽屉开关
      drawer: false,

      // 弹窗
      sync: false,

      // 页数
      pagenumber: 1,
      // 代理类型
      info_value: 1,
      // 倒计时请求数据
      info: [],
      //数据表格
      tableData: [],

      //   选择框的选择
      loading: true,

      // 所有页数
      total: 0,
      // 定时器
      settimer: "",
      // 代理类型
      info_usage: [],
      // 数据
      select_list: [],
      // 弹窗是否为全屏
      fullscreen: true,
      user_id: ""
    };
  },

  // 拿到用户登录的身份
  mounted() {
    // 拿到数据
    let users_type = JSON.parse(window.sessionStorage.getItem("info")).type;
    this.type = users_type;
    this.user_id = JSON.parse(window.sessionStorage.getItem("info")).user_id;
    this.get_ip_list();
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.select_list = [];
      val.forEach(row => {
        this.select_list.push(row.id);
      });
    },
    // 删除按钮
    toggleSelection() {
      if (this.select_list.length != 0) {
        this.$confirm("此操作将永久删除该IP, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios
            .post("/del_asn_arr_ip", {
              asn_ip_id: this.select_list.join(",")
            })
            .then(res => {
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: res.msg
                });
                this.get_ip_list();
              } else {
                this.$message({
                  type: "error",
                  message: res.msg
                });
              }
            });
        });
      } else {
        this.$message("请选择您要删除的IP");
      }
    },
    // 分页部分
    handleCurrentChange(val) {
      this.pagenumber = val;
      this.get_ip_list();
    },

    // 拿到表格数据
    get_ip_list() {
      this.$axios
        .post("/get_asn_ip", {
          user_id: this.user_id,
          pagenumber: this.pagenumber,
          ip: ""
        })
        .then(res => {
          this.loading = false;
          res.data.forEach(item => {
            item.createdAt = unixTimeToDateTime(item.createdAt);
          });
          this.tableData = res.data;
          this.total = res.count;
        });
    },

    // 搜索自己提交的IP
    search() {
      let ip = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
      if (this.asn_ip.trim() == "") {
        this.$message({
          type: "error",
          message: "输入框不能为空"
        });
      } else if (!ip.test(this.asn_ip)) {
        this.$message({
          type: "error",
          message: "IP格式错误"
        });
      } else {
        this.$axios
          .post("/get_asn_ip", {
            ip: this.asn_ip,
            user_id: this.user_id,
            pagenumber: 1
          })
          .then(res => {
            res.data.forEach(item => {
              item.creatTime = unixTimeToDateTime(item.creatTime);
            });
          });
      }
    },
    // 删除IP接口
    delete_ip(ip_id) {
      this.$confirm("此操作将永久删除该IP, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$axios
            .post("/del_asn", {
              asn_id: ip_id
            })
            .then(res => {
              this.get_ip_list();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  }
};
</script>
<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.search_ip {
  margin-top: 20px;
}
.search_ip /deep/ .el-textarea {
  width: 50%;
  vertical-align: middle;
}
.search_ip /deep/.el-button {
  padding: 0 !important;
}
.el-input {
  text-align: left;
  width: 40%;
}
.el-button {
  margin-left: 10px;
  width: 85px;
}
.pagination {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.search_table {
  margin: 40px 0;
}
.add_ip {
  margin-top: 20px;
}
.select {
  margin-top: 100px;
  text-align: center;
}
.input_ip .el-input {
  margin: 10px 0;
  width: 300px;
}
.flex {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.flex > .el-radio-group {
  margin: 5px 2px;
}
.input_ips .el-input {
  margin: 10px 0;
  width: 143px;
}
.input_ips span,
.input_ip span {
  margin: 0 5px;
}
.solt_button > .el-button {
  border-radius: 5%;
}
.upload_excel {
  margin: 20px;
  text-align: center;
}
.upload_excel .el-button {
  width: 100px;
}
.file_style {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  color: #666;
}
.flex_Dad {
  display: flex;
  border: 1;
  justify-content: center;
}

.flex_Dad > div:last-child {
  margin-left: 10px;
}
.el-table {
  margin-top: 10px;
}
.test {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.radios {
  position: relative;
  margin: 15px;
}
.radios > span {
  position: absolute;
  top: 0;
  left: 20%;
}

@media screen and (max-width: 1200px) {
  .flex_Dad {
    display: flex;
    border: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 550px) {
  .flex_Dad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex_Dad div {
    width: 90%;
  }
  .flex_Dad div img {
    width: 100%;
  }
  .input_ip .el-input {
    margin: 10px 0;
    width: 100%;
  }
  .flex {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .flex > .el-radio {
    margin: 10px;
  }
  .input_ips .el-input {
    margin: 10px 0;
    width: 100%;
  }
  .input_ips span {
    margin: 0 5px;
  }
}
</style>