<template>
  <div class="body">
    <!-- 面包屑区域 -->
    <div class="ipSubmit_home" :style="{width:widthStyle[0]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>APP管理</el-breadcrumb-item>
        <el-breadcrumb-item>知识库审核</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card">
      <el-card class="box-card">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="已审核" name="first" value="1">
              <homeZskMain :data="data"/>
         </el-tab-pane>
          <el-tab-pane label="未审核" name="second" value="0">
            <Main :data="data" v-on:audit="audit" />
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <Pagenation :size="5" :total="total" v-on:getPage="handleCurrentChange" />
    </div>
  </div>
</template>
<script>
import Main from "../components/main.vue";
import homeZskMain from '../components/home_zsk_main.vue'
import Pagenation from '../components/pagenation'
export default {
  components: {
    Main,
    homeZskMain,
    Pagenation
  },
  data() {
    return {
      activeName: "second",
      data: [],
      check:0,
      pagenumber:1,
      total:0,
    };
  },
  computed:{
    widthStyle(){
      return this.$store.state.widthStyle
    }
  },
  mounted() {
    this.get_home_zsk();
  },
  methods: {
    //   审核的
    audit(state, id,user_id) {
      this.$axios({
        url: "/zsk_check",
        data: {
          id,
          is_check: state,
          user_id
        },
        method: "POST",
      }).then(res => {
            this.get_home_zsk()
      });
    },
    //   发送请求
    get_home_zsk() {
      this.$axios.
      post("/get_home_zsk",{
        is_check:this.check,
        pagenumber:this.pagenumber
      }).then( res => {
        // console.log(res)
        this.data = res.data;
        this.total = res.count
      });
    },
    handleClick(event) {
      this.check=event.$attrs.value
      this.get_home_zsk()
    },
    handleCurrentChange(val) {
      this.pagenumber = val; 
      this.get_home_zsk()
    }
  },
};
</script>

<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

</style>
