<template>
  <div>
    <div class="ipSubmit_home" :style="{ width: widthStyle[0] }">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>任务管理</el-breadcrumb-item>
        <el-breadcrumb-item>管理任务</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div slot="header" class="clearfix" style="margin:5% 0 1% 0">
      <span v-for="itemin in admin" :key="itemin.id +'-admin'">
        <el-button
          :type=" itemin.id==0 ? 'info': itemin.id==2 ? 'warning' : itemin.id==4 ? 'success' : 'primary'"
          plain
          size="medium"
          style="margin:0 5px;"
          @click="getList(itemin.id)"
        >{{itemin.name}}</el-button>
      </span>
    </div>
    <!-- 卡片表格 -->
    <el-card class="box-card">
      <div slot="header" class="clearfix" v-if="state==2 || state==3 || state==4 || state==5 || state==7">
        <span v-for="item in roleList" :key="item.state">
          <el-button
            :type="item.state==8 ? 'danger' : item.state==7 ? 'success' : state==5 ? 'primary' : 'warning'"
            plain
            size="small"
            style="margin:0 5px;"
            @click="comAna(item.state)"
          >{{item.name}}</el-button>
        </span>
      </div>
      <!-- 数据表格 -->
      <div>
        <template>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="id" label="任务ID" 
             :default-sort = "{prop: 'id', order: 'descending'}"
            sortable width="90"></el-table-column>
            <el-table-column prop="order_number" label="订单号" width="150"></el-table-column>
            <el-table-column prop="user_name" label="用户名" width="130"></el-table-column>
            <el-table-column prop="task_type" label="订单类型" width="180"></el-table-column>
            <el-table-column prop="remarks" label="备注信息" width="150"></el-table-column>
            <el-table-column prop="analysis_unit" label="分析单位">
              <template slot-scope="scope">
                <div>{{scope.row.analysis_unit.company}}</div>
                <div>{{scope.row.analysis_unit.analyst}}</div>
              </template>
            </el-table-column>
            <el-table-column label="总额" width="100">
              <template slot-scope="scope">
                <div>
                  <el-link type="danger" :underline="false">{{scope.row.total_price}}</el-link>
                </div>
                <div>
                  <span>{{scope.row.price}}</span>*
                  <span>{{scope.row.analysis_quantity}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-show="state==0" prop="extended_days" width="130" label="延长分析时间(天)">
              <template slot-scope="scope">
                <div v-show="scope.row.extended_days==0">
                  <el-button size="mini" type="primary" round @click="delay(scope.row.id)">延长天数</el-button>
                </div>
                <div v-show="scope.row.extended_days!=0">
                  <el-tag type="success">延长{{scope.row.extended_days}}天</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="任务时间" width="180">
              <template slot-scope="scope">
                <div v-show="scope.row.state==0">{{scope.row.create_time}}</div>
                <div v-show="scope.row.state==1">{{scope.row.evaluation_time}}</div>
                <div v-show="scope.row.state==2">{{scope.row.specified_completion_time}}</div>
                <div v-show="scope.row.state==3">{{scope.row.distribution_company_time}}</div>
                <div v-show="scope.row.state==4">{{scope.row.distribution_analyst_time}}</div>
                <div v-show="scope.row.state==5">{{scope.row.analysis_time}}</div>
                <div
                  v-show="scope.row.state==6 || scope.row.state==7 || scope.row.state==8"
                >{{scope.row.audit_time}}</div>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">
                <div>
                  <el-tag v-show="scope.row.state==0" type="info">待评估</el-tag>
                  <el-tag v-show="scope.row.state==1" type="primary">待用户确认</el-tag>
                  <el-tag v-show="scope.row.state==2 || scope.row.state==3" type="warning">待分配</el-tag>
                  <el-tag v-show="scope.row.state==4" type="success">待分析</el-tag>
                  <el-tag v-show="scope.row.state==5" type="primary">待审核</el-tag>
                  <el-tag v-show="scope.row.state==6 || scope.row.state==7" type="success">审核通过</el-tag>
                  <el-tag v-show="scope.row.state==8" type="danger">审核不通过</el-tag>
                  <span v-show="scope.row.state==8" style="color:red">原因：{{reason}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-show="state!=6|| state !=7"  label="操作">
              <!-- 分配 -->
              <template slot-scope="scope">
                <el-button
                  v-show="scope.row.state==0"
                  size="mini"
                  type="primary"
                  @click="openFinishiTime(scope.row)"
                >设置完成时间</el-button>
                <el-button
                  v-show="scope.row.state==2 || scope.row.state==3"
                  size="mini"
                  type="primary"
                  @click="getDistribute(scope.row)"
                >{{ scope.row.state==2 && scope.row.analysis_unit.company_id==0 ? '分配分析公司' : scope.row.state==3 && scope.row.analysis_unit.analyst_id==0 ? '分配分析员' : '分配'}}</el-button>
                <el-button
                  v-show="scope.row.state==4"
                  size="mini"
                  type="primary"
                  @click="assian(scope.row.id)"
                >分析</el-button>
                  <el-tag v-show=" scope.row.state==6" type="success">生成报告中</el-tag>
                  <el-tag v-show=" scope.row.state==7" type="success">报告已生成</el-tag>
                <el-button
                  v-if="scope.row.state==5 || scope.row.state==8"
                  size="mini"
                  type="primary"
                  @click="scope.row.state==5 ? check(scope.row.id) : assian(scope.row.id)"
                >{{scope.row.state==5 ? '审核' : '重新提交'}}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
      <div>
        <Pagenation :size="10" :total="count" v-on:getPage="handleCurrentChange"></Pagenation>
      </div>
    </el-card>
    <!-- 设置完成时间弹窗 -->
    <div>
      <el-dialog
        title="设置完成时间"
        :visible.sync="completeTimeDialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <div class="dialogFlex">
          <span>服务次数：</span>
          <el-input v-model="box_number"></el-input>
        </div>
        <div class="dialogFlex">
          <span>服务费用：</span>
          <el-input v-model="box_money"></el-input>
        </div>
        <div class="dialogFlex">
          <div class="block">
            <span class="demonstration">任务完成时间：</span>
            <el-date-picker v-model="complete_time" type="date" placeholder="选择日期"></el-date-picker>
          </div>
        </div>
        <div style="margin: 20px 0 0 100px;">
          <el-button @click="completeTimeDialogVisible = false">取 消</el-button>
          <el-button @click="completeTimeSubmit()" type="primary">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 延长天数弹窗提示 -->
    <div>
      <el-dialog title="延长天数" :visible.sync="delayVisible" width="30%">
        <span>请选择延长的天数：</span>
        <el-select style="width: 80%; margin-top: 2%;" v-model="value" placeholder="请选择">
          <el-option v-for="item in dayOptions" :key="item.value" :value="item.value"></el-option>
        </el-select>
        <div style="margin: 20px 2% 0 30%;">
          <el-button @click="delayVisible = false">取 消</el-button>
          <el-button @click="delayEnter()" type="primary">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 任务分配弹窗 -->
    <div>
      <!-- 分配公司 -->
      <el-dialog :visible.sync="distriDialogVisible" title="分配分析公司" center width="800px">
        <el-table style="width:90%;margin:0 auto;" :data="q_info">
          <el-table-column width="300px" prop="enterpriseName" label="公司名称"></el-table-column>
          <el-table-column prop="meanwhile" align="center" label="当前任务量/最大任务量">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.underway">({{scope.row.underway}}/{{scope.row.meanwhile}})</span>
                <span v-else>(0/{{scope.row.meanwhile}})</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                :disabled="scope.row.q_id == q_id"
                @click="distribution(scope.row)"
              >分配</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <!-- 分配分析员 -->
      <el-dialog :visible.sync="analysterDialogVisible" title="分配分析员" center width="800px">
        <el-table style="width:90%;margin:0 auto;" :data="p_info">
          <el-table-column prop="username" label="分析员"></el-table-column>
          <el-table-column prop="count" label="任务数量"></el-table-column>
          <el-table-column align="right">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="anaEnter(scope.row)">分配</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <!-- 任务分析弹窗 -->
    <div>
      <el-dialog
        :visible.sync="assaDialogVisible"
        title="任务分析"
        center
        width="1000px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <!-- 上传文件 -->
        <div style="text-align:center;">
          <el-upload
            class="upload-demo"
            ref="upload"
            drag
            :auto-upload="false"
            :headers="headers"
            :data="assiaData"
            :file-list="fileList"
            :action="base"
            :accept="accept"
            :on-remove="handleRemove"
            :on-success="handleArtisanSuccess"
            :multiple="false"
            :limit="1"
            :show-file-list="true"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">可接受的文件格式为：.xls，.xlsx，.pdf，.xmind，.doc，.docx</div>
          </el-upload>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
          style="margin: 20px 0 0 0; bottom: 10px; justify-content:space-around;"
        >
          <el-button @click="assaDialogVisible = false">取 消</el-button>
          <el-button @click="anaSubmit" type="primary">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 审核弹窗 -->
    <div>
      <el-dialog :visible.sync="checkDialogVisible" title="任务审核" center width="800px">
        <div class="file">
          <ul>
            <li v-for="item in fileDownList" :key="item.id">
              <p>已接收到文件,可下载</p>
              <el-link style="cursor:pointer;" type="primary" @click="downLoad(item)">下载</el-link>
            </li>
          </ul>
        </div>
        <el-row class="audit_check">
          审核结果:
          <el-radio v-model="audit" label="1" border>通过</el-radio>
          <el-radio v-model="audit" label="2" border>不通过</el-radio>
        </el-row>
        <el-row class="notaudit" v-show="audit == 2">
          原因：
          <el-input v-model="reason" placeholder="输入不通过的原因"></el-input>
        </el-row>

        <span slot="footer" class="dialog-footer" style="justify-content:space-around">
          <el-button @click="checkDialogVisible = false">取 消</el-button>
          <el-button @click="enterCheck()" type="primary">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagenation from "../components/pagenation";
import moment from "moment";
export default {
  data() {
    return {
      // 管理员可选择的部分
      admin: [
        { id: 10, name: "全部" },
        { id: 0, name: "任务时间" },
        { id: 2, name: "任务分配" },
        { id: 4, name: "任务分析" },
        { id: 5, name: "任务审核" }
      ],
      adminId: "", //管理员Id：999：超级管理员，1003：人工分析管理员
      // 身份Id 分析公司：1001，分析员：1002，审核员：14，人工分析管理员：1003
      roleId: "",
      state: "",
      // 头部标签显示
      cardList: [],
      comType: [
        //分析公司
        { state: 2, name: "分配分析公司" },
        { state: 3, name: "分配分析员" }
      ],
      // assayer: [
      //   //分析员
      //   { id: 1, name: "未分析" },
      //   { id: 2, name: "待审核" },
      //   { id: 3, name: "已审核" }
      // ],
      checker: [
        //审核员
        { state: 5, name: "未审核的任务" },
        { state: 7, name: "已审核的任务" },
        { state: 8, name: "审核失败的任务" }
      ],
      // 所有列表数据
      tableData: [{ address: 1 }],
      // 完成时间弹窗
      completeTimeDialogVisible: false,
      taskId: "",
      box_number: "",
      box_money: "",
      complete_time: "",
      // 分配分析公司弹窗
      distriDialogVisible: false,
      q_info: [],
      distriId: "",
      q_id: "",
      // 分配分析员弹窗
      p_id: "",
      analysterDialogVisible: false,
      p_info: [],
      count: null,
      // 任务分析弹窗
      assaDialogVisible: false,
      assiaId: "",
      assiaData: {},
      base: this.$store.state.BaseUrl + "admin/analysis_task",
      accept: ".xls, .xlsx, .pdf, .xmind, .doc, .docx",
      fileList: [],
      // 延长天数弹窗
      delayVisible: false,
      // 延长天数判断
      dayOptions: [
        {
          value: "3"
        },
        {
          value: "5"
        },
        {
          value: "7"
        }
      ],
      // 延长天数选择值
      value: "",
      // 任务详情弹窗
      details: false,
      detailTitle: "",
      // 任务审核弹窗
      checkDialogVisible: false,
      audit: "1",
      reason: "",
      fileDownList:[],
      fileLength:null,
    };
  },
  mounted() {
    this.getList(10);
    // this.getAll();
  },
  components: {
    Pagenation
  },
  computed: {
    headers() {
      return {
        Authorization: window.localStorage.getItem("token"),
        version: "v3"
      };
    },
    widthStyle() {
      return this.$store.state.widthStyle;
    },
    // 头部标签切换
    roleList() {
      if (this.state == 2) {
        return this.comType;
      } else if (this.state == 4) {
        return this.assayer;
      } else if (this.state == 5) {
        return this.checker;
      } else if (this.state == 10) {
        return this.cardList;
      }
    }
  },
  methods: {
    // 获取全部数据
    getList(id) {
      this.state = id;
      if (id == 10) {
        let allState = "";
        this.cardList = false;
        this.getAll(0, allState);
      } else {
        this.getAll(0, id);
      }
    },
    getAll(page, state) {
      if (state == 10) {
        var trueState = "";
      } else {
        var trueState = state;
      }
      this.$axios
        .post("/analysis_task_list", { page: page, key_state: trueState })
        .then(res => {
          this.tableData = res.data.list;
          this.count = res.data.count;
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
          this.box_number = "";
          this.box_money = "";
          this.complete_time = "";
        })
        .catch(_ => {});
    },
    // 分页
    handleCurrentChange(val) {
      // this.pagenumber = val;
      this.getAll(val, this.state);
    },
    // 卡片导航栏
    comAna(id) {
      this.getAll(0, id);
    },
    // 延长天数
    delay(id) {
      this.delayId = id;
      this.delayVisible = true;
    },
    delayEnter() {
      this.$axios
        .post("/extended_days", { id: this.delayId, extended_days: this.value })
        .then(res => {
          if (res.code == 200) {
            this.delayVisible = false;
            this.getAll(0, this.state);
          }
        });
    },
    // 审核
    check(id) {
      this.checkId = id;
      this.checkDialogVisible = true;
      this.$axios.post("/get_task_detail", { id: id }).then(res => {
        this.fileDownList=res.data
        this.fileLength=res.data.task.length
      });
    },
    // 下载
    downLoad(item){
      window.open(item.analysis_file_path)
    },
    // 提交审核
    enterCheck() {
      if (this.audit == 1) {
        var data = {
          id: this.checkId,
          auditing_type: 1
        };
      } else if (this.audit == 2) {
        if (this.reason == "") {
          this.$message({
            message: "审核不通过的原因不能为空！",
            type: "error"
          });
        } else {
          var data = {
            id: this.checkId,
            auditing_type: 2,
            failure_reason: this.reason
          };
        }
      }
      this.$axios.post("/auditing_task", data).then(res => {
        if (res.code == 200) {
          this.checkDialogVisible = false;
          this.getAll(0, this.state);
        }
      });
    },
    // 分析
    assian(ids) {
      this.assiaId = ids;
      let data = { id: ids };
      this.assiaData = { ...data };
      this.assaDialogVisible = true;
    },
    //   上传文件成功接口
    handleArtisanSuccess(res) {
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.assaDialogVisible = false;
        this.getAll(0, this.state);
        this.$refs.upload.clearFiles();
      } else {
        this.$message({
          type: "error",
          message: res.msg
        });
        this.$refs.upload.clearFiles();
      }
    },
    anaSubmit(){
      this.$refs.upload.submit();
      this.assaDialogVisible = false
    },
    handleRemove(file, fileList) {
      this.$refs.upload.clearFiles();
      // console.log(file);
      // console.log(fileList);
    },
    // 分配公司
    getDistribute(item) {
      // console.log(item);
      this.q_id = item.analysis_unit.company_id;
      this.p_id = item.analysis_unit.analyst_id;
      this.distriId = item.id;
      if (item.state == 2 && this.q_id == 0) {
        this.distriDialogVisible = true;
        this.$axios
          .post("/artificial_enterprise_list", { page: 0 })
          .then(res => {
            this.q_info = res.data;
          });
      } else if (item.state == 3 && this.p_id == 0) {
        this.analysterDialogVisible = true;
        this.getAnaly();
      }
    },
    // 提交分配公司任务
    distribution(item) {
      this.$confirm("确定分配给" + item.enterpriseName + "吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$axios
            .post("/distribution_to_company", {
              id: this.distriId,
              qid: item.q_id
            })
            .then(res => {
              this.distriDialogVisible = false;
              this.getAll(0, this.state);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消分配"
          });
        });
    },
    // 获取分析员列表
    getAnaly(val) {
      this.$axios.post("/analyst_list", { page: 0 }).then(res => {
        this.p_info = res.data;
        this.pcount = res.count;
      });
    },
    // 提交分配分析员
    anaEnter(row) {
      this.$confirm("确定分配给" + row.username + "吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$axios
            .post("/distribution_analyst", {
              id: this.distriId,
              analyst_id: row.user_id
            })
            .then(res => {
              this.analysterDialogVisible = false;
              this.getAll(0, 3);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消分配"
          });
        });
    },
    // 设置完成时间对话框
    openFinishiTime(row) {
      this.taskId = row.id;
      this.box_number = row.analysis_quantity;
      this.box_money = row.total_price;
      this.completeTimeDialogVisible = true;
    },
    // 提交完成时间
    completeTimeSubmit() {
      const data = this.complete_time.getTime();
      const comTime = data.toString().substr(0, 10);
      // 当前时间的时间戳
      const time = Date.parse(new Date());
      const nowTime = time.toString().substr(0, 10);
      let daaa = moment(Number(nowTime)).format("YYYY-MM-DD");
      // console.log(data);
      if (
        this.complete_time == "" ||
        this.box_number == "" ||
        this.box_money == ""
      ) {
        this.$message({
          type: "error",
          message: "请填写完整！"
        });
      } else if (comTime < nowTime) {
        this.$message({
          message: "不能小于当前时间，请选择正确的时间！",
          type: "error",
          duration: 1500,
          offset: 450
        });
      } else {
        this.$axios
          .post("/set_completion_time_and_price", {
            id: this.taskId,
            number: this.box_number,
            price: this.box_money,
            specified_completion_time: comTime
          })
          .then(res => {
            // console.log(res);
            this.box_number = "";
            this.box_money = "";
            this.complete_time = "";
            this.completeTimeDialogVisible = false;
            this.getAll(0, 0);
          });
      }
    }
  }
};
</script>
<style scoped>
.box-card {
  margin-top: 25px;
}

/* 审核 */
.notaudit {
  width: 95%;
  display: flex;
  flex-direction: row;
  line-height: 40px;
  margin-bottom: 20px;
}
.notaudit .el-input {
  margin-left: 48px;
  width: 80%;
}
.file > ul > li {
  display: flex;
  justify-content: space-evenly;
  margin: 40px 0 40px -70px;
  font-size: larger;
}
.audit_check {
  width: 90%;
  display: flex;
  margin-bottom: 20px;
  line-height: 40px;
}
.audit_check .el-radio {
  margin-left: 35px;
}
.dialogFlex {
  display: flex;
  margin: 3% 0;
}
.dialogFlex > span {
  width: 30%;
}
.el-dialog__body{
  padding-left: 12% !important;
}
.el-link {
  cursor: text;
}
</style>