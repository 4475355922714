<template>
  <div>
    <!-- 面包屑区域 -->
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>充值</el-breadcrumb-item>
        <el-breadcrumb-item>充值中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card">
      <el-card class="box-card">
        <h2>充值中心</h2>
        <div class="recharge">您账户的余额为：{{allSum}}</div>
        <div class="moneyZx">
            充值金额
        </div>
         <div class="forMoney" >
           <div class="flex_money" :class="active_index==index ? 'active':''" v-for="(item,index) in data" :key="item.id" @click="active(index,item.amount)">
             <span>￥{{item.amount}}</span>
              <div>{{item.integral}}积分</div>
           </div>
         </div>
        <div class="zhifu" v-show="active_index!='123'">
          <div class="border" @click="payment">
            <img src="../../public/aliply.png" />
            支付宝支付
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      active_index: "123",
      active_price: "",
      allSum: 0,
      orderNum: "",
      timer: "",
      seconds: 1
    };
  },
  mounted() {
    this.getIntegral();
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    }
  },
  methods: {
    getIntegral() {
      this.$axios("/get_integral_spec").then(res => {
        this.data = res.data.list;
        this.allSum = res.data.sum;
      });
    },
    payment() {
      const loading = this.$loading({
        lock: true,
        text: "支付中....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$axios
        .post("/alipay_createOrder", {
          price: this.active_price
        })
        .then(res => {
          //后端返回的form表单，使用原生自动跳转到支付页面
          let divForm = document.getElementsByTagName("divform");
          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = res.url;
          document.body.appendChild(div);
          document.forms[0].setAttribute("target", "_blank");
          document.forms[0].submit();
          //保存订单号
          this.orderNum = res.ordersn;
        });
      setTimeout(() => {
        loading.close();
        this.polling();
      }, 1500);
    },
    active(index, amount) {
      this.active_index = index;
      this.active_price = amount;
    },
    polling() {
      this.timer = setInterval(() => {
        this.seconds++;
        //有效期半个小时，如果超时，清空定时器与订单号
        if (this.seconds >= 60 * 30) {
          clearInterval(this.timer);
          this.orderNum;
          this.$message({
            message:'您的订单已过期，请重新提交',
            type:'warning'
          })
        } else {
          this.$axios
            .post("/query_state", {
              order_number: this.orderNum
            })
            .then(res => {
              if (res.code == 201) {
                this.getIntegral();
                //清空订单号
                this.orderNum = "";
                clearInterval(this.timer);
              }
            });
        }
      }, 2500);
    }
  }
};
</script>
<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.recharge {
  margin: 30px 0;
}
.zhifu {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.border {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #eaeaea;
  padding: 5px;
  cursor: pointer;
  border-radius: 10px;
}
.moneyZx {
  padding: 0 20px;
  border-left: 10px solid #2878ff;
  margin: 30px 0;
}
.border:hover {
  border: 1px solid #0aa1ed;
}
.forMoney {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex_money {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #bfbfbf;
  margin: 20px;
  cursor: pointer;
}
.flex_money > span {
  font-size: 18px;
  font-weight: 700;
}
.flex_money > span:last-child {
  color: #666;
  font-size: 12px;
}
.flex_money:hover {
  color: #0aa1ed;
  border: 1px solid #2878ff;
}
.active {
  color: #0aa1ed;
  border: 1px solid #2878ff;
}
</style>
