import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Chat from 'jwchat'
Vue.use(Chat)

import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store'
// 导入全局样式文件
import './assets/css/global.css'
// 引入axios
import axios from './utils/interceptors'
// 拿到缓存的内容
// 引入时间
import unixTimeToDateTime from './filter/data'
//引入websocket
import socket from './utils/socket.js'
Vue.prototype.$socket = socket
    // 组件间传值
window.$bus = new Vue()

import 'echarts'
Vue.use(ElementUi)

import index from '@/utils/index'
Vue.use(index.install);

Vue.config.productionTip = false

Vue.prototype.$axios = axios;

//引入js-base64
const Base64 = require('js-base64').Base64

//全局防抖，监听所有点击事件
const on = Vue.prototype.$on
Vue.prototype.$on = function(event, func) {
    let timer;
    let flag = true;
    let newFunc = func
    if (event == 'click') {
        newFunc = function() {
            if (flag) {
                func.apply(this, arguments)
                flag = false
            }
            clearTimeout(timer)
            timer = setTimeout(function() {
                flag = true
            }, 1000)
        }
    }
    on.call(this, event, newFunc)
}

// 注册
Vue.filter('my-filter', function(value) {
    return unixTimeToDateTime(value)
})

Vue.filter('timer', function(UTCDateString) {
    if (!UTCDateString) {
        return '-';
    }

    function formatFunc(str) {
        return str > 9 ? str : '0' + str
    }
    var date2 = new Date(UTCDateString);
    var year = date2.getFullYear();
    var mon = formatFunc(date2.getMonth() + 1);
    var day = formatFunc(date2.getDate());
    var hour = date2.getHours();
    hour = hour >= 24 ? hour - 24 : hour; // 12小时制
    hour = formatFunc(hour);
    var min = formatFunc(date2.getMinutes());
    var sec = formatFunc(date2.getSeconds());
    var dateStr = year + '-' + mon + '-' + day + ' ' + hour + ':' + min + ':' + sec;
    return dateStr;
})
Vue.mixin({
    filters: {
        base_img(url) {
            return '/api' + url
        },
    }
})
new Vue({
    router,
    store,
    Base64,
    render: h => h(App)
}).$mount('#app')