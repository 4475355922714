<template>
  <div class="body">
    <!-- 面包屑区域 -->
    <div class="ipSubmit_home" :style="{width:widthStyle[0]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>后台IP</el-breadcrumb-item>
        <el-breadcrumb-item>IP统计</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="70%">
      <div class="flex_buju">
        <label for>新代理名称：</label>
        <el-input v-model="main" placeholder="请输入新代理名称"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_useage">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 表格区域 -->
    <div class="card">
      <el-card class="box-card">
        <!-- 添加代理 -->
        <el-button type="primary" @click="dialogVisible = true">添加新代理</el-button>
        <div class="table">
          <el-table :data="table_ip" border style="width: 100%" v-loading="loading" element-loading-text="拼命加载中">
            <el-table-column prop="username" label="名字"></el-table-column>
            <el-table-column prop="type" label="身份">
              <template slot-scope="scope">
                <div v-if="scope.row.type == 1">警察</div>
                <div v-else>企业</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="地址">
              <template slot-scope="scope">
                <div v-if="scope.row.address!=null && scope.row.address != ''">{{ scope.row.address}}</div>
                <div v-else style="color:red;">地址为空</div>
              </template>
            </el-table-column>
            
            <el-table-column prop="sum" label="IP提交数量">
              <template slot-scope="scope">
                <div @click="handleIPdialogVisible(scope.row.id,1)">
                  <el-button size="mini" type="success">{{ scope.row.sum }} 个</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagenation :size="20" :total="total" v-on:getPage="getPage" />
        </div>
      </el-card>
      <!-- 查看ip弹窗 -->
      <el-dialog title="IP" :visible.sync="IPdialogVisible" width="70%">
        <div v-if="ip_total ==0">ip列表为空</div>
        <div v-else class="ip_content">
          <div class="ip_content_item" v-for="(item,index) of user_ip" :key="index">
            <el-button>{{item.ip}}</el-button>  --
            <el-button>{{item.multi_IP}}</el-button>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
         <Pagenation :size="20" :total="ip_total" v-on:getPage="IP_Page" />
        </span>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagenation from '../components/pagenation'
export default {
  components:{
    Pagenation
  },
  data() {
    return {
      // 表格数据
      id:0,
      table_ip: [],
      user_ip:[],
      // 弹窗
      dialogVisible: false,
      IPdialogVisible:false,
      // 双向绑定
      main: '',
      loading: true,
      //总数
      total:0,
      ip_total:0,
    }
  },
  mounted() {
    this.all_ip(1)
  },
  computed:{
    widthStyle(){
      return this.$store.state.widthStyle
    }
  },
  methods: {
    // 添加新代理
    add_useage() {
      if (this.main == '') {
        this.$message({
          type: 'error',
          message: '输入框不能为空'
        })
      } else {
        this.$axios
          .post('/add_ip_usage', {
            name: this.main
          })
          .then(res => {
              this.dialogVisible = false
          })
      }
    },
    // 获取数据
    all_ip(val) {
      this.$axios('/get_allip_sum?pagenumber='+val).then(res => {
        this.total = res.count
        //返回的是个对象，转为数组才能循环
        this.table_ip =  Object.values(res.data)
        this.loading = false
      })
    },
    //列表分页
     getPage(val){
      this.all_ip(val)
    },
    //获取ip弹窗的数据
    handleIPdialogVisible(id,pagenumber){
      this.id = id
      this.$axios.post('/get_user_allip',{
        id,
        pagenumber:pagenumber
      }).then(res => {
        this.user_ip = res.data
        this.ip_total = res.count
        this.IPdialogVisible = true
      })
    },
    //ip详情分页
    IP_Page(val){
      this.handleIPdialogVisible(this.id,val)
    }
  }
}
</script>
<style scoped>
.table {
  margin: 10px 0;
  border-radius: 5px;
}
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.el-pagination{
  margin-top: 10px;
  text-align: center;
}
.flex_buju {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:30px;
}
.flex_buju > label {
  width: 100px;
}
.ip_content{
  height:550px;
}
.ip_content_item{
  float:left;
  width:400px;
  margin-top:10px;
  display:flex;
  align-items: center;
  justify-content: center;
}
.ip_content .el-button{
  width:150px;
}
.dialog-footer{
  position: absolute;
  bottom: 20px;
  left: 0;
  width:100%;
  display:flex;
  justify-content: center;
  padding-left:70px;
}
</style>