<template>
  <div class="body">
    <!-- 面包屑区域 -->
    <div class="ipSubmit_home" :style="{width:widthStyle[0]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>账号审核</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 卡片区域 -->
    <div class="card">
      <el-card class="box-card">
        <div style="border-bottom: 1px solid #BFBFBF; padding-bottom: 10px" class="about_select">
          <el-button plain size="mini" :autofocus="true" v-focus @click="getUser_ispass(4,1)">全部</el-button>
          <el-button type="info" size="mini" plain @click="getUser_ispass(1,1)">未审核</el-button>
          <el-button type="primary"  size="mini" plain @click="getUser_ispass(2,1)">审核成功</el-button>
          <el-button type="danger" size="mini" plain @click="getUser_ispass(0,1)">审核失败</el-button>
          <el-button type="warning" size="mini" plain @click="getUser_ispass(3,1)">信息未提交</el-button>
        </div>
        <!-- 角色表格 -->
        <el-table :data="user_tableData"  stripe style="width: 100%" :default-sort = "{prop: 'id', order: 'ascending'}" v-loading="loading" element-loading-text="拼命加载中">
          <el-table-column prop="id" label="ID" sortable ></el-table-column>
          <el-table-column prop="policename" label="姓名"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column
            label="身份"
            prop="role_name"
            style="width: 100px;"
          ></el-table-column>
          <el-table-column prop="createdAt" label="注册时间"></el-table-column>
          <el-table-column prop="is_pass" label="审核状态">
            <template slot-scope="scope">
              <div v-if="scope.row.is_pass == 1">未审核</div>
              <div v-else-if="scope.row.is_pass == 2">审核通过</div>
              <div v-else-if="scope.row.is_pass == 3">审核信息不完整</div>
              <div v-else>
                审核失败
                <div class="ablout">原因：{{ scope.row.cause }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="操作">
            <template slot-scope="scope">
              <div v-if="scope.row.is_pass == 1">
                <el-button type="primary" size="mini" @click="ablout_is_pass(scope.row.id, scope.row.type)">去审核</el-button>
              </div>
              <div v-else-if="scope.row.is_pass == 2">
                <el-button type="success" size="mini" @click="ablout_is_pass(scope.row.id, scope.row.type)">审核通过</el-button>
              </div>
              <div v-else-if="scope.row.is_pass == 3">
                <el-button type="danger" size="mini" disabled>信息不完整</el-button>
              </div>
              <div v-else>
                <el-button type="danger" size="mini" @click="ablout_is_pass(scope.row.id, scope.row.type)">审核失败</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 弹窗 -->
        <el-dialog title="提示" :visible.sync="drawer" width="70%" modal :close-on-click-modal="false">
          <div class v-if="type == 1">
            <!--  -->
            <el-form label-width="80px">
              <el-form-item label="姓名">
                <el-input :value="info.policename" disabled></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <el-input :value="info.contacts" disabled></el-input>
              </el-form-item>
              <el-form-item label="单位名称">
                <el-input :value="info.company" disabled></el-input>
              </el-form-item>
              <div class="ablout_image">
                <el-image :preview-src-list="imgUrl" :src="BaseUrl+info.positiveImage" :z-index="20000" @click="setImg(info.positiveImage,info.nopositiveImage)"></el-image>
                <el-image :preview-src-list="imgUrl" :src="BaseUrl+info.nopositiveImage" :z-index="20000" @click="setImg(info.positiveImage,info.nopositiveImage)"></el-image>
              </div>
              <el-form-item label="审核结果" v-show="info.is_pass==2">
                <el-radio-group value="2">
                  <el-radio label="2" border disabled>通过</el-radio>
                  <el-radio label="0" border disabled>不通过</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="审核结果" v-show="info.is_pass==0">
                <el-radio-group value="0">
                  <el-radio label="2" border disabled>通过</el-radio>
                  <el-radio label="0" border disabled>不通过</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="审核结果" v-show="info.is_pass==1">
                <el-radio-group v-model="label_value">
                  <el-radio label="2" border>通过</el-radio>
                  <el-radio label="0" border>不通过</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="原因" v-if="label_value == 0">
                <el-input placeholder="请输入不通过原因" v-model="from_value"></el-input>
              </el-form-item>
              <el-form-item label="原因" v-if="info.is_pass==0">
                <el-input disabled placeholder="请输入不通过原因" :value="info.cause"></el-input>
              </el-form-item>
            </el-form>
            <!--  -->
          </div>
          <div class v-else>
            <!--  -->
            <el-form label-width="80px">
              <el-form-item label="企业电话">
                <el-input :value="info.enterprisePhone" disabled></el-input>
              </el-form-item>
              <el-form-item label="企负责人">
                <el-input :value="info.contacts" disabled></el-input>
              </el-form-item>
              <el-form-item label="企业名称">
                <el-input :value="info.enterpriseName" disabled></el-input>
              </el-form-item>
              <el-form-item label="企业地址">
                <el-input :value="info.address" disabled></el-input>
              </el-form-item>
              <div class="ablout_image">
                <el-image :preview-src-list="imgUrl" :src="BaseUrl + info.q_image" @click="setImg(info.q_image)" :z-index="20000"></el-image>
              </div>
              <el-form-item label="审核结果" v-show="info.is_pass==2">
                <el-radio-group value="2">
                  <el-radio label="2" border disabled>通过</el-radio>
                  <el-radio label="0" border disabled>不通过</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="审核结果" v-show="info.is_pass==0">
                <el-radio-group value="0">
                  <el-radio label="2" border disabled>通过</el-radio>
                  <el-radio label="0" border disabled>不通过</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="审核结果" v-show="info.is_pass==1">
                <el-radio-group v-model="label_value">
                  <el-radio label="2" border>通过</el-radio>
                  <el-radio label="0" border>不通过</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="原因" v-if="label_value == 0">
                <el-input placeholder="请输入不通过原因" v-model="from_value"></el-input>
              </el-form-item>
              <el-form-item label="原因" v-if="info.is_pass==0">
                <el-input disabled placeholder="请输入不通过原因" :value="info.cause"></el-input>
              </el-form-item>
            </el-form>
            <!--  -->
          </div>
          <span slot="footer" class="dialog-footer" v-if="type==1">
            <el-button @click="drawer = false">取 消</el-button>
            <el-button type="primary" v-show="info.is_pass==1" @click="dialogVisible(info.jq_user_jid)">确 定</el-button>
          </span>
          <span slot="footer" class="dialog-footer" v-else>
            <el-button @click="drawer = false">取 消</el-button>
            <el-button type="primary" v-show="info.is_pass==1" @click="dialogVisible(info.jq_user_qid)">确 定</el-button>
          </span>
        </el-dialog>
      </el-card>
    </div>
     <div class="paginations" :style="{width:widthStyle[1]}">
        <Pagenation :size="30" :total="total" v-on:getPage="handleCurrentChange" />
     </div>
  </div>
</template>
<script>
import Pagenation from '../components/pagenation'
import { Base64 } from 'js-base64';
export default {
  components:{
    Pagenation
  },
  data() {
    return {
      user_tableData: [],
      //   弹窗开关
      drawer: false,
      // 身份
      type: '',
      // 数据
      info: '',
      // 单选框的值
      label_value: '2',
      // 不通过原因
      from_value: '',
      loading: true,
      // 审核状态
      total:0,
      is_pass:'4',
      check:1,
      BaseUrl:this.$store.state.BaseUrl,
      imgUrl:[]
    }
  },

  mounted() {
    this.getUser_ispass(4,1)
  },
  computed:{
    widthStyle(){
      return this.$store.state.widthStyle
    }
  },
  //设置默认选中第一个按钮
  directives: {
      focus: {
        inserted: function(el) {
          el.focus();
        }
      }
    },
  methods: {
    // 去审核
    ablout_is_pass(id, type) {
      this.type = type
      this.drawer = true
      this.label_value = '2'
      this.$axios.post('/audit', {
          id: id,
          type: type
        }).then((res) => {
          this.info = res.data
        })
		// console.log(this.info)
    },
    // 获取用户审核状态信息
    getUser_ispass(is_pass,pagenumber) {
      this.is_pass = is_pass
      this.$axios
        .post('/home_audit', {
          pagenumber,
          is_pass
        })
        .then((res) => {
          // console.log(res)
          this.user_tableData = res.data
          this.total = res.count
          this.loading = false
        })
    },
    //分页
     handleCurrentChange(val){
      this.getUser_ispass(this.is_pass,val)
    },
    dialogVisible(id) {
      // 判断用户点击的是通过还是不通过
      if (this.label_value == 2) {
        this.$axios.post('/submit', {
            is_pass: 2,
            id,
            cause: ''
          }).then((res) => {
            this.drawer = false
            this.getUser_ispass(1,1)
          })
      } else {
        if (this.from_value == '') {
          // 如果审核不通过，必须填写审核不通过的原因
          this.$message({
            message: '审核原因不能为空',
            type: 'error'
          })
        } else {
          this.$axios.post('/submit', {
              is_pass: '0',
              id,
              cause: Base64.encode(this.from_value)
            }).then((res) => {
              this.drawer = false
              this.from_value = ""
              this.getUser_ispass(1,1)
            })
        }
      }
    },
    //查看大图
    setImg(img,img2){
            this.imgUrl = []
            if(img2 != undefined){
              this.imgUrl.push(this.BaseUrl + img, this.BaseUrl + img2)
            }else {
              this.imgUrl.push(this.BaseUrl + img)
            }
        }
  }
}
</script>
<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.el-button--success {
  background: #29d49e;
  border: #29d49e;
}
.el-button--success:hover {
  background: #1d916c;
  border: #1d916c;
}
.add_roles {
  margin: 10px 0;
}
.paginations{
  position: fixed;
  bottom: 0;
  z-index:999;
  width: 93.6%;
  background: #fff;
}
.input {
  padding: 10px;
}
.input > .el-input {
  width: 50%;
}
.ablout {
  font-size: 14px;
  color: #f00;
}
.ablout_image {
  height: 220px;
  margin: 10px;
  text-align: center;
}
.ablout_image .el-image {
  width: 30%;
  height: 200px;
  margin: 0 80px;
}
@media screen and (max-width: 550px) {
  .ablout_image img {
    width: 100%;
    height: 200px;
    margin: 0;
  }
  .ablout_image {
    height: 400px;
    margin: 10px;
    text-align: center;
  }
}
</style>