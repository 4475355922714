<template>
  <div class="body">
    <!-- 面包屑区域 -->
    <div class="ipSubmit_home" :style="{width:widthStyle[0]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>权限列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 卡片区域 -->
    <div class="card">
      <el-card class="box-card">
        <!--子路由弹窗 -->
        <el-dialog title="添加路由" :visible.sync="drawer" width="70%" :fullscreen='fullscreen'>
          <el-form :label-position="labelPosition" label-width="80px">
            <el-form-item label="路由名：">
              <el-input
                placeholder="请输入路由名字"
                v-model="route_name"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否菜单:">
              <el-radio-group v-model="radio">
                <el-radio label="1" :value="1">是</el-radio>
                <el-radio label="0" :value="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否为导航菜单:" v-show="radio == 0">
            <el-radio-group v-model="list_radio">
                <el-radio label="1" :value="1">是</el-radio>
                <el-radio label="0" :value="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="路由url：" v-show="radio == 0">
              <el-input
                placeholder="请输入路由url"
                v-model="route_url"
              ></el-input>
            </el-form-item>
          </el-form>

          <span slot="footer" style="justify-content:space-around" class="dialog-footer">
            <el-button @click="drawer = false">取 消</el-button>
            <el-button type="primary" @click="add_doraw">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 角色表格 -->
        <el-table :data="tableData" style="width: 100%"
         v-loading="loading"
        element-loading-text="拼命加载中"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                :data="props.row.children"
                style="width: 100%"
                stripe
                border
              >
                <el-table-column prop="wenidc_action_group_id" label="父级ID">
                </el-table-column>
                <el-table-column prop="wenidc_action_name" label="路由名">
                </el-table-column>
                <el-table-column prop="wenidc_action_url" label="url">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        icon="el-icon-delete"
                        type="danger"
                        size="mini"
                        @click="delete_action(scope.row.wenidc_id)"
                      ></el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="ID" prop="wenidc_id"> </el-table-column>
          <el-table-column label="权限名称" prop="wenidc_action_name">
          </el-table-column>
          <el-table-column label="是否菜单" prop="desc">
            <template slot-scope="scope">
              <div v-if="scope.row.wenidc_action_group_id == 0">是</div>
              <div v-else>否</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="add_action(scope.row.wenidc_id)"
                  >添加</el-button
                >
                <el-button
                  icon="el-icon-delete"
                  type="danger"
                  size="mini"
                  @click="delete_action(scope.row.wenidc_id)"
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>
<script>
import { Base64 } from 'js-base64';
export default {
  data() {
    return {
      tableData: [],
      //   弹窗开关
      drawer: false,
      // 路由名字
      route_name: "",
      // 路由URL
      route_url: "",
      // 添加弹窗开关
      drawer_s: false,
      // 对齐方式
      labelPosition: "right",
      // 父级IP
      dad_id: "",
      // 是否为菜单
      radio: "1",
      // 子菜单
      list_radio:'0',
      // 弹窗是否全屏
      fullscreen:false,
      //   选择框的选择
      loading: true,
    };
  },
  mounted() {
     if(document.body.clientWidth<550){
    this.fullscreen=true
  }
    // 拿到数据
    this.getTable();
  },computed:{
    widthStyle(){
      return this.$store.state.widthStyle
    }
  },

  methods: {
    // 删除路由
    delete_action(id) {
      this.$confirm("此操作将永久删除路由, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/del_jq_action", {
              id,
            })
            .then((res) => {
                this.getTable()
            })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 弹窗
    add_action(id) {
      this.dad_id = id;
      // 发送添加请求
      this.drawer = true;
    },
    // 确定按钮
    add_doraw() {
      if (this.route_name == "") {
        this.$message({
          type: "error",
          message: "路由名字不能为空，必须为汉字",
        });
      } else if (this.radio == "1") {
        this.$axios
          .post("/add_jq_action", {
            action_url: "",
            action_name: this.route_name,
            action_group_id: "0",
            is_menu: 1,
          })
          .then((res) => {
              this.getTable();
              this.drawer = false;
          })
      } else if (this.route_url == "") {
        this.$message({
          type: "error",
          message: "路由Url不能为空",
        });
      } else {
        this.$axios
          .post("/add_jq_action", {
            action_url: this.route_url,
            action_name: this.route_name,
            action_group_id: this.dad_id,
            is_menu: this.list_radio,
          })
          .then(res => {
            // console.log(res)
              this.getTable();
              this.drawer = false;
          })
      }
    },
    // 获取数据
    getTable() {
      this.$axios("/roles_add").then((res) => {
        if(res.code==200){ 
          // console.log(res)
          this.loading=false
          this.tableData = res.data;
        }else{
          this.$message({
            type:'error',
            message:res.msg
          })
        }
      });
    },
  },
};
</script>
<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.card {
  margin-top: 60px;
}
.add_roles {
  margin: 10px 0;
}
.input {
  padding: 10px;
}
.input > .el-input {
  width: 50%;
}
</style>