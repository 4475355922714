<template>
  <div class="body">
    <!-- 面包屑区域 -->
    <div class="ipSubmit_home" :style="{width:widthStyle[0]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>APP版本管理</el-breadcrumb-item>
        <el-breadcrumb-item>版本控制</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :fullscreen="fullscreen">
      <div>
        <el-form>
          <el-form-item label="版本号：">
            <el-input placeholder="请输入版本号" v-model="versionName"></el-input>
            <span class="span_Style">输入格式为 例如:1.0.1</span>
          </el-form-item>
          <el-form-item label="版本大小：">
            <el-input placeholder="请输入版本大小" v-model="apkSize"></el-input>
            <span class="span_Style">输入格式为 例如：10M</span>
          </el-form-item>
          <el-form-item label="版本说明：">
            <el-input placeholder="请输入这次更新说明" v-model="updata"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" style="justify-content:space-around"  class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_app_updata">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 卡片区域 -->
    <div class="card">
      <el-card class="box-card">
        <el-button type="primary" @click="dialogVisible = true">添加新版本</el-button>
        <div style="margin: 10px; font-size: 14px; color: #999">以下是历史版本</div>
        <!-- 角色表格 -->
        <el-table :data="tableData" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中">
          <el-table-column label="版本名" prop="versionName" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.versionName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="安装包大小" prop="apkSize" align="center">
          </el-table-column>
          <el-table-column label="更新时间" prop="versionCode" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.versionCode | timer }}</div>
            </template>
          </el-table-column>
          <el-table-column label="更新说明" prop="updateContent" align="center"></el-table-column>
          <el-table-column label="操作" prop="updateContent" align="center">
           <template slot-scope="scope">
              <div>
                <el-button @click="del_version(scope.row.id)" type="danger">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <Pagenation :size="30" :total="total" v-on:getPage="getSearchPage" />
    </div>
  </div>
</template>
<script>
import Pagenation from '../components/pagenation'
import {Base64} from 'js-base64'
export default {
  components:{
    Pagenation
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      versionName: '',
      apkSize: '',
      updata: '',
      // 弹窗是否为全屏
      fullscreen: false,
      loading: true,
      total:0
    }
  },
  mounted() {
    this.get_App(1)
    if (document.body.clientWidth < 550) {
      this.fullscreen = true
    }
  },
  computed:{
    widthStyle(){
      return this.$store.state.widthStyle
    }
  },
  methods: {
    //获取版本信息
    get_App(val) {
      this.$axios('/get_app_updata?pagenumber='+ val).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.count
        this.tableData.forEach(item => {
          item.versionName = item.versionName.toString()
          item.versionName = item.versionName.split('').join('.')
        })
      })
    },
    //新增版本
    add_app_updata() {
      if (this.versionName == '') {
        this.$message({
          type: 'error',
          message: '版本号不能为空'
        })
      } else if (this.apkSize == '') {
        this.$message({
          type: 'error',
          message: '大小不能为空'
        })
      } else if (this.updata == '') {
        this.$message({
          type: 'error',
          message: '版本说明不能为空'
        })
      } else {
        //拼接下载地址
        let path = 'https://down.hitevil.com/apk/yzc_app_'+this.versionName+'.apk'
        let content = Base64.encode(this.updata)
        this.$axios
          .post('/add_app_updata', {
            versionName: this.versionName,
            apkSize: this.apkSize,
            updateContent: content,
            path
          })
          .then(res => {
              this.dialogVisible = false
              this.get_App(1)
          })
      }
    },
    //分页
    getSearchPage(val){
      this.get_App(val)
    },
    //删除版本
    del_version(id){
      this.$confirm("此操作将永久删除此版本, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then( ()=>{
          this.$axios.post('/edition_del',{
            id
        }).then( res => {
            this.get_App(1)
        })
      }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
     
    }
  }
}
</script>
<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.span_Style {
  font-size: 14px;
  color: #999;
}
.el-pagination{
  margin-top: 10px;
  text-align: center;
}
</style>


























