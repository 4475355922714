<template>
  <div class="hMain">
    <div class="block">
      <span class="demonstration">日期：</span>
      <el-date-picker
        v-model="value"
        type="daterange"
        :picker-options="pickerOptions"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        align="right"
        format="yyyy 年 MM 月 dd 日"
        value-format="timestamp"
        @change="getDate"
      ></el-date-picker>
    </div>

    <div style="display: flex; margin-bottom: 2%;" class="chartFlex">
      <div class="searchBox">
        <v-chart
          ref="registers_searchChart"
          class="chart setChart "
          :option="setOption"
        />
        <span class="sumb">
          查询总数量:
          <span>{{ sumNum }}</span>
        </span>
      </div>
      <div>
        <v-chart ref="registers_policeChart" class="chart" :option="option" />
      </div>
      </div>

      <div style="display: flex; height:390px;"  class="chartFlex">
        <div class="reportBox">
          <v-chart ref="registers_repChart" class="chart" :option="repOption" />

          <span class="sum">
            生成报告总数量：
            <span>{{ reportSum }}</span>
          </span>
        </div>

      <div>
        <v-chart
          ref="registers_pieChart"
          class="chart"
          :option="options"
          style="margin-top: 35px;"
        />
      </div>
    </div>
  </div>
</template>

<script>
//引入数字样式
import shuzi from "../components/shuzi";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  AxisPointerComponent,
  PolarComponent,
  GeoComponent,
  SingleAxisComponent,
  ParallelComponent,
  CalendarComponent,
  GraphicComponent,
  ToolboxComponent,
  BrushComponent,
  TimelineComponent,
  MarkPointComponent,
  MarkLineComponent,
  MarkAreaComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
  AriaComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  BarChart,
  PieChart,
  LineChart,
  GridComponent,
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  AxisPointerComponent,
  GridComponent,
  PolarComponent,
  GeoComponent,
  SingleAxisComponent,
  ParallelComponent,
  CalendarComponent,
  GraphicComponent,
  ToolboxComponent,
  TooltipComponent,
  AxisPointerComponent,
  BrushComponent,
  TitleComponent,
  TimelineComponent,
  MarkPointComponent,
  MarkLineComponent,
  MarkAreaComponent,
  LegendComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
  AriaComponent,
  DatasetComponent,
  TransformComponent,
]);

export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "higt",
  },
  data() {
    return {
      // 日期
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value: "",
      sumNum: 0,
      startDate: "",
      endDate: "",
      // 深度报告总数
      depthNum: "",
      // 人工分析总数
      artiNum: "",
      // 柱状图
      option: {
        grid: {
          left: "8%",
          bottom: "1%",
        },
        // legend:{
        //   left:'right'
        // },
        title: {
          text: "警方对接数量",
          left: "center",
          textStyle: {
            color: "#6495ED",
            fontsize: 18,
          },
          // margin: [20, 0, 10, 1000],
        },
        // 提示框
        tooltip: {
          show: true,
          trigger: "item",
          axiosPointer: {
            type: "shadow",
            axis: "auto",
          },
          padding: 5,
          textStyle: {
            color: "green",
          },
        },
        xAxis: [
          {
            type: "category",
            offset: 8,
            nameLocation: "end",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#666",
            },
            nameGap: 30, //---坐标轴名称与轴线之间的距离

            data: [],
          },
        ],
        yAxis: [
          {
            show: true, //---是否显示
            position: "left", //---y轴位置
            offset: 0, //---y轴相对于默认位置的偏移
            type: "value", //---轴类型，默认'category'
            name: "销量", //---轴名称
            nameLocation: "end", //---轴名称相对位置value
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#fff",
              padding: [5, 0, 0, 5], //---坐标轴名称相对位置
            },
            nameGap: 15, //---坐标轴名称与轴线之间的距离
            axisLine: {
              //---坐标轴 轴线
              show: true, //---是否显示

              //------------------- 箭头 -------------------------
              symbol: ["none", "arrow"], //---是否显示轴线箭头
              symbolSize: [8, 8], //---箭头大小
              symbolOffset: [0, 7], //---箭头位置

              //------------------- 线 -------------------------
              lineStyle: {
                color: "#6495ED",
                width: 1,
                type: "solid",
              },
            },
            axisTick: {
              //---坐标轴 刻度
              show: true, //---是否显示
              inside: true, //---是否朝内
              lengt: 3, //---长度
              lineStyle: {
                color: "red", //---默认取轴线的颜色
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              //---坐标轴 标签
              show: true, //---是否显示
              inside: false, //---是否朝内
              rotate: 0, //---旋转角度
              margin: 8, //---刻度标签与轴线之间的距离
              color: "#000", //---默认取轴线的颜色
            },
          },
        ],
        series: [
          {
            name: "对接数量",
            type: "bar",
            legendHoverLink: true, //---是否启用图例 hover 时的联动高亮
            // 是否显示文本
            label: {
              //---图形上的文本标签
              show: true,
              position: "top", //---相对位置
              // rotate:90,               //---旋转角度
              color: "#000",
            },

            itemStyle: {
              //---图形形状
              color: "#6495ED",
              barBorderRadius: [18, 18, 0, 0],
            },
            data: [],
          },
        ],
      },
      // 饼图
      options: {
        tooltip: {
          trigger: "item",
        },
        grid: {
          left: "5%",
          bottom: "1%",
        },
        legend: {
          left: "center",
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              fontSize: 20,
              position: "center",
              padding: [20, 10, 20, 10],
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      // 活跃度
      setOption: {
        title: {
          text: "用户活跃度",
          left: "left",
          textStyle: {
            color: "#00BFFF",
            fontsize: 18,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          // data: ["警方活跃度", "企业活跃度"],
          // top: "8%",/
          left:"center",
          // textStyle: {
          //   color: "#ffffff",
          // },
        },
        grid: {
          left: "5%",
          right: "3%",
          bottom: "10%",
        },
        xAxis: {
          type: "category",
          data: [],
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#00BFFF",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#00BFFF",
            },
          },
        },
        series: [
          {
            name: "警方活跃度",
            type: "line",
            data: [],
            lineStyle: {
              width: 5,
              color: "#F5802E",
            },
            label: {
              show: true,
              position: "inside",
              formatter: "{c}",
              color: "#ffffff",
              textStyle: {
                fontSize: 16,
                fontWeight: "bold",
              },
            },
          },
          {
            name: "企业活跃度",
            type: "line",
            lineStyle: {
              width: 5,
              color: "#5FA2DB",
            },
            data: [],
            label: {
              show: true,
              position: "inside",
              formatter: "{c}",
              color: "#ffffff",
              textStyle: {
                fontSize: 16,
                fontWeight: "bold",
              },
            },
          },
        ],
      },
      // 矩形柱状图
      repOption: {
        title: {
          text: "报告生成数",
          left: "left",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "5%",
          // right: "3%",
          bottom: "6%",
        },
        legend: {
          left: "center",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "category",
          // data:[0,2,4,6,8,10,12,14,16]
          axisLabel: {
            formatter: "{value} ",
          },
        },
        series: [
          {
            name: "人工分析生成报告数量",
            type: "line",
            data: [],
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          },
          {
            name: "深度分析生成报告数量",
            type: "line",
            data: [],
            markPoint: {
              data: [{ name: "周最低", value: 5, xAxis: 1, yAxis: 5 }],
            },
            markLine: {
              data: [
                { type: "average", name: "Avg" },
                [
                  {
                    symbol: "none",
                    x: "90%",
                    yAxis: "max",
                  },
                  {
                    symbol: "circle",
                    label: {
                      position: "start",
                      formatter: "Max",
                    },
                    type: "max",
                    name: "最高点",
                  },
                ],
              ],
            },
          },
        ],
      },
    };
  },
  computed: {
    reportSum() {
      return this.depthNum + this.artiNum;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeTheChart);
    this.number = 50;
    this.$axios
      .post("/get_duijie_echare", { start_time: "", end_time: "" })
      .then((res) => {
        // console.log(res);
        this.option.series[0].data = res.data.nums;
        this.option.xAxis[0].data = res.data.days;
      });
    this.get_echars_bar();
    // 警方活跃度
    this.$axios
      .post("/police_active", { start_time: "", end_time: "" })
      .then((res) => {
        // console.log(res);
        this.setOption.xAxis.data = res.data.days;
        this.setOption.series[0].data = res.data.nums;
      });
    //  企业活跃度
    this.$axios
      .post("/enterprise_active", { start_time: "", end_time: "" })
      .then((res) => {
        // console.log(res);
        this.setOption.xAxis.data = res.data.days;
        this.setOption.series[1].data = res.data.nums;
      });
    // 人工分析报告书
    this.$axios
      .post("/analysis_sum", { start_time: "", end_time: "" })
      .then((res) => {
        // console.log(res);
        this.repOption.series[0].data = res.data.nums;
        this.repOption.xAxis.data = res.data.days;
        this.artiNum = res.data.sum;
      });
    // 深度分析报告书
    this.$axios
      .post("/depth_analysis_sum", { start_time: "", end_time: "" })
      .then((res) => {
        // console.log(res);
        this.repOption.series[1].data = res.data.nums;
        this.repOption.xAxis.data = res.data.days;
        this.depthNum = res.data.sum;
      });
    // 总查询数量
    this.getInquiry();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeTheChart);
  },
  methods: {
    resizeTheChart() {
      if (this.$refs.registers_policeChart) {
        this.$refs.registers_policeChart.resize();
      }
      if (this.$refs.registers_repChart) {
        this.$refs.registers_repChart.resize();
      }
      if (this.$refs.registers_searchChart) {
        this.$refs.registers_searchChart.resize();
      }
      if (this.$refs.registers_pieChart) {
        this.$refs.registers_pieChart.resize();
      }
    },
    // 总查询量数据
    getInquiry() {
      this.$axios.post("/query_quantity").then((res) => {
        // console.log(res);
        this.sumNum = res.data.sum;
      });
    },
    // 审核echarts数据
    get_echars_bar() {
      this.$axios("/get_shenhe_echare_bar").then((res) => {
        this.options.series[0].data = res.data;
      });
    },
    getDate() {
      //如果为空说明清空了日期选项，就显示默认日期
      if (this.value == null) {
        this.date = "";
      } else {
        //start_time开始日期
        this.startDate = this.value[0].toString().slice(0, 10);
        //end_time结束日期
        this.endDate = this.value[1].toString().slice(0, 10);
      }
      this.$axios
        .post("/get_duijie_echare", {
          start_time: this.startDate,
          end_time: this.endDate,
        })
        .then((res) => {
          // console.log(res);
          this.option.series[0].data = res.data.nums;
          this.option.xAxis[0].data = res.data.days;
        });
      // console.log(this.date);
      // 警方活跃度
      this.$axios
        .post("/police_active", {
          start_time: this.startDate,
          end_time: this.endDate,
        })
        .then((res) => {
          // console.log(res);

          this.setOption.xAxis.data = res.data.days;
          this.setOption.series[0].data = res.data.nums;
        });
      // 企业活跃度
      this.$axios
        .post("/enterprise_active", {
          start_time: this.startDate,
          end_time: this.endDate,
        })
        .then((res) => {
          // console.log(res);
          this.setOption.xAxis.data = res.data.days;
          this.setOption.series[1].data = res.data.nums;
        });
      // 人工分析生成报告书
      this.$axios
        .post("/analysis_sum", {
          start_time: this.startDate,
          end_time: this.endDate,
        })
        .then((res) => {
          // console.log(res);
          this.repOption.series[0].data = res.data.nums;
          this.repOption.xAxis.data = res.data.days;
          this.artiNum = res.data.sum;
        });
      // 深度分析生成报告书
      this.$axios
        .post("/depth_analysis_sum", {
          start_time: this.startDate,
          end_time: this.endDate,
        })
        .then((res) => {
          this.repOption.series[1].data = res.data.nums;
          this.repOption.xAxis.data = res.data.days;
          this.depthNum = res.data.sum;
        });
    },
  },
};
</script>

<style scoped>
.hMain {
  width: 97%;
  margin-top: 55px;
  height: 100%;
  border-radius: 20px;
  /* box-shadow: 0 1px 2px 0 rgb(134 131 131 / 10%),
   0 6px 20px 0 rgb(138 135 135 / 10%); */
  /* padding-left: 5%; */
}
.body {
  overflow-x: hidden;
}
.chart {
  width: 900px;
  height: 390px;
}
.chart > canvas {
  width: 100% !important;
}
.block {
  position: absolute;
  top: 8%;
  z-index: 22222;
}
.reportBox {
  position: relative;
}
.sum {
  position: absolute;
  top: 10px;
  right: 35px;
}
.searchBox {
  position: relative;
}
.sumb {
  position: absolute;
  top: 10px;
  right: 25px;
}
.sumb > span {
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  color: #00bfff;
}
.sum > span {
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  color: #1e90ff;
}
@media screen and (min-width: 1995px) and (max-width: 2006px) {
  .sum {
    margin: 0 0 30px -140px;
  }
}
@media screen and (min-width: 0px) and (max-width: 1920px) {
  .chartFlex {
    flex-wrap: wrap;
  }
  .chart{
    width: 1120px;
    height: 400px;
  }
}
@media screen and (min-width: 0px) and (max-width: 1550px) {
  .sumb {
    margin: 0 0 0 -300px;
  }
  .sum {
    margin: 0 0 30px -300px;
  }
}
</style>
