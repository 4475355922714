<template>
  <div>
    <!-- 面包屑区域  fullscreen-->
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>ASN</el-breadcrumb-item>
        <el-breadcrumb-item>ASN提交</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 卡片区域 -->
    <div class="card">
      <el-card shadow="always">
        <div class="add_ip">
          <el-button type="primary" size="mini" @click="dialogVisible = true"
            >上传ASN</el-button
          >
        </div>

        <!-- 弹窗部分 -->
        <el-dialog
          title="上传ASN"
          :visible.sync="dialogVisible"
          width="50%"
          :fullscreen="fullscreen"
        >
          <el-input
            placeholder="请输入您要上传的ASN"
            v-model="asn_input"
          ></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="add_ips">上 传</el-button>
          </span>
        </el-dialog>
        <!-- 表格区域 -->
        <div class="search_table">
          <el-table :data="tableData" style="width: 100%" v-loading="loading"
            element-loading-text="拼命加载中">
            <el-table-column prop="enterpriseName" label="公司名称">
            </el-table-column>
            <el-table-column prop="AS_number" label="ASN号"> </el-table-column>
            <el-table-column prop="createdAt" label="日期"> </el-table-column>
            <el-table-column prop="id" label="操作">
              <template slot-scope="scope">
                <div class="solt_button">
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                    @click="delete_ip(scope.row.id)"
                  ></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页部分 -->
        <!-- <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[50, 100, 150, 200]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div> -->
      </el-card>
    </div>
  </div>
</template>
<script>
import unixTimeToDateTime from "../filter/data";
// var test_ip = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;

export default {
  data() {
    return {
      //搜索的ip
      dialogVisible: false,
      // 登录用户的类型
      type: "",

      // 抽屉开关
      drawer: false,

      // 页数
      skip: 0,
      limit: 50,
      // 倒计时请求数据
      info: [],
      //数据表格
      tableData: [],
      // 错误

      // 所有页数
      total: 0,
      // 定时器
      settimer: "",
      // 弹窗是否为全屏
      fullscreen: false,
      //   双向绑定ASN
      asn_input: "",
      loading:true
    };
  },

  // 拿到用户登录的身份
  mounted() {
    // 拿到数据
    this.get_asn();
    let users_type = JSON.parse(window.sessionStorage.getItem("info")).type;
    this.type = users_type;
    // console.log(this.type)
  },
  computed:{
    widthStyle(){
      return this.$store.state.widthStyle
    }
  },

  methods: {
    //   获取asn数据
    get_asn() {
      this.$axios("/get_asn?pagenumber="+ 1).then((res) => {
        this.loading=false
        this.tableData = res.data;
      });
    },

    // 删除ASN
    delete_ip(id) {
      this.$confirm("此操作将永久删除该ASN, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        this.$axios.post("/del_asn", {
            asn_id:id
        }).then(res => {
             this.get_asn()
        })
      })
      .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 上传ASN
    add_ips() {
      if (this.asn_input.trim() == "") {
        this.$message({
          type: "error",
          message: "输入框不能为空",
        });
      } else if (isNaN(this.asn_input)) {
        this.$message({
          type: "error",
          message: "ASN必须为数字",
        });
      } else {
        this.$axios
          .post("/save_asn", {
            asn: this.asn_input,
          })
          .then((res) => {
            if (res.code == 1) {
              this.dialogVisible = false;
              this.get_asn();
              this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              this.dialogVisible = false;

              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
      }
    },
    // 搜索asn 自己提交的
    search() {
      console.log("搜索ASN");
    },
  },
};
</script>
<style scoped>
.ipSubmit_home {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #999;
  background-color: #fff;
}
.el-input {
  text-align: left;
  width: 40%;
}
.el-button {
  margin-left: 10px;
  width: 85px;
}
.pagination {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.search_table {
  margin: 40px 0;
}
.add_ip {
  margin-top: 20px;
}
.select {
  margin-top: 100px;
  text-align: center;
}
.input_ip .el-input {
  margin: 10px 0;
  width: 300px;
}
.flex {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.flex > .el-radio-group {
  margin: 5px 2px;
}
.input_ips .el-input {
  margin: 10px 0;
  width: 143px;
}
.input_ips span,
.input_ip span {
  margin: 0 5px;
}
.solt_button > .el-button {
  border-radius: 5%;
}
.upload_excel {
  margin: 20px;
  text-align: center;
}
.upload_excel .el-button {
  width: 100px;
}
.file_style {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  color: #666;
}
.flex_Dad {
  display: flex;
  border: 1;
  justify-content: center;
}

.flex_Dad > div:last-child {
  margin-left: 10px;
}
.el-table {
  margin-top: 10px;
}
.test {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.radios {
  position: relative;
  margin: 15px;
}
.radios > span {
  position: absolute;
  top: 0;
  left: 20%;
}

@media screen and (max-width: 1200px) {
  .flex_Dad {
    display: flex;
    border: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 550px) {
  .flex_Dad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex_Dad div {
    width: 90%;
  }
  .flex_Dad div img {
    width: 100%;
  }
  .input_ip .el-input {
    margin: 10px 0;
    width: 100%;
  }
  .flex {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .flex > .el-radio {
    margin: 10px;
  }
  .input_ips .el-input {
    margin: 10px 0;
    width: 100%;
  }
  .input_ips span {
    margin: 0 5px;
  }
}
</style>