<template>
  <!-- 用户列表 -->
  <div class="body">
    <!-- 面包屑区域 -->
    <div class="top" :style="{width:widthStyle[0]}">
      <div class="order_home">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>用户管理</el-breadcrumb-item>
          <el-breadcrumb-item>用户列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 搜索框部分 -->
      <div class="search_ip">
        <el-input placeholder="请输入您要查询的内容" v-model.trim="search_text" clearable @keyup.enter.native="search(1)"></el-input>
        <el-select v-model="select" @change="getSearchType" popper-class="getSearchType" placeholder="请选择字段">
          <el-option v-for="item in searchOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="search(1)">搜索</el-button>
        <el-button type="primary" @click="crearSearch(1)">清空搜索</el-button>
      </div>
    </div>
    <!-- 卡片区域 -->
    <div class="card">
      <el-card class="box-card">
        <!-- 角色表格 -->
        <el-table :data="tableData" v-loading="loading"  :default-sort = "{prop: 'id', order: 'ascending'}"  element-loading-text="拼命加载中" stripe style="width: 100%">
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="policename" label="姓名"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="q_userinfo.address" label="公司单位/企业名称">
            <template slot-scope="scope">
              <div v-if="scope.row.type == 1 && scope.row.company!=null">{{ scope.row.company }}</div>
              <div v-else-if=" scope.row.type == 0 && scope.row.address!=null">{{ scope.row.address }}</div>
              <div v-else-if=" scope.row.type == 2 && scope.row.address!=null">{{ scope.row.address }}</div>
              <div v-else-if=" scope.row.type == 0 && scope.row.enterpriseName!=null">{{ scope.row.enterpriseName }}</div>
              <div v-else style="color:red;">信息填写不完整</div>
            </template>
          </el-table-column>
          <el-table-column label="身份" prop="role_name" style="width:100px;"></el-table-column>
          <el-table-column prop="createdAt" label="注册时间"></el-table-column>
          <el-table-column prop="phone" label="联系电话"></el-table-column>
          <el-table-column prop label="操作">
            <template slot-scope="scope">
              <div>
                <el-button type="primary" icon="el-icon-edit" size="mini" @click="edit_role(scope.row.username, scope.row.id)"></el-button>
                <el-button type="danger" ref="button" icon="el-icon-delete" size="mini" @click="remove_role(scope.row.id)"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 弹窗部分 -->
        <el-dialog title="修改权限" :visible.sync="dialogVisible" width="70%" close-on-click-modal>
          <div>
            <el-input :value="username" disabled></el-input>
          </div>
          <!-- 下拉框部分 -->
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.id" :label="item.role_name" :value="item.id" :disabled="item.id == value_id"></el-option>
          </el-select>
          <!-- 下拉框部分结束 -->

          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="user_list_add">确 定</el-button>
          </span>
        </el-dialog>
      </el-card>
    </div>
    <!-- 分页部分,不是一个接口，所以只能写俩分页 -->
    <div class="paginations" :style="{width:widthStyle[1]}">
      <Pagenation :total="total" :size="30" v-on:getPage="getPage" />
      <Pagenation :total="searchTotal" :size="10" v-on:getPage="getSearchPage" />
    </div>
  </div>
</template>
<script>
  import Pagenation from '../components/pagenation'
  export default {
    components: {
      Pagenation
    },
    data() {
      return {
        show: false,
        tableData: [],
        //   弹窗开关
        drawer: false,
        dialogVisible: false,
        // 用户名字
        username: '',
        // 搜索的内容
        search_text: '',
        //搜索的字段
        search_type: 'username',
        options: [],
        value: '',
        searchOptions: [{
          value: 'username',
          label: '用户名'
        },{
          value: 'phone',
          label: '手机号'
        },{
          value: 'company',
          label: '公司单位'
        }, {
          value: 'enterpriseName',
          label: '企业名称'
        }],
        select: '用户名',
        // 用户id
        id: '',
        // 选中的id
        value_id: '',
        // 选中的名字
        value_name: '',
        // 登录的类型
        type: '',
        currentPage: 1, //初始页
        pagesize: 50,
        // 搜到了
        tDatas: [],
        //   选择框的选择
        loading: true,
        total: 0,
        searchTotal: 0,
      }
    },
    mounted() {
      this.get_order_list(1)
      this.type = JSON.parse(window.sessionStorage.getItem('info')).type
    },
    computed: {
      widthStyle() {
        return this.$store.state.widthStyle
      }
    },
    methods: {
      // 删除角色
      remove_role(id) {
        this.$confirm('此操作将用户删除该用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error',
          center: true
        })
          .then(() => {
            // 这里面加入删除用户的请求
            this.$axios
              .post('/del_user', {
                type: this.type,
                id
              })
              .then(res => {
                this.total = 0
                if (this.search_text != '') {
                  this.search(1)
                } else {
                  this.get_order_list(1)
                }
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      },
      // 获取数据
      get_order_list(val) {
        this.$axios('/order_list?Pagenumber=' + val).then(res => {
          // console.log(res)
          this.tableData = res.data
          this.total = res.count
          this.loading = false
        })
      },
      //角色页码
      getPage(val) {
        this.get_order_list(val)
      },
      //搜索出来用户的页码
      getSearchPage(val) {
        this.search(val)
      },
      //选择搜索哪个字段
      getSearchType(val) {
        this.search_type = val
      },
      // 修改用户角色
      edit_role(username, id, index) {
        this.username = username
        this.id = id
        this.dialogVisible = true
        this.$axios
          .post('/see', {
            id
          })
          .then(res => {
            this.options = res.data.roles
            this.value = res.data.role.jq_role.role_name
            this.value_name = res.data.role.jq_role.role_name
            this.value_id = res.data.role.jq_role.id
          })
      },
      // 发送请求修改用户角色

      user_list_add() {
        if (this.value == this.value_name) {
          this.$message({
            type: 'info',
            message: '不能修改已知权限'
          })
        } else {
          this.$axios
            .post('/update_see', {
              role_id: this.value,
              user_id: this.id
            })
            .then(res => {
              this.dialogVisible = false
            })
        }
      },
      // 搜索
      search(val) {
        if (this.search_text == '') {
          this.$message({
            type: 'error',
            message: '输入框不能为空'
          })
        } else if (this.search_type == '') {
          this.$message({
            type: 'error',
            message: '请选择要搜索的字段'
          })
        } else {
          this.$axios
            .post('/order_list_search', {
              Pagenumber: val,
              search_text: this.search_text,
              search_type: this.search_type
            })
            .then(res => {
              if (res.code == 200) {
                // console.log(res)
                this.tableData = res.data.data
                this.show = true
                this.searchTotal = res.data.count
                //归零整个数据的条数，这样整个的分页就会隐藏
                this.total = 0
              } else {
                this.search_text = ''
                this.show = false
              }
            })
        }
      },
      //清空搜索
      crearSearch(val) {
        this.get_order_list(val)
        //归零搜索数据的总数，这样搜索的分页就会隐藏
        this.searchTotal = 0
        this.search_text = ''

      }
    }
  }
</script>
<style scoped>
  .top{
    height:auto;
    width: 92.5%;
    background:#fff;
    z-index:999;
    display:flex;
    flex-direction:column;
    position:fixed;
    top:60px;
    padding:10px;
  }

  .order_home{
    height:50px;
    display:flex;
    align-items:center;
    padding:0 10px;
    border-radius:5px;
    box-shadow: 0px 0px 3px #999;
    background-color:#fff;
    margin-bottom: 20px;
  }

  .search_ip{
    width:100%;
    margin:20px 0 0 0;
    display:flex;
    flex-direction:row;
  }

  .search_ip>.el-select {
    width: 30%;
    margin: 0 20px 0 0;
  }

  .search_ip>.el-input {
    width: 30%;
    margin-right: 20px;
  }

  .search_ip>.el-input,
  .el-button {
    display: inline-block;
    height: 40px;
  }

  .search_table {
    margin-bottom: 20px;
  }

  .card {
    margin-top: 120px;
  }

  .add_roles {
    margin: 10px 0;
  }

  .input {
    padding: 10px;
  }

  .input>.el-input {
    width: 50%;
  }

  .el-select {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .getSearchType {
    width: 20%;
  }

  .paginations {
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 93.6%;
    background: #fff;
  }

  .box-card {
    margin-top: 60px;
  }

  @media screen and (max-width: 740px) {
    .search_ip>.el-input {
      width: 50%;
      margin-right: 10px;
    }
  }
</style>