<template>
  <div>
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>APP管理</el-breadcrumb-item>
        <el-breadcrumb-item>价格管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="card">
        <el-card>
            <el-table :data="rechargeList">
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="type_name" label="类型"></el-table-column>
                <el-table-column prop="unit_price" label="所需积分">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.unit_price * 10}}分
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="toupdata(scope.row.id)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
    <el-dialog :visible.sync="dialogVisible" title="修改积分" center>
        请输入积分<el-input v-model="integral" placeholder="请输入积分"></el-input>
        <span slot="footer" class="dialog-footer" style="margin-left:-50px;">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updataPrice">确 定</el-button>
         </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rechargeList: [],
      dialogVisible: false,
      integral: null,
      type_id: null
    };
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    }
  },
  mounted() {
    this.getRechargeList();
  },
  methods: {
    getRechargeList() {
      this.$axios.post("/recharge_ladder").then(res => {
        this.rechargeList = res.data;
      });
    },
    toupdata(id) {
      this.dialogVisible = true;
      this.type_id = id;
    },
    updataPrice() {
      this.$axios
        .post("/modify_price", {
          type:this.type_id,
          price:this.integral
        })
        .then(res => {
          if (res.code == 201) {
            this.dialogVisible = false;
            this.integral = null
            this.getRechargeList();
          }
        });
    }
  }
};
</script>

<style scoped>
.el-input{
    width:70%;
    margin:0 0 40px 20px;
}
</style>