<template>
  <div>
    <div class="did" v-for="item in data" :key="item.id" v-show="item.is_check!=0">
      <div class="title">{{ item.title }}</div>
      <div class="username" v-if="item.company">----{{item.company}}--{{item.policename}}</div>
      <div class="username" v-else>----{{item.contacts}}--{{item.enterpriseName}}</div>
      <div class="content">
        {{ item.content }}
      </div>
      <!-- 轮播 -->
      <div class="lunbo" v-if="item.zsk_img != '0'">
        <el-image
          :preview-src-list="imgUrl"
          v-for="(img, imgindex) of item.zsk_img"
          :key="imgindex"
          :src="BaseUrl + img"
          @click="setImg(item.zsk_img)"
        ></el-image>
      </div>
    <div class="footer"  v-if="item.is_check==1">
        审核通过    
    </div>
     <div class="footer" style="color:red" v-if="item.is_check==2">
        审核未通过    
    </div>
   </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      BaseUrl:this.$store.state.BaseUrl,
      imgUrl: []
    };
  },
  methods: {
    //设置大图预览的数据，必须是数组
    setImg(img) {
      this.imgUrl = [];
      img.forEach(item => {
        this.imgUrl.push(this.BaseUrl + item);
      });
    }
  }
};
</script>
<style scoped>
.did {
  position: relative;
  background-color: #fcfcfc;
  border-radius: 5px;
  padding: 30px;
  margin: 30px 20px;
  box-shadow: 0px 0px 7px rgba(147, 153, 154, 0.35);
}
.title {
  font-size: 30px;
}
.username {
  font-size: 14px;
  margin: 10px 0;
  color: #666;
}
.content {
  margin: 30px 0 10px 0;
  font-size: 14px;
  color: #999;
}
.lunbo .el-image {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: 10px;
}
.yesorno {
  margin: 20px;
}
.footer {
  position: absolute;
  padding: 9px;
  border-radius: 10px;
  border: 1px solid #eaeaee;
  top: 50px;
  right: 10px;
  font-size: 24px;
  box-shadow: 1px 1px 8px #eaeaea;
  color: yellowgreen;
}
</style>
