<template>
    <div>
        <el-pagination
          @current-change="getPage"
          :hide-on-single-page="true"
          :page-size="size"
          :page-sizes="[size]"
          :current-page.sync="current_page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
    </div>
</template>

<script>
export default {
    props:[
        'total',
        'size',
    ],
    data() {
        return {
            current_page:1
        }
    },
    watch:{
        total(){
            //如果总数变了，重置当前页码，防止串码
            this.current_page = 1
        }
    },
    methods: {
        getPage(val){
            this.$emit('getPage',val)
        }
    },
}
</script>

<style>
.el-pagination {
  /* text-align: center;
  margin-left: -200px; */
}
</style>