<template>
  <div>
    <div class="ipSubmit_home" :style="{width:widthStyle[0]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>金刚区配置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="margin-top:50px" class="card">
      <el-button type="primary" style="margin-bottom:20px" @click="insertDialogVisible=true">添加展示信息</el-button>
      <el-card>
        <el-table :data="kingData" stripe style="width: 100%">
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="src" label="图片">
            <template slot-scope="scope">
              <el-image class="image" :z-index="20000" :src="imgBase + scope.row.src" alt></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="url" label="跳转路径">
            <template slot-scope="scope">
              <el-link type="info" :underline="false">{{scope.row.url}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="link_type" label="跳转类型">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.link_type == '0' ? 'success' : 'primary'"
                disable-transitions
              >{{scope.row.link_type == '0' ? '内链' : '外链'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.type == '1' ? 'success' : 'primary'"
                disable-transitions
              >{{scope.row.type == '1' ? '警方' : '企业'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="display" label="展示状态">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.display == '1' ? 'success' : 'primary'"
                disable-transitions
              >{{scope.row.display == '1' ? '展示' : '隐藏'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="url" label="操作">
            <template slot-scope="scope" style="text-align:center">
              <el-button type="primary" size="mini" @click="openShow(scope.row)">是否展示</el-button>
              <el-button type="danger" size="mini" @click="deleteData(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <!-- 添加展示信息弹窗 -->
      <el-dialog title="添加展示信息" :visible.sync="insertDialogVisible" width="30%">
        <el-form
          :model="insertForm"
          :rules="insertRules"
          ref="insertForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="图片标题" prop="title">
            <el-input v-model="insertForm.title"></el-input>
          </el-form-item>
          <el-form-item label="跳转路径" prop="url">
            <el-input v-model="insertForm.url"></el-input>
          </el-form-item>
          <el-form-item label="跳转类型" prop="link_type">
            <el-radio v-model="insertForm.link_type" label="0">内链</el-radio>
            <el-radio v-model="insertForm.link_type" label="1">外链</el-radio>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <template>
              <el-radio v-model="insertForm.type" label="1">警方</el-radio>
              <el-radio v-model="insertForm.type" label="2">企业</el-radio>
            </template>
          </el-form-item>
          <el-form-item label="是否展示" prop="isShow">
            <template>
              <el-radio v-model="insertForm.isShow" label="0">隐藏</el-radio>
              <el-radio v-model="insertForm.isShow" label="1">展示</el-radio>
            </template>
          </el-form-item>
          <el-form-item label="图片文件" prop="file">
            <el-upload
              class="upload-demo"
              ref="upload"
              :action="BasUrl"
              multiple
              accept=".jpg, .png, .jpeg"
              :data="insertData"
              :limit="1"
              :headers="headers"
              :auto-upload="false"
              :show-file-list="true"
              :on-success="handleSuccess"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button @click="insertDialogVisible=false">取 消</el-button>
            <el-button type="primary" @click="InsertSubmit()">添加</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 是否展示弹窗 -->
      <el-dialog title="是否展示" :visible.sync="dialogVisible" width="30%">
        <el-select v-model="showValue" placeholder="请选择">
          <el-option label="隐藏" value="0"></el-option>
          <el-option label="显示" value="1"></el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible=false">取 消</el-button>
          <el-button @click="isShow" type="primary">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
	  //图片文件 
	  BasUrl:this.$store.state.BaseUrl+"admin/add_vajra_district",
      //   表单数据
      kingData: [],
      //   是否展示
      radio: 1,
      imgBase: this.$store.state.BaseUrl,
      imgUrl: "",
      // 删除
      deleteID: "",
      // 添加展示信息
      insertDialogVisible: false,
      // 上传图片列表
      imgList: [],
      insertForm: {
        title: "",
        url: "",
        isShow: "",
        type: "",
        link_type:""
      },
      insertRules: {
        title: [{ required: true, message: "请输入图片标题", trigger: "blur" }],
        url: [{ required: true, message: "请输入图片路径", trigger: "blur" }],
        // file: [{ required: true, message: "请上传图片信息", trigger: "blur" }],
        isShow: [
          { required: true, message: "请选择展示状态", trigger: "change" }
        ],
        type: [{ required: true, message: "请选择数据类型", trigger: "change" }]
      },
      // 是否展示
      taskId: "",
      showValue: "",
      dialogVisible: false
    };
  },
  mounted() {
    this.getKingData();
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    },
    headers() {
      return {
        Authorization: window.localStorage.getItem("token"),
        version:'v3'
      };
    },
    insertData() {
      return {
        title: this.insertForm.title,
        url: this.insertForm.url,
        isShow: this.insertForm.isShow,
        type: this.insertForm.type,
        link_type:this.insertForm.link_type
      };
    }
  },
  methods: {
    // 获取金刚区数据
    getKingData() {
      this.$axios.post("/vajra_district", { type: 1 }).then(res => {
        // console.log(res.data);
        this.kingData = res.data;
      });
    },
    // 提交添加信息数据
    InsertSubmit(insertForm) {
      this.$refs.upload.submit();
    },
    // 上传成功触发事件
    handleSuccess(res) {
      // console.log(res);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.insertDialogVisible = false;
        this.getKingData();
      } else {
        this.$message({
          type: "error",
          message: res.msg
        });
        this.$refs.upload.clearFiles();
      }
    },
    // 是否展示对话框
    openShow(row) {
      this.taskId = row.id;
      this.dialogVisible = true;
    },
    // 提交是否展示
    isShow() {
      // console.log(this.showValue)
      this.$axios
        .post("/update_vajra_district", {
          id: this.taskId,
          attribute: this.showValue
        })
        .then(res => {
          // console.log(res)
          if (res.code == 200) {
            this.dialogVisible = false;
            this.getKingData();
          }else{
            this.dialogVisible = false;
          }
        });
    },
    // 删除展示数据
    deleteData(row) {
      this.deleteID = row.id;
      this.$axios
        .post("/del_vajra_district", { id: this.deleteID })
        .then(res => {
          if ((res.code == 200)) {
            this.$message({
              type: "success",
              message: res.msg
            });
            this.getKingData();
          } else {
            this.$message({
              type: "error",
              message: res.msg
            });
          }
        });
    }
  }
};
</script>
<style scoped>
.card /deep/ .el-dialog__body {
  margin-bottom: 20px;
}
.card /deep/ .el-dialog__body > span {
  margin-right: 10px;
}
.image /deep/ .el-image__inner {
  width: 40% !important;
}
.card /deep/ .el-table td {
  text-align: center !important;
}
.card /deep/ .el-table th {
  text-align: center !important;
}
</style>


