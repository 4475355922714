//引入base64插件
let {
    Base64
} = require('js-base64');

//引入url加密
let urlencode = require('urlencode');

//引入md5加密
let md5 = require('md5');

let axios = require('axios');
//加密方法
let encryption = ($txt, $key  = 'wenidc') => {

    //秘钥字符串
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-=+";

    // 随机数
    let nh = rand(0, 64);

    // 查找chars里面有没有这个nh下标，有的话返回这个下标的值
    let ch = chars.charAt(nh);

    // md加密
    let mdKey = md($key + ch);

    // 从mdKey第一个参数下标到第二个参数下标的中间的参数
    mdKey = mdKey.substr(nh % 8, nh % 8 + 7);

    // base加密
    $txt = Base64.encode($txt);
    let tmp = '';
    let i;
    let j = 0;
    let k = 0;

    // 开始for循环
    for (i = 0; i < $txt.length; i++) {
        // 每一次循环的下标值
        k = k == mdKey.length ? 0 : k;

        // nh + 
        j = (nh + chars.indexOf($txt.charAt(i)) + mdKey.charAt(k++).charCodeAt()) % 64;

        //把每一次查找出来的值拼接
        tmp += chars.charAt(j);
    }

    // 最后返回
    return ch + tmp;
}

//md5加密方法
let md = (constent) => {

    let result = md5(constent);

    return result
}

//随机数
let rand = (minNum, maxNum) => {
    switch ( arguments.length ) {
        case 1:
            return parseInt(Math.random() * minNum + 1, 10);
            break;
        case 2:
            return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
            break;
        default:
            return 0;
            break;
    }
}

//解密方法
let decryption = ($txt, $key = 'wenidc') => {
    // $txt = urldecode($txt);

    //秘钥字符串
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-=+";

    // 查找$txt里面有没有这个nh下标，有的话返回这个下标的值
    let ch = $txt.charAt(0);

    let nh = chars.indexOf(ch);

    let mdKey = md($key + ch);

    mdKey = mdKey.substr(nh % 8, nh % 8 + 7);

    $txt = $txt.substr(1);

    let tmp = '';
    let i = 0;
    let j = 0;
    let k = 0;
    for (i = 0; i < $txt.length; i++) {
        k = k == mdKey.length ? 0 : k;
        j = chars.indexOf($txt.charAt(i)) - nh - mdKey.charAt(k++).charCodeAt()

        while (j < 0) j += 64;
        tmp += chars.charAt(j);
    }

    return Base64.decode(tmp);
}

let axiosRequest = (pay,pathname) => {
    let axios = require('axios');
    return axios({
        method: 'post',
        url: 'http://127.0.0.1:88/api',
        data: {
            pay,
            url:`${pathname}`
        }
    });
}


module.exports = {
    encryption,
    decryption,
    axiosRequest
}