<template>
 <div class="chartNum">
    <div class="box-item">
     <li :class="{'number-item': !isNaN(item), 'mark-item': isNaN(item) }"
      v-for="(item,index) in Num"
      :key="index">
       <span v-if="!isNaN(item)">
        <i ref="numberItem">0123456789</i>
       </span>
      <span class="comma" v-else>{{item}}</span>
     </li>
     <li class="text">次</li>
    </div>
   </div>
</template>
<script>
 export default {
   props:[
     'shuzi'
   ],
  data() {
   return {
    Num: ['0'], // 默认总数
    first:1
   }
  },
  mounted() {
   this.toNum(this.shuzi) // 这里输入数字即可调用
   this.timer()
  },
  watch:{
    shuzi(){
      this.toNum(this.shuzi)
      this.setNumberTransform()
    }
  },
  methods: {
    timer(){
      //进入页面的时候先调用三次渲染数字的函数，因为渲染函数有bug，前三次渲染的数字位数不全
      let time = setInterval( () => {
        if(this.first >2){
          clearInterval(time)
        }
        this.setNumberTransform()
        this.first++
      },500)
    },
   setNumberTransform () {
    const numberItems = this.$refs.numberItem // 拿到数字的ref，计算元素数量
    const numberArr = this.Num.filter(item => !isNaN(item))
    // 结合CSS 对数字字符进行滚动,显示订单数量
    for (let index = 0; index < numberItems.length; index++) {
      const elem = numberItems[index]
      elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`
    }
   },

   // 处理数字
   toNum(num) {
    num = num.toString()
    this.Num = num.split('') // 将其便变成数据，渲染至滚动数组
   },
  }
 }
</script>
<style scoped>
  /*滚动数字设置*/
 .box-item {
  position: relative;
  height: 70px;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  list-style: none;
  color: #00eaff;
  writing-mode: vertical-lr;
  text-orientation: upright;
  /*文字禁止编辑*/
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  /* overflow: hidden; */
 }
  .mark-item span {
   position: absolute;
   width: 100%;
   bottom: 0;
   writing-mode: vertical-rl;
   text-orientation: upright;
  }
 /*滚动数字设置*/
 .number-item {
  width: 21px;
  height: 45px;
  list-style: none;
  border-radius:4px;
  margin-right:-1px;
 }
  .number-item span {
   position: relative;
   display: inline-block;
   width: 100%;
   height: 100%;
   writing-mode: vertical-rl;
   text-orientation: upright;
   overflow: hidden;
   font-weight: bold
  }
   .number-item span i {
    font-style: normal;
    position: absolute;
    top: 11px;
    left: 50%;
    transform: translate(-50%,0);
    transition: transform 1s ease-in-out;
    letter-spacing: 10px;
   }
 .number-item:last-child {
  margin-right: 0;
 }
 .text{
   margin:-10px 0 0 0;
   font-size:20px;
  font-weight: bold
 }
</style>