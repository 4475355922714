<template>
  <div>
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>任务管理</el-breadcrumb-item>
            <el-breadcrumb-item>案件类型管理</el-breadcrumb-item>
        </el-breadcrumb>
    </div>

    <div class="card">
        <el-row>
            <el-button type="success" @click="dialogVisible = true" v-loading="loading">添加案件类型</el-button>
        </el-row>
        <el-card>
            <el-table :data="case_list">
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="name" label="案件类型"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="delCase(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
    <el-dialog :visible.sync="dialogVisible" title="添加案件类型" center width="800px">
        请输入要添加的案件 <el-input v-model="caseType" placeholder="请输入案件类型"></el-input>
        <span slot="footer" class="dialog-footer" style="margin-left:-50px;">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addCase">确 定</el-button>
        </span>
    </el-dialog>
  </div>



</template>

<script>
export default {
  data() {
    return {
      case_list: [],
      loading:true,
      dialogVisible: false,
      caseType: ""
    };
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    }
  },
  mounted() {
    this.getCaseType();
  },
  methods: {
    getCaseType() {
      this.$axios.post("/case_type").then(res => {
        this.case_list = res.data;
        this.loading = false
      });
    },
    //新增案件类型
    addCase() {
      if (this.caseType == "") {
        this.$message({
          message: "请输入案件类型！",
          type: "error"
        });
      } else {
        this.$axios
          .post("/add_case_type", {
            name: this.caseType
          })
          .then(res => {
            if ((res.code = 201)) {
              this.getCaseType();
              this.dialogVisible = false
            }
          });
      }
    },
    //删除案件类型
    delCase(id) {
    this.$confirm("此操作将删除此案件类型, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then( ()=>{
      this.$axios
        .post("/del_case_type", {
          id
        })
        .then(res => {
          if (res.code == 201) {
            this.getCaseType();
          }
        });
      }).catch( () => {
          this.$message({
              message:'已取消删除',
          })
      })
    }
  }
};
</script>

<style scoped>

.el-dialog__header{
  text-align: center !important;
}
.dialog-footer{
  justify-content: space-evenly !important;
}
.el-row{
    margin-bottom:20px;
}
.el-input{
    width: 70%;
    margin:0 0 20px 20px;
}
</style>